import {combineReducers} from 'redux';
import {reducer as modals} from 'redux-saga-modal';

import backendApi from './backend-api/reducer';
import authentication from './authentication/reducer';
import navigation from './navigation/reducer';
import pharmacy from './pharmacy/reducer';
import training from './training/reducer';
import reward from './reward/reducer';
import profile from './profile/reducer';
import loginLightbox from './login-lightbox/reducer';
import interimLightbox from './interim-lightbox/reducer';
import pointLimitLightbox from './point-limit-lightbox/reducer';
import pointsHistory from './points-history/reducer';
import pointsReceipt from './points-receipt/reducer';
import pointsAssignment from './points-assignment/reducer';
import pbAcademy from './pb-academy/reducer';
import pointsArchive from './points-archive/reducer';
import news from './news/reducer';
import pharmacyUsers from './pharmacy-users/reducer';
import pharmacyUserDetails from './pharmacy-user-details/reducer';
import submissions from './campaign-submissions/reducer';
import submissionsSlider from './user-campaign-submissions-slider/reducer';
import userCampaignSubmissions from './user-campaign-submissions/reducer';
import interactiveBanner from './interactive-banner/reducer';
import highlightsBanner from './highlights-banner/reducer';
import userPharmacyTourSubmissions from './user-pharmacy-tour-submission/reducer';
import registration from './registration/reducer';
import legalPages from './legal-pages/reducer';

export default function createRootReducer() {
    return combineReducers({
        backendApi,
        authentication,
        navigation,
        pharmacy,
        training,
        reward,
        profile,
        loginLightbox,
        interimLightbox,
        pointLimitLightbox,
        pointsHistory,
        pointsReceipt,
        pointsAssignment,
        modals,
        news,
        pointsArchive,
        pharmacyUsers,
        pharmacyUserDetails,
        submissions,
        submissionsSlider,
        userCampaignSubmissions,
        interactiveBanner,
        userPharmacyTourSubmissions,
        registration,
        legalPages,
        pbAcademy,
        highlightsBanner,
    });
}
