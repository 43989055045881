import styled from 'styled-components';
import {ToastContainer} from 'react-toastify';

import {color, zindex} from '../../../styles/variables';
import {lg} from '../../../styles/media';
export const StyledToastContainer = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress',
})`
    /* .toast-container */
    bottom: 0;
    top: 80px;
    right: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: ${zindex.toast};
    height: fit-content;

    ${lg`
        width: 30%;
        top; 130px;
    `}

    .toast {
        background-color: ${color.white};
        margin: 0;
        cursor: auto;

        &.Toastify__toast--info {
            background-color: ${color.darkBlue};
            color: ${color.white};
        }

        &.Toastify__toast--warning {
            background-color: ${color.petrol};
            color: ${color.white};
        }

        &.Toastify__toast--error {
            background-color: ${color.red};
            color: ${color.white};
        }
    }
    button[aria-label='close'] {
        display: none;
    }
    .body {
        margin: 0;
        display: grid;
        align-items: center;
    }
    .Toastify__progress-bar--info {
        background: ${color.white};
    }
    .Toastify__progress-bar--warning {
        background: ${color.white};
    }
    .Toastify__progress-bar--error {
        background: ${color.white};
    }
`;
