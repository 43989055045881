export const LOAD_DETAILS = 'pharmacy-user-details/LOAD_DETAILS';
export const LOAD_DETAILS_SUCCESS =
    'pharmacy-user-details/LOAD_DETAILS_SUCCESS';
export const LOAD_DETAILS_FAILURE =
    'pharmacy-user-details/LOAD_DETAILS_FAILURE';
export const LOAD_POINTS_HISTORY = 'pharmacy-user-details/LOAD_POINTS_HISTORY';
export const LOAD_POINTS_HISTORY_SUCCESS =
    'pharmacy-user-details/LOAD_POINTS_HISTORY_SUCCESS';
export const LOAD_POINTS_HISTORY_FAILURE =
    'pharmacy-user-details/LOAD_POINTS_HISTORY_FAILURE';
export const CLEAR = 'pharmacy-user-details/CLEAR';

export const loadDetails = (userId) => ({
    type: LOAD_DETAILS,
    payload: {userId},
});

export const loadDetailsSuccess = (details) => ({
    type: LOAD_DETAILS_SUCCESS,
    payload: {details},
});

export const loadDetailsFailure = (error) => ({
    type: LOAD_DETAILS_FAILURE,
    payload: {error},
});

export const loadPointsHistory = (userId, page = 1) => ({
    type: LOAD_POINTS_HISTORY,
    payload: {userId, page},
});

export const loadPointsHistorySuccess = (pointsHistory, pagination) => ({
    type: LOAD_POINTS_HISTORY_SUCCESS,
    payload: {pointsHistory, pagination},
});

export const loadPointsHistoryFailure = (error) => ({
    type: LOAD_POINTS_HISTORY_FAILURE,
    payload: {error},
});

export const clear = () => ({
    type: CLEAR,
});
