import moment from 'moment';
import 'moment/locale/de';
moment.locale('de');

export const dateFormat = (date) => moment(date).format('dddd, DD.MM.YYYY');
export const dateFormatPrecise = (date) =>
    moment(date).format('DD.MM.YYYY HH:mm:ss');

export const compareDateDesc = (dateA, dateB) =>
    new Date(dateB) - new Date(dateA);
