export const normalizePendingTransactionEntry = (entry = null) => {
    if (!entry) return null;

    return {
        id: entry.id,
        type: entry.type,
        date: entry.date,
        change: entry.change,
        message: entry.message,
        reason: entry.reason,
    };
};

export const normalizePendingNotificationEntry = (entry = null) => {
    if (!entry) return null;

    return {
        id: entry.id,
        type: entry.type,
        title: entry.title,
        message: entry.message,
        data: entry.data,
    };
};
