import {combineReducers} from 'redux';

import {
    SET_STATIC_BRANDS,
    SET_STATIC_CATEGORIES,
    SET_STATIC_TRAININGS,
    LOAD_COMPLETION_SUCCESS,
    SET_SCORM_READY,
    START,
    SET_PROGRESS,
    SET_SCORE,
    SET_SUCCESS_STATUS,
    FINISH,
    FINISH_SUCCESS,
    OPEN_SUCCESS_LIGHTBOX,
    CLOSE_SUCCESS_LIGHTBOX,
} from './actions';
import {SET_IS_LOGGED_IN} from '../authentication/actions';

const initialState = {
    byId: {},
    typesById: {},
    brandsById: {},
    categoriesById: {},
    isScormReady: false,
    current: {
        id: null,
        completionStatus: 'incomplete', // one of: "incomplete", "completed"
        successStatus: 'unknown', // one of: "unknown", "passed", "failed"
        progress: 0,
        score: 0, // 0 - 1
        isSuccessLightboxOpen: false,
    },
};

const byId = (state = initialState.byId, {type, payload}) => {
    switch (type) {
        case SET_STATIC_TRAININGS:
            return payload.staticTrainings.reduce(
                (trainingsById, training) => ({
                    ...trainingsById,
                    [training.id]: {
                        ...training,
                        brands: training.brands,
                        completed: null, // unknown
                    },
                }),
                {}
            );
        case LOAD_COMPLETION_SUCCESS:
            return payload.trainings.reduce(
                (trainingsById, training) => ({
                    ...trainingsById,
                    ...(state[training.id] != null
                        ? {
                              // update only if exists before
                              [training.id]: {
                                  ...state[training.id],
                                  completed:
                                      training.completed != null
                                          ? training.completed
                                          : state[training.id].completed,
                              },
                          }
                        : {}),
                }),
                state
            );
        case FINISH_SUCCESS:
            return {
                ...state,
                [payload.id]: {
                    ...state[payload.id],
                    completed: true,
                },
            };
        case SET_IS_LOGGED_IN:
            return payload.isLoggedIn === false
                ? Object.values(state).reduce(
                      (trainingsById, training) => ({
                          ...trainingsById,
                          [training.id]: {
                              ...training,
                              completed: null,
                          },
                      }),
                      state
                  )
                : state;
        default:
            return state;
    }
};

const typesById = (state = initialState.typesById, {type, payload}) => {
    switch (type) {
        case SET_STATIC_TRAININGS:
            return payload.staticTrainings.reduce(
                (typesById, training) => ({
                    ...typesById,
                    ...{
                        [training.type]: {
                            id: training.type,
                            name: training.type,
                        },
                    },
                }),
                {}
            );
        default:
            return state;
    }
};

const brandsById = (state = initialState.brandsById, {type, payload}) => {
    switch (type) {
        case SET_STATIC_BRANDS:
            return payload.staticBrands.reduce(
                (brandsById, brand) => ({
                    ...brandsById,
                    [brand.id]: brand,
                }),
                {}
            );
        default:
            return state;
    }
};

const categoriesById = (
    state = initialState.categoriesById,
    {type, payload}
) => {
    switch (type) {
        case SET_STATIC_CATEGORIES:
            return payload.staticCategories.reduce(
                (allCategoriesById, category) => ({
                    ...allCategoriesById,
                    [category.id]: category,
                }),
                {}
            );
        default:
            return state;
    }
};

const isScormReady = (state = initialState.isScormReady, {type, payload}) => {
    switch (type) {
        case SET_SCORM_READY:
            return true;
        default:
            return state;
    }
};

const id = (state = initialState.current.id, {type, payload}) => {
    switch (type) {
        case START:
            return payload.id;
        default:
            return state;
    }
};

const completionStatus = (
    state = initialState.current.completionStatus,
    {type}
) => {
    switch (type) {
        case START:
            return initialState.current.completionStatus;
        case FINISH:
            return 'completed';
        default:
            return state;
    }
};

const successStatus = (
    state = initialState.current.successStatus,
    {type, payload}
) => {
    switch (type) {
        case START:
            return initialState.current.successStatus;
        case SET_SUCCESS_STATUS:
            return payload.successStatus;
        default:
            return state;
    }
};

const progress = (state = initialState.current.progress, {type, payload}) => {
    switch (type) {
        case START:
            return initialState.current.progress;
        case SET_PROGRESS:
            return payload.progress;
        default:
            return state;
    }
};

const score = (state = initialState.current.score, {type, payload}) => {
    switch (type) {
        case START:
            return initialState.current.score;
        case SET_SCORE:
            return payload.score;
        default:
            return state;
    }
};

const isSuccessLightboxOpen = (
    state = initialState.current.isSuccessLightboxOpen,
    {type}
) => {
    switch (type) {
        case OPEN_SUCCESS_LIGHTBOX:
            return true;
        case CLOSE_SUCCESS_LIGHTBOX:
            return false;
        default:
            return state;
    }
};

const current = combineReducers({
    id,
    completionStatus,
    successStatus,
    progress,
    score,
    isSuccessLightboxOpen,
});

export const internals = {
    initialState,
    byIdReducer: byId,
    typesByIdReducer: typesById,
    brandsByIdReducer: brandsById,
    categoriesByIdReducer: categoriesById,
    isScormReadyReducer: isScormReady,
    currentReducer: current,
    isSuccessLightboxOpenReducer: isSuccessLightboxOpen,
};

export default combineReducers({
    byId,
    typesById,
    brandsById,
    categoriesById,
    isScormReady,
    current,
});
