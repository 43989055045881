export const LOAD_PHARMACY_USERS = 'pharmacy-users/LOAD_PHARMACY_USERS';
export const LOAD_PHARMACY_USERS_SUCCESS =
    'pharmacy-users/LOAD_POINT_USERS_SUCCESS';
export const LOAD_PHARMACY_USERS_FAILURE =
    'pharmacy-users/LOAD_PHARMACY_USERS_FAILURE';
export const SET_USERS = 'pharmacy-users/SET_USERS';
export const SET_SEARCH = 'pharmacy-users/SET_SEARCH';
export const CLEAR_SEARCH = 'pharmacy-users/CLEAR_SEARCH';
export const CLEAR_PHARMACY_USERS = 'pharmacy-users/CLEAR_PHARMACY_USERS';
export const SET_PAGINATION_DATA = 'pharmacy-users/SET_PAGINATION_DATA';

export const loadPharmacyUsers = ({
    page = 1,
    sortColumn = {0: 'desc'},
    search = '',
}) => ({
    type: LOAD_PHARMACY_USERS,
    payload: {page, sortColumn, search},
});

export const loadPharmacyUsersSuccess = (pharmacyUsers) => ({
    type: LOAD_PHARMACY_USERS_SUCCESS,
    payload: {pharmacyUsers},
});
export const loadPharmacyUsersFailure = (error) => ({
    type: LOAD_PHARMACY_USERS_FAILURE,
    payload: {error},
});

export const setUsers = (normalizedUsers) => ({
    type: SET_USERS,
    payload: {users: normalizedUsers},
});

export const setSearch = (search) => ({
    type: SET_SEARCH,
    payload: {search},
});

export const clearSearch = () => ({
    type: CLEAR_SEARCH,
});

export const setPaginationData = (
    current,
    total,
    totalUsers,
    toPage,
    perPage
) => ({
    type: SET_PAGINATION_DATA,
    payload: {current, total, totalUsers, toPage, perPage},
});

export const clearPharmacyUsers = () => ({
    type: CLEAR_PHARMACY_USERS,
});
