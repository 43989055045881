export const normalizePharmacyUserDetails = (details = null) => {
    if (!details) return null;
    return {
        id: details.id,
        firstName: details.first_name,
        lastName: details.last_name,
        pharmacy: details.pharmacy_name,
        zipCode: details.zip_code,
        city: details.city,
        userType: details.user_type,
    };
};

export const normalizePharmacyUserPointsHistoryEntry = (entry = null) => {
    if (!entry) return null;
    return {
        date: entry.created_at,
        reason: entry.message,
        points: entry.points,
        pointsBefore: entry.points_before,
        pointsAfter: entry.points_after,
        status: entry.status,
    };
};
