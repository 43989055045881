import {call, takeLatest, put} from 'redux-saga/effects';

import {
    LOAD_DETAILS,
    loadDetailsSuccess,
    loadDetailsFailure,
    LOAD_POINTS_HISTORY,
    loadPointsHistorySuccess,
    loadPointsHistoryFailure,
    clear,
} from './actions';
import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {
    normalizePharmacyUserDetails,
    normalizePharmacyUserPointsHistoryEntry,
} from './normalize';
import {fetch} from '../backend-api';

function* getPharmacyUserDetailsSaga(userId) {
    return yield call(
        fetch,
        LOAD_DETAILS,
        'GET',
        `/me/users/allocated/${userId}`
    );
}

function* getPointsHistorySaga(userId, page = 1) {
    return yield call(
        fetch,
        LOAD_POINTS_HISTORY,
        'GET',
        `/me/users/allocated/${userId}/transactions?page=${page}`
    );
}

function* loadPharmacyUserDetailsSaga({payload: {userId}}) {
    try {
        const {data: details} = yield call(getPharmacyUserDetailsSaga, userId);
        yield put(loadDetailsSuccess(normalizePharmacyUserDetails(details)));
    } catch (error) {
        yield put(loadDetailsFailure(error));
    }
}

function* loadPointsHistorySaga({payload: {userId, page}}) {
    try {
        const {data: pointsHistory, meta} = yield call(
            getPointsHistorySaga,
            userId,
            page
        );
        yield put(
            loadPointsHistorySuccess(
                pointsHistory.map((entry) =>
                    normalizePharmacyUserPointsHistoryEntry(entry)
                ),
                {
                    currentPage: meta.current_page,
                    totalPages: meta.last_page,
                }
            )
        );
    } catch (error) {
        yield put(loadPointsHistoryFailure(error));
    }
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

function* clearSaga() {
    yield put(clear());
}

export const internals = {
    isLoggedOut,
    getPharmacyUserDetailsSaga,
    getPointsHistorySaga,
    loadPharmacyUserDetailsSaga,
    loadPointsHistorySaga,
    clearSaga,
};

export default function* pharmacyUserDetailsSaga() {
    yield takeLatest(LOAD_DETAILS, loadPharmacyUserDetailsSaga);
    yield takeLatest(LOAD_POINTS_HISTORY, loadPointsHistorySaga);
    yield takeLatest(isLoggedOut, clearSaga);
}
