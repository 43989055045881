import {combineReducers} from 'redux';
import {SET_SUBMISSIONS_SLIDER, CLEAR_SUBMISSIONS_SLIDER} from './actions';

const initialState = {
    images: [],
};

const images = (state = initialState.images, {type, payload}) => {
    switch (type) {
        case SET_SUBMISSIONS_SLIDER:
            return payload.images;
        case CLEAR_SUBMISSIONS_SLIDER:
            return initialState.images;
        default:
            return state;
    }
};

export const internals = {
    imagesReducer: images,
    initialState,
};

export default combineReducers({
    images,
});
