import {call, takeLatest, put} from 'redux-saga/effects';

import {
    LOAD_POINTS_HISTORY,
    loadPointsHistorySuccess,
    loadPointsHistoryFailure,
    setHistory,
    setPaginationData,
    clearPointsHistory,
} from './actions';

import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {normalizePointsHistoryEntry} from './normalize';
import {fetch} from '../backend-api';

function* getPointsHistorySaga(page = 1) {
    return yield call(
        fetch,
        LOAD_POINTS_HISTORY,
        'GET',
        `/me/transactions?filter[status]=credited&page=${page}`
    );
}

function* loadPointsHistorySaga({payload: {page}}) {
    try {
        const {data: pointsHistory, meta} = yield call(
            getPointsHistorySaga,
            page
        );

        yield put(loadPointsHistorySuccess(pointsHistory));
        yield put(
            setHistory(
                pointsHistory.map((entry) => normalizePointsHistoryEntry(entry))
            )
        );
        yield put(setPaginationData(meta.current_page, meta.last_page));
    } catch (error) {
        yield put(loadPointsHistoryFailure(error));
    }
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

function* clearPointsHistorySaga() {
    yield put(clearPointsHistory());
}

export const internals = {
    isLoggedOut,
    loadPointsHistorySaga,
    clearPointsHistorySaga,
    getPointsHistorySaga,
};

export default function* pointsHistorySaga() {
    yield takeLatest(LOAD_POINTS_HISTORY, loadPointsHistorySaga);
    yield takeLatest(isLoggedOut, clearPointsHistorySaga);
}
