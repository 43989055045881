import React from 'react';
import {call, takeLatest, put, select} from 'redux-saga/effects';

import {
    LOAD_SUBMISSIONS,
    EVALUATE_SUBMISSION,
    loadSubmissionsSuccess,
    loadSubmissionsFailure,
    setSubmissions,
    setPaginationData,
    clearSubmissions,
    evaluateSubmissionSuccess,
    evaluateSubmissionFailure,
} from './actions';
import {selectCurrentPage} from './selectors';
import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {
    normalizeSubmissionEntry,
    denormalizeSubmissionRequest,
} from './normalize';
import {fetch} from '../backend-api';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';

function* getCampaignSubmissionsSaga(page = 1, isEvaluated) {
    const url = isEvaluated
        ? `/campaigns/submissions/allocated?page=${page}&filter[status][]=accepted&filter[status][]=rejected&sort=-reviewed_at`
        : `/campaigns/submissions/allocated?page=${page}&filter[status]=pending&sort=-reviewed_at`;
    return yield call(fetch, LOAD_SUBMISSIONS, 'GET', url);
}

function* putEvaluateCampaignSubmissionSaga(submissionData) {
    return yield call(
        fetch,
        EVALUATE_SUBMISSION,
        'PUT',
        `/submissions/${submissionData.id}`,
        submissionData
    );
}

function* loadCampaignSubmissionsSaga({payload: {page, isEvaluated}}) {
    try {
        const {data: submissions, meta} = yield call(
            getCampaignSubmissionsSaga,
            page,
            isEvaluated
        );
        yield put(loadSubmissionsSuccess(submissions));
        yield put(
            setSubmissions(
                submissions.map((entry) => normalizeSubmissionEntry(entry))
            )
        );
        yield put(setPaginationData(meta.current_page, meta.last_page));
    } catch (error) {
        yield put(loadSubmissionsFailure(error));
    }
}

function* evaluateSubmissionSaga({payload: {evaluateData}}) {
    try {
        const currentPage = yield select(selectCurrentPage);
        yield call(
            putEvaluateCampaignSubmissionSaga,
            denormalizeSubmissionRequest(evaluateData)
        );
        yield put(evaluateSubmissionSuccess(evaluateData.id));
        yield call(loadCampaignSubmissionsSaga, {
            payload: {page: currentPage, isEvaluated: false},
        });
        toast.info(
            <Notification
                headline={
                    evaluateData.isRejected
                        ? lang.evaluatedSubmission.rejectedMessage.hl
                        : lang.evaluatedSubmission.acceptedMessage.hl
                }>
                {evaluateData.isRejected
                    ? lang.evaluatedSubmission.rejectedMessage.msg
                    : lang.evaluatedSubmission.acceptedMessage.msg}
            </Notification>
        );
    } catch (error) {
        yield put(evaluateSubmissionFailure(error));
        toast.error(
            <Notification headline={lang.evaluatedSubmission.failure.hl}>
                {lang.evaluatedSubmission.failure.msg}
            </Notification>
        );
    }
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

function* clearSubmissionsSaga() {
    yield put(clearSubmissions());
}

export const internals = {
    isLoggedOut,
    loadCampaignSubmissionsSaga,
    clearSubmissionsSaga,
    getCampaignSubmissionsSaga,
    evaluateSubmissionSaga,
};

export default function* SubmissionsSaga() {
    yield takeLatest(LOAD_SUBMISSIONS, loadCampaignSubmissionsSaga);
    yield takeLatest(EVALUATE_SUBMISSION, evaluateSubmissionSaga);
    yield takeLatest(isLoggedOut, clearSubmissionsSaga);
}
