import {createSelector} from 'reselect';

export const selectDomain = (state) => state.registration;

export const selectRedirectUrl = createSelector(
    selectDomain,
    (domain) => domain.redirectUrl
);

export const selectReferralCodeStatus = createSelector(
    selectDomain,
    (domain) => domain.referralCodeStatus
);
