export const LOAD_PENDING_RECEIPTS = 'points-receipt/LOAD_PENDING_RECEIPTS';
export const LOAD_PENDING_RECEIPTS_SUCCESS =
    'points-receipt/LOAD_PENDING_RECEIPTS_SUCCESS';
export const LOAD_PENDING_RECEIPTS_FAILURE =
    'points-receipt/LOAD_PENDING_RECEIPTS_FAILURE';
export const SET_PENDING_TRANSACTIONS =
    'points-receipt/SET_PENDING_TRANSACTIONS';
export const SET_PENDING_NOTIFICATIONS =
    'points-receipt/SET_PENDING_NOTIFICATIONS';
export const CONFIRM_PENDING_TRANSACTION =
    'points-receipt/CONFIRM_PENDING_TRANSACTION';
export const CONFIRM_PENDING_TRANSACTION_SUCCESS =
    'points-receipt/CONFIRM_PENDING_TRANSACTION_SUCCESS';
export const CONFIRM_PENDING_TRANSACTION_FAILURE =
    'points-receipt/CONFIRM_PENDING_TRANSACTION_FAILURE';
export const REJECT_PENDING_TRANSACTION =
    'points-receipt/REJECT_PENDING_TRANSACTION';
export const REJECT_PENDING_TRANSACTION_SUCCESS =
    'points-receipt/REJECT_PENDING_TRANSACTION_SUCCESS';
export const REJECT_PENDING_TRANSACTION_FAILURE =
    'points-receipt/REJECT_PENDING_TRANSACTION_FAILURE';
export const DISMISS_PENDING_NOTIFICATION =
    'points-receipt/DISMISS_PENDING_NOTIFICATION';
export const DISMISS_PENDING_NOTIFICATION_SUCCESS =
    'points-receipt/DISMISS_PENDING_NOTIFICATION_SUCCESS';
export const DISMISS_PENDING_NOTIFICATION_FAILURE =
    'points-receipt/DISMISS_PENDING_NOTIFICATION_FAILURE';
export const CLEAR_PENDING_RECEIPTS = 'points-receipt/CLEAR_PENDING_RECEIPTS';

export const loadPendingReceipts = () => ({
    type: LOAD_PENDING_RECEIPTS,
});

export const loadPendingReceiptsSuccess = (
    pendingTransactions,
    pendingNotifications
) => ({
    type: LOAD_PENDING_RECEIPTS_SUCCESS,
    payload: {pendingTransactions, pendingNotifications},
});

export const loadPendingReceiptsFailure = (error) => ({
    type: LOAD_PENDING_RECEIPTS_FAILURE,
    payload: {error},
});

export const setPendingTransactions = (normalizedPendingTransactions) => ({
    type: SET_PENDING_TRANSACTIONS,
    payload: {pendingTransactions: normalizedPendingTransactions},
});

export const setPendingNotifications = (normalizedPendingNotifications) => ({
    type: SET_PENDING_NOTIFICATIONS,
    payload: {pendingNotifications: normalizedPendingNotifications},
});

export const confirmPendingTransaction = (
    transactionId,
    ratingCourse,
    ratingInstructor,
    ratingContent
) => ({
    type: CONFIRM_PENDING_TRANSACTION,
    payload: {
        transactionId,
        ratingCourse,
        ratingInstructor,
        ratingContent,
    },
});

export const confirmPendingTransactionSuccess = (transactionId) => ({
    type: CONFIRM_PENDING_TRANSACTION_SUCCESS,
    payload: {transactionId},
});

export const confirmPendingTransactionFailure = (transactionId, error) => ({
    type: CONFIRM_PENDING_TRANSACTION_FAILURE,
    payload: {transactionId, error},
});

export const rejectPendingTransaction = (transactionId) => ({
    type: REJECT_PENDING_TRANSACTION,
    payload: {transactionId},
});

export const rejectPendingTransactionSuccess = (transactionId) => ({
    type: REJECT_PENDING_TRANSACTION_SUCCESS,
    payload: {transactionId},
});

export const rejectPendingTransactionFailure = (transactionId, error) => ({
    type: REJECT_PENDING_TRANSACTION_FAILURE,
    payload: {transactionId, error},
});

export const dismissPendingNotification = (notificationId) => ({
    type: DISMISS_PENDING_NOTIFICATION,
    payload: {notificationId},
});

export const dismissPendingNotificationSuccess = (notificationId) => ({
    type: DISMISS_PENDING_NOTIFICATION_SUCCESS,
    payload: {notificationId},
});

export const dismissPendingNotificationFailure = (notificationId, error) => ({
    type: DISMISS_PENDING_NOTIFICATION_FAILURE,
    payload: {notificationId, error},
});

export const clearPendingReceipts = () => ({
    type: CLEAR_PENDING_RECEIPTS,
});
