// NOTE: this must be a commonjs module because we need to import it in routes.js

const roles = {
    PUBLIC: 'PUBLIC', // no restriction, even when not logged in (ONLY relevant in routes.js, NOT in redux)
    USER: 'USER', // normal logged in user
    INTERIM: 'INTERIM', // user has no pharmacy associated
    TEST_USER: 'TEST_USER', // logged in special test user ("Präsentationsuser")
    SALES_REPRESENTATIVE: 'SALES_REPRESENTATIVE', // logged in sales representative ("Außendienstmitarbeiter")
};

module.exports = roles;
