import {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {useDispatch} from 'react-redux';

import {setStaticCategories} from './actions';

export default function SourceStaticCategories({children}) {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const dispatch = useDispatch();
    const data = useStaticQuery(
        graphql`
            {
                allCategory {
                    edges {
                        node {
                            id
                            name
                            description
                            image
                            image_selected
                        }
                    }
                }
            }
        `
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    if (isFirstRender) {
        dispatch(
            setStaticCategories(
                data.allCategory.edges.map((edge) => ({
                    id: +edge.node.id, // make it a number again
                    name: edge.node.name,
                    description: edge.node.description,
                    image: edge.node.image,
                    imageSelected: edge.node.image_selected,
                }))
            )
        );
    }
    return children;
}
