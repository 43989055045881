import {combineReducers} from 'redux';

import {
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_CANCEL,
} from './actions';

const pendingRequests = (state = [], {type, payload}) => {
    switch (type) {
        case REQUEST_PENDING:
            return [...state, payload];
        case REQUEST_SUCCESS:
        case REQUEST_FAILURE:
        case REQUEST_CANCEL:
            const indexOfPendingRequest = state.findIndex(
                (pendingRequest) => pendingRequest.id === payload.id
            );
            return [
                ...state.slice(0, indexOfPendingRequest),
                ...state.slice(indexOfPendingRequest + 1),
            ];
        default:
            return state;
    }
};

const isOnline = (state = true, {type, payload}) => {
    switch (type) {
        case REQUEST_SUCCESS:
            return true;
        case REQUEST_FAILURE:
            return !payload.error.message.startsWith('Network request failed');
        default:
            return state;
    }
};

const pendingRequestsSuccess = (state = true, {type, payload}) => {
    switch (type) {
        case REQUEST_SUCCESS:
            return payload;
        default:
            return state;
    }
};

const pendingRequestsError = (state = false, {type, payload}) => {
    switch (type) {
        case REQUEST_FAILURE:
            return payload;
        default:
            return state;
    }
};

export const internals = {
    pendingRequestsReducer: pendingRequests,
    isOnlineReducer: isOnline,
    pendingRequestsErrorReducer: pendingRequestsError,
    pendingRequestsSuccessReducer: pendingRequestsSuccess,
};

export default combineReducers({
    pendingRequests,
    isOnline,
    pendingRequestsError,
    pendingRequestsSuccess,
});
