export const normalizePharmacyUsersEntry = (entry = null) => {
    if (!entry) return null;

    return {
        id: entry.id,
        pharmacy: entry.pharmacy_name,
        zipCode: entry.zip_code,
        city: entry.city,
        firstName: entry.first_name,
        lastName: entry.last_name,
        userType: entry.user_type,
        newsletter: entry.newsletter_opt_in ? 'Ja' : 'Nein',
        hasAttendedAcademy: entry.has_attended_academy ? 'Ja' : 'Nein',
        createdAt: entry.created_at,
        trainings: entry.trainings_count,
    };
};

export const unNormalize = [
    'pharmacy_name',
    'zip_code',
    'city',
    'first_name',
    'last_name',
    'user_type',
    'newsletter_opt_in',
    'hasAttendedAcademy',
    'created_at',
    'trainings_count',
];
