import {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {useDispatch} from 'react-redux';

import {setStaticNews} from './actions';

export default function SourceStaticNews({children}) {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const dispatch = useDispatch();
    const data = useStaticQuery(
        graphql`
            {
                allNews {
                    edges {
                        node {
                            id
                            slug
                            title
                            content
                            category
                            teaser_desc
                            teaser_image
                            hero_image
                            video
                            updated_at
                            is_focus
                            fields {
                                route
                            }
                        }
                    }
                }
            }
        `
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    if (isFirstRender) {
        dispatch(
            setStaticNews(
                data.allNews.edges.map((edge) => ({
                    id: edge.node.id,
                    slug: edge.node.slug,
                    title: edge.node.title,
                    content: edge.node.content,
                    category: edge.node.category,
                    teaserDesc: edge.node.teaser_desc,
                    isFocus: edge.node.is_focus,
                    route: edge.node.fields.route,
                    updatedAt: edge.node.updated_at,
                    teaserImage: edge.node.teaser_image,
                    heroImage: edge.node.hero_image,
                    video: edge.node.video,
                }))
            )
        );
    }
    return children;
}
