export const LOAD_LIST_BY_ZIP = 'pharmamcy/LOAD_LIST_BY_ZIP';
export const LOAD_LIST_BY_SEARCH_STRING =
    'pharmamcy/LOAD_LIST_BY_SEARCH_STRING';
export const LOAD_LIST_SUCCESS = 'pharmacy/LOAD_LIST_SUCCESS';
export const LOAD_LIST_FAILURE = 'pharmacy/LOAD_LIST_FAILURE';
export const SET_ALL = 'pharmacy/SET_ALL';
export const LOAD_ONE = 'pharmacy/LOAD_ONE';
export const LOAD_ONE_SUCCESS = 'pharmacy/LOAD_ONE_SUCCESS';
export const LOAD_ONE_FAILURE = 'pharmacy/LOAD_ONE_FAILURE';
export const ADD_ONE = 'pharmacy/ADD_ONE';
export const CLEAR_OTHER_PHARMACY = 'pharmacy/CLEAR_OTHER_PHARMACY';
export const CLEAR_ALL_PHARMACY = 'pharmacy/CLEAR_ALL_PHARMACY';
export const LOAD_CURRENT_WITH_COLLEAGUES =
    'pharmacy/LOAD_CURRENT_WITH_COLLEAGUES';
export const LOAD_CURRENT_WITH_COLLEAGUES_SUCCESS =
    'pharmacy/LOAD_CURRENT_WITH_COLLEAGUES_SUCCESS';
export const LOAD_CURRENT_WITH_COLLEAGUES_FAILURE =
    'pharmacy/LOAD_CURRENT_WITH_COLLEAGUES_FAILURE';

export const CHANGE_PHARMACY = 'pharmacy/CHANGE_PHARMACY';
export const CHANGE_PHARMACY_SUCCESS = 'pharmacy/CHANGE_PHARMACY_SUCCESS';
export const CHANGE_PHARMACY_FAILURE = 'pharmacy/CHANGE_PHARMACY_FAILURE';
export const SET_PHARMACY_ID = 'pharmacy/SET_PHARMACY_ID';
export const SET_SALES_AGENT = 'pharmacy/SET_SALES_AGENT';
export const SET_COLLEAGUES = 'pharmacy/SET_COLLEAGUES';
export const SET_PAGINATION_DATA = 'pharmacy/SET_PAGINATION_DATA';

export const loadListByZip = (zip, withUsers = false) => ({
    type: LOAD_LIST_BY_ZIP,
    payload: {zip, withUsers},
});

export const loadListBySearchString = (
    searchString,
    currentPage,
    totalPage
) => ({
    type: LOAD_LIST_BY_SEARCH_STRING,
    payload: {searchString, currentPage, totalPage},
});

export const loadListSuccess = (pharmacies) => ({
    type: LOAD_LIST_SUCCESS,
    payload: {
        pharmacies,
    },
});

export const loadListFailure = (error) => ({
    type: LOAD_LIST_FAILURE,
    payload: {
        error,
    },
});

export const setAll = (normalizedPharmacies) => ({
    type: SET_ALL,
    payload: {pharmacies: normalizedPharmacies},
});

export const setPaginationData = (current, total) => ({
    type: SET_PAGINATION_DATA,
    payload: {current, total},
});

export const loadOne = (id) => ({
    type: LOAD_ONE,
    payload: {
        id,
    },
});

export const loadCurrentWithColleagues = () => ({
    type: LOAD_CURRENT_WITH_COLLEAGUES,
});

export const loadCurrentWithColleaguesSuccess = () => ({
    type: LOAD_CURRENT_WITH_COLLEAGUES_SUCCESS,
});

export const loadCurrentWithColleaguesFailure = (error) => ({
    type: LOAD_CURRENT_WITH_COLLEAGUES_FAILURE,
    payload: {
        error,
    },
});

export const loadOneSuccess = (pharmacy) => ({
    type: LOAD_ONE_SUCCESS,
    payload: {
        pharmacy,
    },
});
export const addOne = (normalizedPharmacy) => ({
    type: ADD_ONE,
    payload: {
        pharmacy: normalizedPharmacy,
    },
});

export const clearOtherPharmacy = (normalizedPharmacy) => ({
    type: CLEAR_OTHER_PHARMACY,
    payload: {
        pharmacy: normalizedPharmacy,
    },
});

export const clearAllPharmacy = () => ({
    type: CLEAR_ALL_PHARMACY,
});

export const loadOneFailure = (error) => ({
    type: LOAD_ONE_FAILURE,
    payload: {
        error,
    },
});

export const changePharmacy = (pharmacyId, identificationNumber) => ({
    type: CHANGE_PHARMACY,
    payload: {pharmacyId, identificationNumber},
});

export const changePharmacySuccess = (userData) => ({
    type: CHANGE_PHARMACY_SUCCESS,
    payload: {userData},
});

export const changePharmacyFailure = (error) => ({
    type: CHANGE_PHARMACY_FAILURE,
    payload: {
        error,
    },
});
export const setPharmacyId = (id) => ({
    type: SET_PHARMACY_ID,
    payload: {
        id,
    },
});

export const setSalesAgent = (salesAgent) => ({
    type: SET_SALES_AGENT,
    payload: {salesAgent},
});

export const setColleagues = (colleagues) => ({
    type: SET_COLLEAGUES,
    payload: {colleagues},
});
