import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';

import store from './src/model';
import {LocationProvider} from './src/ui/utils/LocationContext';
import {
    SourceStaticBrands,
    SourceStaticCategories,
    SourceStaticTrainings,
} from './src/model/training';
import {SourceStaticRewards} from './src/model/reward';
import {SourceStaticNews} from './src/model/news';
import {SourceStaticPages} from './src/model/legal-pages';
import {Toast} from './src/ui/components/Toast';

export const onRouteUpdate = () => {
    if (process.env.NODE_ENV === 'production') {
        setTimeout(function () {}, 50);
    }
};

export const wrapRootElement = ({element}) => {
    return (
        <Provider store={store}>
            <SourceStaticBrands>
                <SourceStaticCategories>
                    <SourceStaticTrainings>
                        <SourceStaticRewards>
                            <SourceStaticNews>
                                <SourceStaticPages>
                                    <LocationProvider>
                                        {element}
                                    </LocationProvider>
                                </SourceStaticPages>
                            </SourceStaticNews>
                        </SourceStaticRewards>
                    </SourceStaticTrainings>
                </SourceStaticCategories>
            </SourceStaticBrands>
            <Toast />
        </Provider>
    );
};

wrapRootElement.propTypes = {
    element: PropTypes.node,
};
