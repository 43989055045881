// see https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Error

export default class BackendApiError extends Error {
    constructor(message, details = {}) {
        super(message);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BackendApiError);
        }
        this.details = details;
    }
}
