import styled from 'styled-components';

export const NotificationContainer = styled.div`
    padding: 1rem;
`;

export const Headline = styled.div`
    font-weight: bold;
`;

export const Text = styled.div`
    margin-top: 1rem;
`;
