import {createSelector} from 'reselect';

import {selectPendingRequests} from '../backend-api/selectors';
import {LOGIN, LOGOUT} from './actions';

export const selectDomain = (state) => state.authentication;

export const selectIsLoggedIn = createSelector(
    selectDomain,
    (authentication) => authentication.isLoggedIn
);

export const selectIsLoginPending = createSelector(
    selectPendingRequests,
    (pendingRequests) =>
        pendingRequests.some((request) => request.type === LOGIN)
);

export const selectIsLogoutPending = createSelector(
    selectPendingRequests,
    (pendingRequests) =>
        pendingRequests.some((request) => request.type === LOGOUT)
);

export const selectLastLoginDate = createSelector(
    selectDomain,
    (authentication) => authentication.lastLogin
);
