import {OPEN, CLOSE} from './actions';

const initialState = {
    isOpen: false,
    redirectRoute: null,
};

export default function (state = initialState, {type, payload}) {
    switch (type) {
        case OPEN:
            return {
                isOpen: true,
                ...payload,
            };
        case CLOSE:
            return initialState;
        default:
            return state;
    }
}
