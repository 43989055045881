import {combineReducers} from 'redux';

import {LOAD_CURRENT_CAMPAIGN_SUCCESS, RESET_CURRENT_CAMPAIGN} from './actions';

const initialState = {
    currentCampaign: null,
};

const currentCampaign = (
    state = initialState.currentCampaign,
    {type, payload}
) => {
    switch (type) {
        case LOAD_CURRENT_CAMPAIGN_SUCCESS:
            return payload.currentCampaign;
        case RESET_CURRENT_CAMPAIGN:
            return null;
        default:
            return state;
    }
};

export const internals = {
    initialState,
    currentCampaignReducer: currentCampaign,
};

export default combineReducers({
    currentCampaign,
});
