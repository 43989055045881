import React from 'react';
import {takeLatest, put, call} from 'redux-saga/effects';

import {fetch} from '../backend-api/';
import {renderBackendApiError} from '../../utils/error-utils';
import {SUBMIT_FORM, submitFormSuccess, submitFormFailure} from './actions';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';

import {datalayerPush} from '../../utils/tracking-utils';

function* postSubmitContactForm(data) {
    return yield call(fetch, SUBMIT_FORM, 'POST', '/contact', data);
}

function* submitContactFormSaga({payload}) {
    try {
        const {contactData} = payload;
        yield call(postSubmitContactForm, contactData);
        yield put(submitFormSuccess());
        datalayerPush({
            event: 'form_sent',
        });
        toast.info(
            <Notification headline={lang.contact.success.hl}>
                {lang.contact.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(submitFormFailure(error));
        toast.error(
            <Notification headline={lang.contact.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

export const internals = {
    postSubmitContactForm,
    submitContactFormSaga,
};

export default function* contactSaga() {
    yield takeLatest(SUBMIT_FORM, submitContactFormSaga);
}
