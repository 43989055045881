import {
    call,
    take,
    put,
    select,
    takeLatest,
    fork,
    all,
} from 'redux-saga/effects';
import {eventChannel, buffers} from 'redux-saga';
import {globalHistory} from '@reach/router';

import {LOCATION_CHANGE, locationChange} from './actions';
import {selectLocation, getRedirectedLocation} from './selectors';
import {selectRoles} from '../profile/selectors';
import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {selectIsLoggedIn} from '../authentication/selectors';

function onHistoryChange() {
    return eventChannel((emitter) => {
        globalHistory.listen(emitter);
        return () => {};
    }, buffers.expanding(5));
}

function sanitizeLocation(location) {
    const pathnameWithoutTrailingSlash = location.pathname.replace(/\/$/, '');
    return {
        ...location,
        pathname:
            location.pathname === '/' || pathnameWithoutTrailingSlash === '/'
                ? '/'
                : pathnameWithoutTrailingSlash,
    };
}

function* historyChangeSaga() {
    const channel = yield call(onHistoryChange);
    yield put(locationChange(sanitizeLocation(globalHistory.location)));
    while (true) {
        const {location} = yield take(channel);
        yield put(locationChange(sanitizeLocation(location)));
    }
}

function* protectLocation({payload}) {
    const {location} = payload;
    const isLoggedIn = yield select(selectIsLoggedIn);
    const roles = yield select(selectRoles);
    const to = getRedirectedLocation(location.pathname, isLoggedIn, roles);
    if (to !== location.pathname) {
        // This is a hack:
        // Can neither use navigate from @reach/router nor from gatsby
        // because of race conditions that apply when navigating too early
        // in case we are served by laravel with an initial logged in state.
        window.location.href = to;
    }
}

export default function* navigationSaga() {
    yield fork(historyChangeSaga);
    // Wait for the initial logged in state to be
    // determined and protect initial location if necessary:
    yield all([take(SET_IS_LOGGED_IN), take(LOCATION_CHANGE)]);
    const location = yield select(selectLocation);
    yield call(protectLocation, {
        payload: {
            location: {pathname: location.pathname},
        },
    });
    // Subsequently protect location whenever a navigation occurs:
    yield takeLatest(LOCATION_CHANGE, protectLocation);
}
