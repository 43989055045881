export const normalizePbAcademyDetails = (entry = null) => {
    if (!entry) return null;

    const {
        data: {
            training,
            dates,
            photos,
            survey_completed,
            training_completed,
            has_attended,
        },
    } = entry;

    return {
        training: {
            id: training.id,
            title: training.title,
            description: training.description,
            image: training.image,
            duration: training.duration,
            points: training.points,
            instruction: training.instruction,
            source: training.source,
            type: training.type,
            slug: training.slug,
            available_from: training.availableFrom,
            createdAt: training.created_at,
            updatedAt: training.updated_at,
            completed: training.completed,
        },
        dates: dates.map((date) => ({
            id: date.id,
            startDate: new Date(date.start_date).toLocaleDateString('de-DE', {
                day: '2-digit',
            }),
            endDate: new Date(date.end_date).toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }),
            userBooking: date.user_booking,
        })),
        photos: photos,
        surveyCompleted: survey_completed,
        trainingCompleted: training_completed,
        hasAttended: has_attended,
    };
};
