import {createSelector} from 'reselect';

export const selectDomain = (state) => state.userCampaignSubmissions;

export const selectCurrentCampaign = createSelector(
    selectDomain,
    (domain) => domain.currentCampaign
);

export const selectCurrentCampaignId = createSelector(
    selectCurrentCampaign,
    (currentCampaign) => (currentCampaign && currentCampaign.id) || null
);
