import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import {StyledToastContainer} from './styles';

export {default as Notification} from './Notification';

const options = {
    autoClose: 12000,
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_RIGHT,
    pauseOnHover: true,
    progress: 0.2,
};

export const Toast = () => <StyledToastContainer {...options} />;

export default toast;
