export const LOAD_POINTS_HISTORY = 'points-history/LOAD_POINTS_HISTORY';
export const LOAD_POINTS_HISTORY_SUCCESS =
    'points-history/LOADS_POINT_HISTORY_SUCCESS';
export const LOAD_POINTS_HISTORY_FAILURE =
    'points-history/LOAD_POINTS_HISTORY_FAILURE';
export const SET_HISTORY = 'points-history/SET_HISTORY';
export const CLEAR_POINTS_HISTORY = 'points-history/CLEAR_POINTS_HISTORY';
export const SET_PAGINATION_DATA = 'points-history/SET_PAGINATION_DATA';

export const loadPointsHistory = (page = 1) => ({
    type: LOAD_POINTS_HISTORY,
    payload: {page},
});

export const loadPointsHistorySuccess = (pointsHistory) => ({
    type: LOAD_POINTS_HISTORY_SUCCESS,
    payload: {pointsHistory},
});
export const loadPointsHistoryFailure = (error) => ({
    type: LOAD_POINTS_HISTORY_FAILURE,
    payload: {error},
});

export const setHistory = (normalizedHistory) => ({
    type: SET_HISTORY,
    payload: {history: normalizedHistory},
});

export const setPaginationData = (current, total) => ({
    type: SET_PAGINATION_DATA,
    payload: {current, total},
});

export const clearPointsHistory = () => ({
    type: CLEAR_POINTS_HISTORY,
});
