export const LOAD_SUBMISSIONS_SLIDER =
    'user-campaign-submissions-slider/LOAD_SUBMISSIONS_SLIDER';
export const LOAD_SUBMISSIONS_SLIDER_SUCCESS =
    'user-campaign-submissions-slider/LOAD_SUBMISSIONS_SLIDER_SUCCESS';
export const LOAD_SUBMISSIONS_SLIDER_FAILURE =
    'user-campaign-submissions-slider/LOAD_SUBMISSIONS_SLIDER_FAILURE';
export const SET_SUBMISSIONS_SLIDER =
    'user-campaign-submissions-slider/SET_SUBMISSION_SLIDER';
export const CLEAR_SUBMISSIONS_SLIDER =
    'user-campaign-submissions-slider/CLEAR_SUBMISSIONS_SLIDER';

export const loadSubmissionsSlider = (campaignId) => ({
    type: LOAD_SUBMISSIONS_SLIDER,
    payload: {campaignId},
});

export const loadSubmissionsSliderSuccess = (submissionsSlider) => ({
    type: LOAD_SUBMISSIONS_SLIDER_SUCCESS,
    payload: {submissionsSlider},
});
export const loadSubmissionsSliderFailure = (error) => ({
    type: LOAD_SUBMISSIONS_SLIDER_FAILURE,
    payload: {error},
});

export const setSubmissionsSlider = (images) => ({
    type: SET_SUBMISSIONS_SLIDER,
    payload: {images},
});

export const clearSubmissionsSlider = () => ({
    type: CLEAR_SUBMISSIONS_SLIDER,
});
