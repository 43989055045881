import {createSelector} from 'reselect';

import {compareDateDesc} from '../../utils/date-utils';

export const selectDomain = (state) => state.news;

export const selectById = createSelector(selectDomain, (news) => news.byId);

export const selectSortListDesc = createSelector(selectById, (byId) =>
    Object.values(byId).sort((a, b) =>
        compareDateDesc(a.updatedAt, b.updatedAt)
    )
);

export const selectAllWithFocus = createSelector(selectSortListDesc, (news) =>
    news.filter((news) => news.isFocus)
);
