import {createSelector} from 'reselect';

export const selectDomain = (state) => state.pharmacy;

export const selectPharmacyMapById = createSelector(
    selectDomain,
    (pharmacy) => pharmacy.pharmacies
);

export const selectPaginationData = createSelector(
    selectDomain,
    (domain) => domain.pagination
);

export const selectSortedPharmacyListByName = createSelector(
    selectPharmacyMapById,
    (pharmacies) =>
        Object.values(pharmacies).sort((a, b) => a.name.localeCompare(b.name))
);

export const selectPharmacyById = (state, id) => state.pharmacy.pharmacies[id];

export const selectCurrentPharmacyId = createSelector(
    selectDomain,
    (pharmacy) => pharmacy.currentPharmacyId
);

export const selectCurrentPharmacy = createSelector(
    selectPharmacyMapById,
    selectCurrentPharmacyId,
    (pharmacyMap, id) => pharmacyMap[id]
);

export const selectSalesAgent = createSelector(
    selectDomain,
    (pharmacy) => pharmacy.currentSalesAgent
);

export const selectColleagues = createSelector(
    selectDomain,
    (pharmacy) => pharmacy.currentColleagues || []
);

export const selectUserHighscore = createSelector(
    selectColleagues,
    (colleagues) => [...colleagues].sort((a, b) => b.points - a.points)
);
