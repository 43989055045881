import {combineReducers} from 'redux';
import {
    SET_HISTORY,
    SET_PAGINATION_DATA,
    CLEAR_POINTS_HISTORY,
} from './actions';

const initialState = {
    history: [],
    pagination: {
        currentPage: 0,
        totalPages: 0,
    },
};

const history = (state = initialState.history, {type, payload}) => {
    switch (type) {
        case SET_HISTORY:
            return payload.history;
        case CLEAR_POINTS_HISTORY:
            return initialState.history;
        default:
            return state;
    }
};

const pagination = (state = initialState.pagination, {type, payload}) => {
    switch (type) {
        case SET_PAGINATION_DATA:
            return {
                currentPage: payload.current,
                totalPages: payload.total,
            };
        case CLEAR_POINTS_HISTORY:
            return initialState.pagination;
        default:
            return state;
    }
};

export const internals = {
    historyReducer: history,
    paginationReducer: pagination,
    initialState,
};

export default combineReducers({
    history,
    pagination,
});
