import {call, takeLatest, put} from 'redux-saga/effects';

import {
    LOAD_PHARMACY_USERS,
    loadPharmacyUsersSuccess,
    loadPharmacyUsersFailure,
    setUsers,
    setPaginationData,
    clearPharmacyUsers,
} from './actions';

import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {normalizePharmacyUsersEntry, unNormalize} from './normalize';
import {fetch} from '../backend-api';

function* getPharmacyUsersSaga(
    page = 1,
    sortColumn = {0: 'desc'},
    search = ''
) {
    const [key] = Object.keys(sortColumn);
    const [value] = Object.values(sortColumn);
    const sort = value === 'desc' ? '-' : '';
    const sortCol = unNormalize[key];

    return yield call(
        fetch,
        LOAD_PHARMACY_USERS,
        'GET',
        `/me/users/allocated?sort=${sort}${sortCol}&filter[search]=${search}&page=${page}`
    );
}

function* loadPharmacyUsersSaga({payload: {page, sortColumn, search}}) {
    try {
        const {data: pharmacyUsers, meta} = yield call(
            getPharmacyUsersSaga,
            page,
            sortColumn,
            search
        );

        yield put(loadPharmacyUsersSuccess(pharmacyUsers));
        yield put(
            setUsers(
                pharmacyUsers.map((entry) => normalizePharmacyUsersEntry(entry))
            )
        );
        yield put(
            setPaginationData(
                meta.current_page,
                meta.last_page,
                meta.total,
                meta.to,
                meta.per_page
            )
        );
    } catch (error) {
        yield put(loadPharmacyUsersFailure(error));
    }
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

function* clearPharmacyUsersSaga() {
    yield put(clearPharmacyUsers());
}

export const internals = {
    isLoggedOut,
    loadPharmacyUsersSaga,
    clearPharmacyUsersSaga,
    getPharmacyUsersSaga,
};

export default function* pharmacyUsersSaga() {
    yield takeLatest(LOAD_PHARMACY_USERS, loadPharmacyUsersSaga);
    yield takeLatest(isLoggedOut, clearPharmacyUsersSaga);
}
