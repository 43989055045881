import {combineReducers} from 'redux';
import {
    SET_ASSIGNMENTS,
    SET_PAGINATION_DATA,
    CLEAR_POINTS_ASSIGNMENTS,
} from './actions';

const initialState = {
    assignments: [],
    pagination: {
        currentPage: 0,
        totalPages: 0,
    },
};

const assignments = (state = initialState.assignments, {type, payload}) => {
    switch (type) {
        case SET_ASSIGNMENTS:
            return payload.assignments;
        case CLEAR_POINTS_ASSIGNMENTS:
            return initialState.assignments;
        default:
            return state;
    }
};

const pagination = (state = initialState.pagination, {type, payload}) => {
    switch (type) {
        case SET_PAGINATION_DATA:
            return {
                currentPage: payload.current,
                totalPages: payload.total,
            };
        case CLEAR_POINTS_ASSIGNMENTS:
            return initialState.pagination;
        default:
            return state;
    }
};

export const internals = {
    assignmentsReducer: assignments,
    paginationReducer: pagination,
    initialState,
};

export default combineReducers({
    assignments,
    pagination,
});
