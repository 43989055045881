import React from 'react';
import {call, takeLatest, put, select} from 'redux-saga/effects';
import {
    LOGIN_SUCCESS,
    RESET_PASSWORD_SUCCESS,
    SET_IS_LOGGED_IN,
} from '../authentication/actions';
import {SUBMIT_ACTIVATION_TOKEN_SUCCESS} from '../registration/actions';
import {
    LOAD_USER_PROFILE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_SETTINGS,
    clearUserProfile,
    loadUserProfileSuccess,
    loadUserProfileFailure,
    setUserProfile,
    updateUserProfileSuccess,
    updateUserProfileFailure,
    updateUserSettingsSuccess,
    updateUserSettingsFailure,
    UPDATE_USER_SETTINGS_SUCCESS,
} from './actions';
import {fetch} from '../backend-api/';
import {normalizeProfileResponse, denormalizeProfileRequest} from './normalize';

import {selectUser} from './selectors';
import {renderBackendApiError} from '../../utils/error-utils';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {CHANGE_PHARMACY_SUCCESS} from '../pharmacy/actions';

function* getProfileSaga() {
    return yield call(fetch, LOAD_USER_PROFILE, 'GET', '/me');
}

function* patchProfileSaga(userData) {
    return yield call(fetch, UPDATE_USER_PROFILE, 'PATCH', '/me', userData);
}

function* putUserSettingsSaga(settings) {
    return yield call(
        fetch,
        UPDATE_USER_SETTINGS,
        'PUT',
        '/me/settings',
        settings
    );
}

function* loadProfileSaga() {
    try {
        const {data: userData} = yield call(getProfileSaga);
        yield put(loadUserProfileSuccess(userData));
        yield put(setUserProfile(normalizeProfileResponse(userData)));
    } catch (error) {
        yield put(loadUserProfileFailure(error));
    }
}

function* setProfileSaga({payload}) {
    const {userData} = payload;
    yield put(setUserProfile(normalizeProfileResponse(userData)));
}

function* updateUserSettingsSaga({payload}) {
    try {
        const {data: userData} = yield call(putUserSettingsSaga, {
            categories: payload.categories,
            type: payload.userType,
        });
        yield put(updateUserSettingsSuccess(userData));
        if (!payload.silent) {
            const successNotification =
                payload.categories && payload.userTyoe
                    ? lang.updateUserSettings.success1
                    : payload.categories
                    ? lang.updateUserSettings.success2
                    : payload.userType
                    ? lang.updateUserSettings.success3
                    : '';
            successNotification &&
                toast.info(
                    <Notification headline={successNotification.hl}>
                        {successNotification.msg}
                    </Notification>
                );
        }
    } catch (error) {
        yield put(updateUserSettingsFailure(error));
        toast.error(
            <Notification headline={lang.updateUserSettings.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}
function* updateProfileSaga({payload}) {
    const currentUserProfile = yield select(selectUser);
    const updatedProfile = {...currentUserProfile, ...payload.updateData};
    try {
        const {data: userData} = yield call(
            patchProfileSaga,
            denormalizeProfileRequest(updatedProfile)
        );
        yield put(updateUserProfileSuccess(userData));
        toast.info(
            <Notification headline={lang.updateUserProfile.success.hl}>
                {lang.updateUserProfile.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(updateUserProfileFailure(error));
        toast.error(
            <Notification headline={lang.updateUserProfile.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

function* clearProfileSaga() {
    yield put(clearUserProfile());
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

export const internals = {
    setProfileSaga,
    isLoggedOut,
    loadProfileSaga,
    clearProfileSaga,
    updateProfileSaga,
    patchProfileSaga,
    getProfileSaga,
    updateUserSettingsSaga,
    putUserSettingsSaga,
};

export default function* profileSaga() {
    yield takeLatest(
        [
            LOGIN_SUCCESS,
            UPDATE_USER_PROFILE_SUCCESS,
            RESET_PASSWORD_SUCCESS,
            SUBMIT_ACTIVATION_TOKEN_SUCCESS,
            UPDATE_USER_SETTINGS_SUCCESS,
            CHANGE_PHARMACY_SUCCESS,
        ],
        setProfileSaga
    );
    yield takeLatest(LOAD_USER_PROFILE, loadProfileSaga);
    yield takeLatest(isLoggedOut, clearProfileSaga);
    yield takeLatest(UPDATE_USER_PROFILE, updateProfileSaga);
    yield takeLatest(UPDATE_USER_SETTINGS, updateUserSettingsSaga);
}
