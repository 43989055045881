import React from 'react';
import {call, put, cancelled} from 'redux-saga/effects';
import {fetch as whatwgFetch} from 'whatwg-fetch';
import {Base64} from 'js-base64';

import {
    requestPending,
    requestSuccess,
    requestFailure,
    requestCancel,
} from './actions';
import BackendApiError from './error';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';

export const hostUrl = process.env.GATSBY_BACKEND_API_HOST_URL;
export const makeUrl = (path) =>
    path === '' || path == null
        ? ''
        : process.env.STORYBOOK_ENV
        ? path
        : `${hostUrl}${path}`;
export const apiBaseUrl = makeUrl(process.env.GATSBY_BACKEND_API_BASE_PATH);
export const basicAuthUsername =
    process.env.GATSBY_BACKEND_API_BASIC_AUTH_USERNAME;
export const basicAuthPassword =
    process.env.GATSBY_BACKEND_API_BASIC_AUTH_PASSWORD;

export const csrfCookieName = 'XSRF-TOKEN';
export const csrfHeaderName = 'X-XSRF-TOKEN';
export const csrfRequestCookieRoute = '/sanctum/csrf-cookie';

const CACHE = {};

function readCookie(name) {
    const match = document.cookie.match(
        new RegExp('(^|;\\s*)(' + name + ')=([^;]*)')
    );
    return match ? decodeURIComponent(match[3]) : null;
}

function cachedFetch(url, opts, maxCacheAge) {
    const cached = CACHE[url];
    if (
        opts.method === 'GET' &&
        cached &&
        Date.now() - cached.time < maxCacheAge
    ) {
        return cached;
    }
    const req = whatwgFetch(url, opts);
    req.time = Date.now();
    return (CACHE[url] = req);
}

function* fetchSaga(
    url,
    method,
    data = null,
    headers = {},
    maxCacheAge = 0,
    isFormData = false
) {
    const csrfToken = readCookie(csrfCookieName);
    const bodyData = isFormData ? data : JSON.stringify(data);

    const fetchHeaders = new Headers({
        Accept: 'application/json',
        ...(basicAuthUsername != null && basicAuthPassword != null
            ? {
                  Authorization:
                      'Basic ' +
                      Base64.encode(
                          `${basicAuthUsername}:${basicAuthPassword}`
                      ),
              }
            : {}),
        ...(csrfToken != null ? {[csrfHeaderName]: csrfToken} : {}),
        ...headers,
    });
    if (!isFormData) {
        fetchHeaders.append('Content-Type', 'application/json');
    }

    const response = yield cachedFetch(
        url,
        {
            method,
            headers: fetchHeaders,
            body: data ? bodyData : null,
            cache: 'default',
            credentials: 'include',
        },
        maxCacheAge
    );
    let responseData = null;
    try {
        responseData = yield response.json();
    } catch (error) {
        // ignore json parse error (e.g. on an empty response)
    }
    if (response.status < 200 || response.status >= 300) {
        // e.g. "Backend API error 404" or "Backend API error 422"
        throw new BackendApiError(`Backend API error ${response.status}`, {
            status: response.status, // e.g. 422
            code: responseData && responseData.code, // e.g. "INVALID_CREDENTIALS"
            message: responseData && responseData.message,
            errors: responseData && responseData.errors,
        });
    }
    return responseData;
}

export function* fetch(
    type,
    method,
    path,
    data = null,
    headers = {},
    maxCacheAge = 0, // disable for now
    isFormData
) {
    const pendingAction = yield put(requestPending(type, path));
    function* makeActualRequest() {
        const result = yield call(
            fetchSaga,
            `${apiBaseUrl}${path}`,
            method,
            data,
            headers,
            maxCacheAge,
            isFormData
        );
        yield put(requestSuccess(pendingAction.payload.id, type, path));
        return result;
    }
    function* handleError(error) {
        yield put(requestFailure(pendingAction.payload.id, type, path, error));
        if (
            error.message.startsWith('Network request failed') ||
            error.message.startsWith('Failed to fetch')
        ) {
            error.message = lang.connection.failure.msg;
            toast.warning(
                <Notification headline={lang.connection.failure.hl}>
                    {lang.connection.failure.msg}
                </Notification>
            );
        } else {
            console.warn(
                error.message,
                error.details?.status,
                error.details?.code,
                error.details?.message,
                error.details?.errors
            );
        }
        return error;
    }
    try {
        // Make the actual request:
        return yield call(makeActualRequest);
    } catch (error) {
        if (
            error instanceof BackendApiError &&
            (error.details.status === 401 ||
                error.details.message === 'CSRF token mismatch.')
        ) {
            try {
                // Request to set a CSRF cookie first:
                yield call(
                    fetchSaga,
                    `${hostUrl}${csrfRequestCookieRoute}`,
                    'GET'
                );
                // Retry the actual request:
                return yield call(makeActualRequest);
            } catch (error) {
                const newError = yield call(handleError, error);
                throw newError;
            }
        }
        const newError = yield call(handleError, error);
        throw newError;
    } finally {
        if (yield cancelled()) {
            yield put(requestCancel(pendingAction.payload.id, type, path));
        }
    }
}
