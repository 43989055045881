import {combineReducers} from 'redux';

import {LOAD_BANNER_CONTENT_SUCCESS, SET_IS_OPEN} from './actions';
import {LOGIN_SUCCESS} from '../authentication/actions';

export const initialState = {
    content: null,
    isOpen: null,
};

export const content = (state = initialState.content, {type, payload}) => {
    switch (type) {
        case LOAD_BANNER_CONTENT_SUCCESS:
            return payload.bannerContent;
        default:
            return state;
    }
};

export const isOpen = (state = initialState.isOpen, {type, payload}) => {
    switch (type) {
        case SET_IS_OPEN:
            return payload.isOpen;
        case LOGIN_SUCCESS:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    content,
    isOpen,
});
