import {combineReducers} from 'redux';

import {
    SET_ANSWER,
    SET_ACADEMY_DETAILS,
    CONFIRM_DATES_BOOKING,
    CONFIRM_ANSWERS,
    SET_DATES_BOOKING,
} from './actions';

const initialState = {
    answers: {},
    academyDetails: {},
    dates: [],
};

const answers = (state = initialState.answers, {type, payload}) => {
    switch (type) {
        case SET_ANSWER:
            return {...state, ...payload.answer};
        case CONFIRM_ANSWERS:
            return payload.allAnswers;
        default:
            return state;
    }
};
const academyDetails = (
    state = initialState.academyDetails,
    {type, payload}
) => {
    switch (type) {
        case SET_ACADEMY_DETAILS:
            return payload.academyDetails;
        default:
            return state;
    }
};
const dates = (state = initialState.dates, {type, payload}) => {
    switch (type) {
        case SET_DATES_BOOKING:
            return payload.dates;
        case CONFIRM_DATES_BOOKING:
            return payload.dates;
        default:
            return state;
    }
};

export const internals = {
    answersReducer: answers,
    academyDetailsReducer: academyDetails,
    datesReducer: dates,
    initialState,
};

export default combineReducers({
    answers,
    academyDetails,
    dates,
});
