export const SET_STATIC_REWARDS = 'reward/SET_STATIC_REWARDS';
export const SUBMIT_REWARD_ORDER = 'reward/SUBMIT_REWARD_ORDER';
export const SUBMIT_REWARD_ORDER_SUCCESS = 'reward/SUBMIT_REWARD_ORDER_SUCCESS';
export const SUBMIT_REWARD_ORDER_FAILURE = 'reward/SUBMIT_REWARD_ORDER_FAILURE';

export const setStaticRewards = (staticRewards) => ({
    type: SET_STATIC_REWARDS,
    payload: {
        staticRewards,
    },
});

export const submitRewardOrder = (id) => ({
    type: SUBMIT_REWARD_ORDER,
    payload: {id},
});

export const submitRewardOrderSuccess = () => ({
    type: SUBMIT_REWARD_ORDER_SUCCESS,
});

export const submitRewardOrderFailure = (error) => ({
    type: SUBMIT_REWARD_ORDER_FAILURE,
    payload: {error},
});
