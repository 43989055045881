import React from 'react';

import {BackendApiError} from '../model/backend-api';
import lang from '../lang/notification.lang';

export const renderBackendApiError = (error, username) => {
    if (error instanceof BackendApiError && error.details.errors) {
        return Object.entries(error.details.errors).map(([code, msg]) => (
            <div key={code}>{msg}</div>
        ));
    } else if (
        error instanceof BackendApiError &&
        error.details.code === 'ERR_NOT_ACTIVATED' &&
        error.details.message
    ) {
        return lang.login.failure.msgNotActivated(username);
    } else if (
        error instanceof BackendApiError &&
        error.details.code &&
        error.details.message
    ) {
        return error.details.message;
    } else {
        return error.message;
    }
};
