export const normalizeSubmission = (submission) => {
    if (!submission) return null;

    return {
        id: submission.id,
        title: submission.title,
        slug: submission.slug,
        type: submission.type,
        submissionStatus: submission.submission_status,
    };
};
