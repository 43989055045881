import {combineReducers} from 'redux';

import {SET_STATIC_PAGES} from './actions';

const initialState = {
    byId: {},
};

const byId = (state = initialState.byId, {type, payload}) => {
    switch (type) {
        case SET_STATIC_PAGES:
            return payload.staticPages.reduce(
                (legalPagesById, legalPages) => ({
                    ...legalPagesById,
                    [legalPages.id]: legalPages,
                }),
                {}
            );
        default:
            return state;
    }
};

export const internals = {
    initialState,
    byIdReducer: byId,
};

export default combineReducers({
    byId,
});
