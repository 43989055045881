import React from 'react';
import {takeLatest, call, put, select} from 'redux-saga/effects';
import {fetch} from '../backend-api';
import {
    LOAD_LIST_BY_ZIP,
    LOAD_LIST_BY_SEARCH_STRING,
    LOAD_ONE,
    LOAD_CURRENT_WITH_COLLEAGUES,
    loadListFailure,
    loadListSuccess,
    loadOneSuccess,
    loadOneFailure,
    loadCurrentWithColleaguesSuccess,
    loadCurrentWithColleaguesFailure,
    setAll,
    CHANGE_PHARMACY,
    addOne,
    setPharmacyId,
    setSalesAgent,
    changePharmacySuccess,
    changePharmacyFailure,
    setColleagues,
    setPaginationData,
} from './actions';

import {
    SET_IS_LOGGED_IN,
    RESET_PASSWORD_SUCCESS,
} from '../authentication/actions';
import {SUBMIT_ACTIVATION_TOKEN_SUCCESS} from '../registration/actions';

import {LOGIN_SUCCESS} from '../authentication/actions';
import {
    normalizePharmacyResponse,
    normalizeSalesAgentResponse,
} from './normalize';
import {
    LOAD_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_SUCCESS,
} from '../profile/actions';
import {selectUser} from '../profile/selectors';
import {selectCurrentPharmacyId} from './selectors';
import {denormalizeProfileRequest} from '../profile/normalize';
import {renderBackendApiError} from '../../utils/error-utils';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';

function* getListByZipSaga(page, zip, withUsers = false) {
    const url = withUsers
        ? `/pharmacies/?page=${page}&filter[search]=${zip}&include=users`
        : `/pharmacies/?page=${page}&filter[search]=${zip}`;

    return yield call(fetch, LOAD_LIST_BY_ZIP, 'GET', url);
}

function* getListBySearchStringSaga(page, searchString) {
    const url = `/pharmacies/?page=${page}&filter[search]=${searchString}&include=users`;

    return yield call(fetch, LOAD_LIST_BY_SEARCH_STRING, 'GET', url);
}

function* getOneSaga(id, withIncludes = false) {
    const url = withIncludes
        ? `/pharmacies/${id}?include=sales-representative,users`
        : `/pharmacies/${id}`;
    return yield call(fetch, LOAD_ONE, 'GET', url);
}

function* patchChangePharmacySaga(userData) {
    return yield call(fetch, CHANGE_PHARMACY, 'PATCH', '/me', userData);
}

function* loadListBySearchStringSaga({
    payload: {searchString, currentPage, totalPage},
}) {
    try {
        const {data: pharmacies, meta} = yield call(
            getListBySearchStringSaga,
            currentPage,
            searchString
        );
        yield put(loadListSuccess(pharmacies));
        yield put(setAll(pharmacies.map(normalizePharmacyResponse)));
        yield put(setPaginationData(meta.current_page, meta.last_page));
        yield call(loadCurrentWithColleaguesSaga);
    } catch (error) {
        yield put(loadListFailure(error));
    }
}
function* loadListByZipSaga({payload: {zip, withUsers}}) {
    try {
        let currentPage = 0;
        let lastPage;
        let pharmacies = [];
        do {
            const {data, meta} = yield call(
                getListByZipSaga,
                currentPage + 1,
                zip,
                withUsers
            );
            pharmacies = pharmacies.concat(data);
            currentPage = meta.current_page;
            lastPage = meta.last_page;
        } while (currentPage !== lastPage);
        yield put(loadListSuccess(pharmacies));
        yield put(setAll(pharmacies.map(normalizePharmacyResponse)));
        yield call(loadCurrentWithColleaguesSaga);
    } catch (error) {
        yield put(loadListFailure(error));
    }
}

function* loadOneSaga({payload}) {
    const {id} = payload;
    try {
        const {data: pharmacy} = yield call(getOneSaga, id);
        yield put(loadOneSuccess(pharmacy));
        yield put(addOne(normalizePharmacyResponse(pharmacy)));
    } catch (error) {
        yield put(loadOneFailure(error));
    }
}

function* loadCurrentWithColleaguesSaga() {
    try {
        const currentPharmacyId = yield select(selectCurrentPharmacyId);
        if (!currentPharmacyId) {
            throw new Error('error: pharmacy id is null');
        }
        const {data: pharmacy} = yield call(
            getOneSaga,
            currentPharmacyId,
            true
        );
        yield call(setUserPharmacySaga, {payload: {userData: {pharmacy}}});
        yield put(loadCurrentWithColleaguesSuccess());
    } catch (error) {
        yield put(loadCurrentWithColleaguesFailure(error));
    }
}

function* changePharmacySaga({payload}) {
    const currentProfile = yield select(selectUser);
    const {pharmacyId, identificationNumber} = payload;
    const updatedProfile = {
        ...currentProfile,
        pharmacyId,
        identificationNumber,
    };
    try {
        const {data: userData} = yield call(
            patchChangePharmacySaga,
            denormalizeProfileRequest(updatedProfile)
        );
        yield put(changePharmacySuccess(userData));
        yield call(setUserPharmacySaga, {payload: {userData}});
        toast.info(
            <Notification headline={lang.changePharmacy.success.hl}>
                {lang.changePharmacy.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(changePharmacyFailure(error));
        toast.error(
            <Notification headline={lang.changePharmacy.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

function* setUserPharmacySaga({payload}) {
    const {
        userData: {pharmacy},
    } = payload;
    if (pharmacy) {
        yield put(addOne(normalizePharmacyResponse(pharmacy)));
        yield put(setPharmacyId(pharmacy.id));

        yield put(
            setSalesAgent(
                pharmacy.sales_representative
                    ? normalizeSalesAgentResponse(pharmacy.sales_representative)
                    : null
            )
        );

        yield put(setColleagues(pharmacy.users || null));
    }
}

function* clearPharmacyDataOfUser() {
    yield put(setPharmacyId(null));
    yield put(setSalesAgent(null));
    yield put(setColleagues(null));
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

export const internals = {
    getListByZipSaga,
    loadOneSaga,
    loadListByZipSaga,
    loadCurrentWithColleaguesSaga,
    changePharmacySaga,
    getOneSaga,
    setUserPharmacySaga,
    clearPharmacyDataOfUser,
    patchChangePharmacySaga,
    isLoggedOut,
    loadListBySearchStringSaga,
};

export default function* pharmacySaga() {
    yield takeLatest(LOAD_LIST_BY_ZIP, loadListByZipSaga);
    yield takeLatest(LOAD_LIST_BY_SEARCH_STRING, loadListBySearchStringSaga);
    yield takeLatest(LOAD_ONE, loadOneSaga);
    yield takeLatest(CHANGE_PHARMACY, changePharmacySaga);
    yield takeLatest(
        LOAD_CURRENT_WITH_COLLEAGUES,
        loadCurrentWithColleaguesSaga
    );
    yield takeLatest(
        [
            LOGIN_SUCCESS,
            LOAD_USER_PROFILE_SUCCESS,
            UPDATE_USER_PROFILE_SUCCESS,
            RESET_PASSWORD_SUCCESS,
            SUBMIT_ACTIVATION_TOKEN_SUCCESS,
        ],
        setUserPharmacySaga
    );
    yield takeLatest(isLoggedOut, clearPharmacyDataOfUser);
}
