import {combineReducers} from 'redux';
import {
    ADD_ONE,
    SET_ALL,
    SET_PHARMACY_ID,
    SET_SALES_AGENT,
    SET_COLLEAGUES,
    CLEAR_OTHER_PHARMACY,
    CLEAR_ALL_PHARMACY,
    SET_PAGINATION_DATA,
} from './actions';

export const initialState = {
    pharmacies: {},
    currentPharmacyId: null,
    currentSalesAgent: null,
    currentColleagues: null,
    pagination: {
        currentPage: 1,
        totalPages: 1,
    },
};

export const pharmacies = (
    state = initialState.pharmacies,
    {type, payload}
) => {
    switch (type) {
        case SET_ALL:
            return payload.pharmacies.reduce(
                (pharmacies, pharmacy) => ({
                    ...pharmacies,
                    [pharmacy.id]: pharmacy,
                }),
                {}
            );
        case ADD_ONE:
            return {...state, [payload.pharmacy.id]: payload.pharmacy};
        case CLEAR_OTHER_PHARMACY:
            return {[payload.pharmacy.id]: payload.pharmacy};
        case CLEAR_ALL_PHARMACY:
            return {};
        default:
            return state;
    }
};

const pagination = (state = initialState.pagination, {type, payload}) => {
    switch (type) {
        case SET_PAGINATION_DATA:
            return {
                currentPage: payload.current,
                totalPages: payload.total,
            };
        case CLEAR_ALL_PHARMACY:
            return initialState.pagination;
        default:
            return state;
    }
};

export const currentPharmacyId = (
    state = initialState.currentPharmacyId,
    {type, payload}
) => {
    switch (type) {
        case SET_PHARMACY_ID:
            return payload.id;
        default:
            return state;
    }
};

export const currentSalesAgent = (
    state = initialState.currentSalesAgent,
    {type, payload}
) => {
    switch (type) {
        case SET_SALES_AGENT:
            return payload.salesAgent;
        default:
            return state;
    }
};

export const currentColleagues = (
    state = initialState.currentColleagues,
    {type, payload}
) => {
    switch (type) {
        case SET_COLLEAGUES:
            return payload.colleagues;
        default:
            return state;
    }
};

export const internals = {
    paginationReducer: pagination,
    initialState,
};

export default combineReducers({
    pharmacies,
    currentPharmacyId,
    currentSalesAgent,
    currentColleagues,
    pagination,
});
