import {combineReducers} from 'redux';

import {SET_IS_LOGGED_IN, SET_LAST_LOGIN} from './actions';

const initialState = {
    isLoggedIn: null, // undecided during gatsby build or when not being served by laravel
    lastLogin: null,
};

const isLoggedIn = (state = initialState.isLoggedIn, {type, payload}) => {
    switch (type) {
        case SET_IS_LOGGED_IN:
            return payload.isLoggedIn;
        default:
            return state;
    }
};

const lastLogin = (state = initialState.lastLogin, {type, payload}) => {
    switch (type) {
        case SET_LAST_LOGIN:
            return payload.lastLogin;
        default:
            return state;
    }
};

export const internals = {
    isLoggedInReducer: isLoggedIn,
    lastLoginReducer: lastLogin,
};

export default combineReducers({
    isLoggedIn,
    lastLogin,
});
