export const LOAD_BANNER_CONTENT = 'interactive-banner/LOAD_BANNER_CONTENT';
export const LOAD_BANNER_CONTENT_SUCCESS =
    'interactive-banner/LOAD_BANNER_CONTENT_SUCCESS';
export const LOAD_BANNER_CONTENT_FAILURE =
    'interactive-banner/LOAD_BANNER_CONTENT_FAILURE';
export const SET_IS_OPEN = 'interactive-banner/SET_IS_OPEN';

export const loadBannerContent = () => ({
    type: LOAD_BANNER_CONTENT,
});

export const loadBannerContentSuccess = (bannerContent) => ({
    type: LOAD_BANNER_CONTENT_SUCCESS,
    payload: {bannerContent},
});

export const loadBannerContentFailure = (error) => ({
    type: LOAD_BANNER_CONTENT_FAILURE,
    payload: {error},
});

export const setIsOpen = (isOpen) => ({
    type: SET_IS_OPEN,
    payload: {isOpen},
});
