export const SUBMIT_FORM = 'contact-form/SUMBIT_FORM';
export const SUBMIT_FORM_SUCCESS = 'contact-form/SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'contact-form/SUBMIT_FORM_FAILURE';

export const submitForm = ({
    email,
    salutation,
    firstName,
    lastName,
    message,
}) => ({
    type: SUBMIT_FORM,
    payload: {
        contactData: {
            email,
            salutation,
            last_name: lastName,
            first_name: firstName,
            message,
        },
    },
});

export const submitFormSuccess = () => ({
    type: SUBMIT_FORM_SUCCESS,
});

export const submitFormFailure = (error) => ({
    type: SUBMIT_FORM_FAILURE,
    payload: {error},
});
