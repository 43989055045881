import {combineReducers} from 'redux';

import {
    UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_SUCCESS,
    LOAD_CURRENT_COMPETITION_SUCCESS,
} from './actions';

export const initialState = {
    fileUploadSuccess: false,
    currentCompetition: null,
};

const fileUploadSuccess = (state = initialState.fileUploadSuccess, {type}) => {
    switch (type) {
        case UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_SUCCESS:
            return true;
        default:
            return state;
    }
};

const currentCompetition = (
    state = initialState.currentCompetition,
    {type, payload}
) => {
    switch (type) {
        case LOAD_CURRENT_COMPETITION_SUCCESS:
            return payload.currentCompetition;
        default:
            return state;
    }
};

export const internals = {
    initialState,
    fileUploadSuccessReducer: fileUploadSuccess,
    currentCompetitionReducer: currentCompetition,
};

export default combineReducers({
    fileUploadSuccess,
    currentCompetition,
});
