import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import {resendActivationEmail} from '../model/registration';
import Link from '../ui/components/Link';
import {routes} from '../model/navigation/routes';

const MsgNotActivated = ({username}) => {
    const dispatch = useDispatch();
    return (
        <>
            Deine E-Mail-Adresse wurde noch nicht bestätigt. Klicke bitte{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
                style={{
                    color: 'white',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }}
                onClick={(event) => {
                    event.preventDefault();
                    dispatch(resendActivationEmail(username));
                }}>
                hier
            </a>
            , damit wir Dir einen neuen Bestätigungslink zusenden können.
        </>
    );
};

MsgNotActivated.propTypes = {
    username: PropTypes.string,
};

export default {
    connection: {
        failure: {
            hl: 'Verbindungsprobleme',
            msg: 'Bitte überprüfe Deine Internetverbindung',
        },
    },
    register: {
        form: {
            success: {
                hl: 'Ein Profil mit Stil!',
                msg:
                    "Weiter geht's: In Deinem E-Mail-Postfach wartet eine schicke Nachricht auf Dich. Bitte klicke auf den darin enthaltenen Link, um Deine E-Mail-Adresse zu bestätigen.",
            },
            failure: {
                hl:
                    'Das Registrierungsformular konnte nicht gesendet werden. Bitte versuche es erneut.',
            },
        },
        resendEmail: {
            success: {
                hl: 'Eine E-Mail zur Accountaktivierung wurde versendet.',
                msg:
                    "Weiter geht's: In Deinem E-Mail-Postfach wartet eine schicke Nachricht auf Dich. Bitte klicke auf den darin enthaltenen Link, um Deine E-Mail-Adresse zu bestätigen.",
            },
            failure: {
                hl:
                    'Die E-Mail zur Accountaktivierung konnte nicht versendet werden. Bitte versuche es erneut.',
            },
        },
        submitToken: {
            success: {
                hl: 'Jetzt kannst Du loslegen.',
                msg:
                    'Deine Aktivierung ist erfolgreich abgeschlossen. Wir wünschen Dir viel Spaß mit Deiner ganz persönlichen Lernkollektion!',
            },
            failure: {
                hl: 'Die Aktivierung Deines Accounts ist fehlgeschlagen.',
            },
        },
    },
    session: {
        failure: {
            hl: 'Deine Sitzung ist abgelaufen.',
            msg:
                'Du wurdest aus Sicherheitsgründen ausgeloggt. Bitte logge Dich erneut ein.',
        },
    },
    login: {
        failure: {
            hl: 'Login fehlgeschlagen.',
            msgNotActivated: (username) => (
                <MsgNotActivated username={username} />
            ),
        },
    },
    logout: {
        failure: {
            hl: 'Logout fehlgeschlagen.',
        },
    },
    resetPassword: {
        emailStep: {
            success: {
                hl:
                    'Die E-Mail zum Zurücksetzen des Passworts wurde versendet.',
                msg:
                    "Weiter geht's: In Deinem E-Mail-Postfach wartet eine schicke Nachricht auf Dich. Bitte klicke auf den darin enthaltenen Link, um Dein Passwort zurückzusetzen.",
            },
            failure: {
                hl:
                    'Die E-Mail zum Zurücksetzen des Passworts konnte nicht versendet werden.',
            },
        },
        changeStep: {
            success: {
                hl: 'Erfolgreich ausgemistet!',
                msg:
                    'Dein Passwort wurde erfolgreich geändert. Du kannst Dich nun mit Deinem neuen schicken Passwort einloggen.',
            },
            failure: {
                hl: 'Das Zurücksetzen Deines Passworts ist fehlgeschlagen.',
                msgInvalidLink:
                    'Ungültiger Link zum Zurücksetzen des Passworts.',
            },
        },
    },
    changePharmacy: {
        success: {
            hl: 'Veränderung tut gut!',
            msg:
                'Deine Apothekenzugehörigkeit wurde erfolgreich geändert. Wir wünschen Dir viel Spaß mit Deinem neuen Team.',
        },
        failure: {
            hl: 'Die Aktualisierung Deiner Daten ist fehlgeschlagen.',
        },
    },
    updateUserProfile: {
        success: {
            hl: 'Veränderung tut gut!',
            msg:
                'Deine persönlichen Daten wurden erfolgreich geändert. Wenn Du Deine E-Mail-Adresse geändert hast, schaue jetzt bitte in Dein Postfach, um die neue E-Mail-Adresse zu bestätigen. Merci!',
        },
        failure: {
            hl: 'Die Aktualisierung Deiner Daten ist fehlgeschlagen.',
        },
    },
    updateUserSettings: {
        success1: {
            hl: 'Veränderung tut gut!',
            msg:
                'Deine Interessen und Deine Berufsbezeichnung wurden erfolgreich geändert.',
        },
        success2: {
            hl: 'Veränderung tut gut!',
            msg: 'Dein Interessen wurden erfolgreich geändert.',
        },
        success3: {
            hl: 'Veränderung tut gut!',
            msg: 'Deine Berufsbezeichnung wurde erfolgreich geändert.',
        },
        failure: {
            hl: 'Die Aktualisierung Deiner Daten ist fehlgeschlagen.',
        },
    },
    contact: {
        success: {
            hl: 'Es geht doch nichts über persönliche Beratung!',
            msg:
                'Wir freuen uns über Deine Kontaktaufnahme und melden uns so schnell es geht bei Dir.',
        },
        failure: {
            hl: 'Deine Nachricht konnte nicht gesendet werden.',
        },
    },
    training: {
        finish: {
            success: {
                hl: (
                    <>
                        <strong>Glückwunsch!</strong>
                    </>
                ),
                msg: (points) =>
                    points > 0
                        ? `Dein Wissen sitzt perfekt. Du hast das Training bestanden und erhältst dafür ${points} Punkte.`
                        : 'Dein Wissen sitzt perfekt. Du hast das Training erneut bestanden.',
            },
            failure: {
                hl: 'Der Abschluss des Trainings ist fehlgeschlagen.',
            },
        },
    },
    reward: {
        failure: {
            hl: 'Deine Prämie konnte nicht bestellt werden.',
        },
    },
    assignPoints: {
        success: {
            hl: 'Punkte wurden vergeben.',
            msg: (
                <>
                    Die Punkte wurden erfolgreich vergeben.{' '}
                    <Link theme="white" href={routes.punktearchiv}>
                        Hier geht's zu Deinem Punktearchiv
                    </Link>
                    .
                </>
            ),
        },
        failure: {
            hl: 'Die Punktevergabe ist fehlgeschlagen.',
        },
    },
    pointsReceipt: {
        // manuelle Punktevergabe durch Außendienst nach Schulung
        manual: {
            confirm: {
                success: {
                    hl: 'Herzlichen Glückwunsch',
                    msg: (reason, points) =>
                        `Du hast ${points} Punkte erhalten! Sieh in der Punktehistorie nach, wofür Du Punkte erhalten hast.`,
                },
                failure: {
                    hl: 'Die Punkteannahme ist fehlgeschlagen.',
                },
            },
            reject: {
                success: {
                    hl: 'Schade',
                    msg: (reason) =>
                        `Für "${reason}" hast Du keine Punkte erhalten.`,
                },
                failure: {
                    hl: 'Die Punkteablehnung ist fehlgeschlagen.',
                },
            },
        },
    },
    evaluatedSubmission: {
        acceptedMessage: {
            hl: 'Das hat geklappt!',
            msg: 'Du hast erfolgreich Punkte für den Aktionsbeitrag vergeben.',
        },
        rejectedMessage: {
            hl: 'Das hat geklappt!',
            msg:
                'Du hast diesen Beitrag abgelehnt. Der Teilnehmer bekommt beim nächsten Login eine Benachrichtigung.',
        },
        failure: {
            hl: 'Da ist leider etwas schief gegangen',
            msg: 'Bitte versuche erneut den Aktionsbeitrag zu bewerten.',
        },
    },
    updloadSubmissionImage: {
        success: {
            hl: 'Das hat geklappt!',
            msg: (
                <>
                    Dein Aktionsbeitrag wurde erfolgreich hochgeladen. Dein
                    Außendienstmitarbeiter wird sich den Beitrag ansehen und Dir
                    dann dafür Punkte gutschreiben.
                </>
            ),
        },
        failure: {
            hl: 'Da ist leider etwas schief gegangen',
            msg: 'Es gibt aktuell keine laufende Aktion.',
        },
    },
    apothekentourSubmission: {
        failure: {
            hl: 'Da ist leider etwas schief gegangen',
            msg: 'Bitte versuche erneut ein Foto hochzuladen.',
        },
    },
};
