export const normalizeCampaignEntry = (campaign) => {
    if (!campaign) return null;

    return {
        id: campaign.id,
        title: campaign.title,
        headline: campaign.headline,
        description: campaign.description,
        points: campaign.points,
        type: campaign.type,
        submissionStatus: campaign.submission_status,
        headerDescription: campaign.header_description,
        headerImage: campaign.header_image,
        headerTitle: campaign.header_title,
    };
};
