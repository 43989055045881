import React from 'react';
import PropTypes from 'prop-types';
import {IconStyled} from './styles';
import {ReactComponent as AlertCircle} from '../../../assets/icons/icon-alert-circle.svg';
import {ReactComponent as ArrowBack} from '../../../assets/icons/icon-arrow-back.svg';
import {ReactComponent as ArrowForward} from '../../../assets/icons/icon-arrow-forward.svg';
import {ReactComponent as Checkmark} from '../../../assets/icons/icon-checkmark.svg';
import {ReactComponent as ChevronDown} from '../../../assets/icons/icon-chevron-down.svg';
import {ReactComponent as ChevronUp} from '../../../assets/icons/icon-chevron-up.svg';
import {ReactComponent as Clock} from '../../../assets/icons/icon-clock.svg';
import {ReactComponent as Email} from '../../../assets/icons/icon-email.svg';
import {ReactComponent as Facebook} from '../../../assets/icons/icon-facebook.svg';
import {ReactComponent as Info} from '../../../assets/icons/icon-info.svg';
import {ReactComponent as Instagram} from '../../../assets/icons/icon-instagram.svg';
import {ReactComponent as LinkedIn} from '../../../assets/icons/icon-linkedin.svg';
import {ReactComponent as LogIn} from '../../../assets/icons/icon-log-in.svg';
import {ReactComponent as LogOut} from '../../../assets/icons/icon-log-out.svg';
import {ReactComponent as Star} from '../../../assets/icons/icon-star.svg';
import {ReactComponent as TriangleRight} from '../../../assets/icons/icon-triangle-right.svg';
import {ReactComponent as Twitter} from '../../../assets/icons/icon-twitter.svg';
import {ReactComponent as Close} from '../../../assets/icons/icon-close.svg';
import {ReactComponent as Awards} from '../../../assets/icons/icon-awards.svg';
import {ReactComponent as Filter} from '../../../assets/icons/icon-filter.svg';
import {ReactComponent as Error} from '../../../assets/icons/icon-error-filled.svg';
import {ReactComponent as Radio} from '../../../assets/icons/icon-radio-active.svg';
import {ReactComponent as ArrowUpwardOutline} from '../../../assets/icons/icon-arrow-upward-outline.svg';
import {ReactComponent as Circle} from '../../../assets/icons/icon-circle.svg';
import {ReactComponent as CheckmarkCircleOutline} from '../../../assets/icons/icon-checkmark-circle-outline.svg';
import {ReactComponent as CheckmarkCircleFilled} from '../../../assets/icons/icon-checkmark-circle-filled.svg';
import {ReactComponent as CloseCircleOutline} from '../../../assets/icons/icon-close-circle-outline.svg';
import {ReactComponent as CloseOutline} from '../../../assets/icons/icon-close-outline.svg';
import {ReactComponent as MenuOutline} from '../../../assets/icons/icon-menu-outline.svg';
import {ReactComponent as MinusOutline} from '../../../assets/icons/icon-minus-outline.svg';
import {ReactComponent as PlusOutline} from '../../../assets/icons/icon-plus-outline.svg';
import {ReactComponent as StarFilled} from '../../../assets/icons/icon-star-filled.svg';
import {ReactComponent as StarOutline} from '../../../assets/icons/icon-star-outline.svg';
import {ReactComponent as User} from '../../../assets/icons/icon-user.svg';
import {ReactComponent as EmojiHappy} from '../../../assets/icons/icon-emoji-happy.svg';
import {ReactComponent as EmojiNeutral} from '../../../assets/icons/icon-emoji-neutral.svg';
import {ReactComponent as EmojiSad} from '../../../assets/icons/icon-emoji-sad.svg';
import {ReactComponent as SortArrows} from '../../../assets/icons/icon-sort-arrows.svg';
import {ReactComponent as ThumbUp} from '../../../assets/icons/icon-thumb-up.svg';
import {ReactComponent as ThumbDown} from '../../../assets/icons/icon-thumb-down.svg';
import {ReactComponent as TrashBin} from '../../../assets/icons/icon-trash-bin.svg';

export const icons = {
    alertCircle: AlertCircle,
    arrowBack: ArrowBack,
    arrowForward: ArrowForward,
    checkmark: Checkmark,
    chevronDown: ChevronDown,
    chevronUp: ChevronUp,
    clock: Clock,
    email: Email,
    facebook: Facebook,
    info: Info,
    instagram: Instagram,
    linkedIn: LinkedIn,
    login: LogIn,
    logout: LogOut,
    star: Star,
    triangleRight: TriangleRight,
    twitter: Twitter,
    close: Close,
    awards: Awards,
    arrowUpwardOutline: ArrowUpwardOutline,
    checkmarkCircleOutline: CheckmarkCircleOutline,
    checkmarkCircleFilled: CheckmarkCircleFilled,
    circle: Circle,
    closeCircleOutline: CloseCircleOutline,
    closeOutline: CloseOutline,
    menuOutline: MenuOutline,
    minusOutline: MinusOutline,
    plusOutline: PlusOutline,
    starFilled: StarFilled,
    starOutline: StarOutline,
    user: User,
    filter: Filter,
    error: Error,
    radio: Radio,
    happy: EmojiHappy,
    neutral: EmojiNeutral,
    sad: EmojiSad,
    sortArrows: SortArrows,
    thumbUp: ThumbUp,
    thumbDown: ThumbDown,
    trashBin: TrashBin,
};

const Icon = ({name, color, size, className, onClick, display}) => {
    if (!name) return null;
    const Icon = icons[name];
    if (!Icon) return null;
    return (
        <IconStyled
            onClick={onClick}
            color={color}
            size={size}
            display={display}
            className={className}>
            <Icon />
        </IconStyled>
    );
};

Icon.propTypes = {
    name: PropTypes.oneOf(Object.keys(icons)),
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    display: PropTypes.string,
};

Icon.defaultProps = {
    name: null,
    color: 'currentColor',
    size: '1em',
    className: 'ap-icon',
    onClick: null,
    display: 'block',
};

export default Icon;
