import {all, take, put} from 'redux-saga/effects';

import {
    SET_STATIC_TRAININGS,
    loadCompletion as loadTrainingsCompletion,
} from '../training/actions';
import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {loadUserProfile} from '../profile';
import {loadPendingReceipts} from '../points-receipt';

const isLoggedIn = (action) =>
    action.type === SET_IS_LOGGED_IN && action.payload.isLoggedIn === true;

export default function* pagesSaga() {
    // load one time data dependencies at startup
    yield all([take(isLoggedIn), take(SET_STATIC_TRAININGS)]);
    yield put(loadTrainingsCompletion());
    yield put(loadUserProfile());
    yield put(loadPendingReceipts());
}
