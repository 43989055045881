export const SET_STATIC_BRANDS = 'training/SET_STATIC_BRANDS';
export const SET_STATIC_CATEGORIES = 'training/SET_STATIC_CATEGORIES';
export const SET_STATIC_TRAININGS = 'training/SET_STATIC_TRAININGS';

export const LOAD_COMPLETION = 'training/LOAD_COMPLETION';
export const LOAD_COMPLETION_SUCCESS = 'training/LOAD_COMPLETION_SUCCESS';
export const LOAD_COMPLETION_FAILURE = 'training/LOAD_COMPLETION_FAILURE';

export const SET_SCORM_READY = 'training/SET_SCORM_READY';

export const START = 'training/START';
export const START_SUCCESS = 'training/START_SUCCESS';
export const START_FAILURE = 'training/START_FAILURE';

export const SET_PROGRESS = 'training/SET_PROGRESS';
export const SET_SCORE = 'training/SET_SCORE';
export const SET_SUCCESS_STATUS = 'training/SET_SUCCESS_STATUS';

export const FINISH = 'training/FINISH';
export const FINISH_SUCCESS = 'training/FINISH_SUCCESS';
export const FINISH_FAILURE = 'training/FINISH_FAILURE';

export const OPEN_SUCCESS_LIGHTBOX = 'training/OPEN_SUCCESS_LIGHTBOX';
export const CLOSE_SUCCESS_LIGHTBOX = 'training/CLOSE_SUCCESS_LIGHTBOX';

export const openSuccessLightbox = () => ({
    type: OPEN_SUCCESS_LIGHTBOX,
});

export const closeSuccessLightbox = () => ({
    type: CLOSE_SUCCESS_LIGHTBOX,
});

export const setStaticBrands = (staticBrands) => ({
    type: SET_STATIC_BRANDS,
    payload: {
        staticBrands,
    },
});

export const setStaticCategories = (staticCategories) => ({
    type: SET_STATIC_CATEGORIES,
    payload: {
        staticCategories,
    },
});

export const setStaticTrainings = (staticTrainings) => ({
    type: SET_STATIC_TRAININGS,
    payload: {
        staticTrainings,
    },
});

export const loadCompletion = () => ({
    type: LOAD_COMPLETION,
});

export const loadCompletionSuccess = (trainings) => ({
    type: LOAD_COMPLETION_SUCCESS,
    payload: {
        trainings,
    },
});

export const loadCompletionFailure = (error) => ({
    type: LOAD_COMPLETION_FAILURE,
    payload: {
        error,
    },
});

export const setScormReady = () => ({
    type: SET_SCORM_READY,
});

export const start = (id) => ({
    type: START,
    payload: {
        id,
    },
});

export const startSuccess = (id) => ({
    type: START_SUCCESS,
    payload: {
        id,
    },
});

export const startFailure = (error) => ({
    type: START_FAILURE,
    payload: {
        error,
    },
});

export const setProgress = (progress) => ({
    type: SET_PROGRESS,
    payload: {
        progress,
    },
});

export const setScore = (score) => ({
    type: SET_SCORE,
    payload: {
        score,
    },
});

export const setSuccessStatus = (successStatus) => ({
    type: SET_SUCCESS_STATUS,
    payload: {
        successStatus,
    },
});

export const finish = () => ({
    type: FINISH,
});

export const finishSuccess = (id) => ({
    type: FINISH_SUCCESS,
    payload: {
        id,
    },
});

export const finishFailure = (error) => ({
    type: FINISH_FAILURE,
    payload: {
        error,
    },
});
