export const SET_ANSWER = 'pb-academy/SET_ANSWER';
export const CONFIRM_ANSWERS = 'pb-academy/CONFIRM_ANSWERS';
export const CONFIRM_PENDING_ANSWER = 'pb-academy/CONFIRM_PENDING_ANSWER';
export const REJECT_PENDING_ANSWER = 'pb-academy/REJECT_PENDING_ANSWER';
export const CONFIRM_PENDING_ANSWER_SUCCESS =
    'pb-academy/CONFIRM_PENDING_ANSWER_SUCCESS';
export const CONFIRM_PENDING_ANSWER_FAILURE =
    'pb-academy/CONFIRM_PENDING_ANSWER_FAILURE';
export const LOAD_ACADEMY_DETAILS_SUCCESS =
    'pb-academy/LOAD_ACADEMY_DETAILS_SUCCESS';
export const LOAD_ACADEMY_DETAILS_FAILURE =
    'pb-academy/LOAD_ACADEMY_DETAILS_FAILURE';
export const LOAD_ACADEMY_DETAILS = 'pb-academy/LOAD_ACADEMY_DETAILS';
export const SET_ACADEMY_DETAILS = 'pb-academy/SET_ACADEMY_DETAILS';
export const CONFIRM_DATES_BOOKING = 'pb-academy/CONFIRM_DATES_BOOKING';
export const CONFIRM_PENDING_DATES_BOOKING_SUCCESS =
    'pb-academy/CONFIRM_PENDING_DATES_BOOKING_SUCCESS';
export const CONFIRM_PENDING_DATES_BOOKING_FAILURE =
    'pb-academy/CONFIRM_PENDING_DATES_BOOKING_FAILURE';
export const SET_DATES_BOOKING = 'pb-academy/SET_DATES_BOOKING';

export const setAnswer = (answer) => ({
    type: SET_ANSWER,
    payload: {answer},
});

export const confirmAnswers = (allAnswers) => ({
    type: CONFIRM_ANSWERS,
    payload: {allAnswers},
});

export const confirmPendingAnswerSuccess = (answer) => ({
    type: CONFIRM_PENDING_ANSWER_SUCCESS,
    payload: {answer},
});

export const confirmPendingAnswerFailure = (error) => ({
    type: CONFIRM_PENDING_ANSWER_FAILURE,
    payload: {error},
});

export const loadAcademyDetailsSuccess = (academyDetails) => ({
    type: LOAD_ACADEMY_DETAILS_SUCCESS,
    payload: {
        academyDetails,
    },
});
export const loadAcademyDetailsFailure = (error) => ({
    type: LOAD_ACADEMY_DETAILS_FAILURE,
    payload: {
        error,
    },
});
export const loadAcademyDetails = (academyDetails) => ({
    type: LOAD_ACADEMY_DETAILS,
    payload: {
        academyDetails,
    },
});
export const setAcademyDetails = (normalizedAcademyDetails) => ({
    type: SET_ACADEMY_DETAILS,
    payload: {
        academyDetails: normalizedAcademyDetails,
    },
});
export const setDatesBooking = (dates) => ({
    type: SET_DATES_BOOKING,
    payload: {
        dates,
    },
});
export const confirmDatesBooking = (dates) => ({
    type: CONFIRM_DATES_BOOKING,
    payload: {
        dates,
    },
});

export const confirmPendingDatesBookingSuccess = (dates) => ({
    type: CONFIRM_PENDING_DATES_BOOKING_SUCCESS,
    payload: {dates},
});

export const confirmPendingDatesBookingFailure = (error) => ({
    type: CONFIRM_PENDING_DATES_BOOKING_FAILURE,
    payload: {error},
});
