import {createSelector} from 'reselect';
export const selectDomain = (state) => state.pointsReceipt;

export const selectPendingTransactions = createSelector(
    selectDomain,
    (domain) => domain.pendingTransactions
);

export const selectPendingNotifications = createSelector(
    selectDomain,
    (domain) => domain.pendingNotifications
);

export const selectPendingReceipts = createSelector(
    selectPendingTransactions,
    selectPendingNotifications,
    (pendingTransactions, pendingNotifications) => [
        ...pendingTransactions,
        ...pendingNotifications,
    ]
);

export const selectLatestPendingReceipt = createSelector(
    selectPendingReceipts,
    (pendingReceipts) => pendingReceipts[0]
);

export const selectIsPointsReceiptLightboxOpen = createSelector(
    selectPendingReceipts,
    (pendingReceipts) =>
        pendingReceipts[0] != null && pendingReceipts[0].done !== true
);
