import styled from 'styled-components';

export const IconStyled = styled.span`
    min-width: 1em;
    min-height: 1em;
    width: ${(props) => props.size} !important;
    height: ${(props) => props.size} !important;
    line-height: 1;
    display: ${(props) => props.display};
    cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

    svg {
        width: 100%;
        height: 100%;
        fill: ${(props) => props.color};
    }
`;
