export const SET_IS_LOGGED_IN = 'authentication/SET_IS_LOGGED_IN';
export const SET_LAST_LOGIN = 'authentication/SET_LAST_LOGIN';
export const LOGIN = 'authentication/LOGIN';
export const LOGIN_SUCCESS = 'authentication/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'authentication/LOGIN_FAILURE';
export const LOGOUT = 'authentication/LOGOUT';
export const SEND_EMAIL_TO_RESET_PASSWORD =
    'authentification/SEND_EMAIL_TO_RESET_PASSWORD';
export const SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS =
    'authentification/SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS';
export const SEND_EMAIL_TO_RESET_PASSWORD_FAILURE =
    'authentification/SEND_EMAIL_TO_RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD = 'authentication/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'authentication/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'authentication/RESET_PASSWORD_FAILURE';

export const setIsLoggedIn = (isLoggedIn) => ({
    type: SET_IS_LOGGED_IN,
    payload: {
        isLoggedIn,
    },
});

export const setLastLogin = (lastLogin) => ({
    type: SET_LAST_LOGIN,
    payload: {
        lastLogin,
    },
});

export const login = (identifier, password) => ({
    type: LOGIN,
    payload: {
        identifier,
        password,
    },
});

export const loginSuccess = (userData) => ({
    type: LOGIN_SUCCESS,
    payload: {
        userData,
    },
});

export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: {
        error,
    },
});

export const logout = () => ({
    type: LOGOUT,
});

export const sendEmailToResetPassword = (email) => ({
    type: SEND_EMAIL_TO_RESET_PASSWORD,
    payload: {email},
});
export const sendEmailToResetPasswordSuccess = () => ({
    type: SEND_EMAIL_TO_RESET_PASSWORD_SUCCESS,
});
export const sendEmailToResetPasswordFailure = (error) => ({
    type: SEND_EMAIL_TO_RESET_PASSWORD_FAILURE,
    payload: {error},
});

export const resetPassword = (password, passwordConfirmation) => ({
    type: RESET_PASSWORD,
    payload: {password, passwordConfirmation},
});

export const resetPasswordSuccess = (userData) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: {userData},
});

export const resetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: {error},
});
