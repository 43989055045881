import {combineReducers} from 'redux';

import {LOAD_REASONS_SUCCESS} from './actions';

const initialState = {
    reasons: [],
};

const reasons = (state = initialState.reasons, {type, payload}) => {
    switch (type) {
        case LOAD_REASONS_SUCCESS:
            return payload.reasons;
        default:
            return state;
    }
};

export const internals = {
    initialState,
    reasonsReducer: reasons,
};

export default combineReducers({
    reasons,
});
