import React from 'react';
import {takeLatest, put, call, select} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {parse as parseQueryString} from 'qs';
import {match as matchPath} from '@reach/router/lib/utils';
import {navigate} from 'gatsby';

import {fetch} from '../backend-api/saga';
import BackendApiError from '../backend-api/error';
import {REQUEST_SUCCESS, REQUEST_FAILURE} from '../backend-api/actions';
import {
    LOGIN,
    LOGOUT,
    LOGIN_SUCCESS,
    loginSuccess,
    loginFailure,
    setIsLoggedIn,
    setLastLogin,
    SEND_EMAIL_TO_RESET_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    sendEmailToResetPasswordSuccess,
    sendEmailToResetPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
} from './actions';
import {selectIsLoggedIn} from './selectors';
import {loadUserProfile} from '../profile';
import {selectLocation} from '../navigation/selectors';
import {routes} from '../navigation/routes';
import {renderBackendApiError} from '../../utils/error-utils';
import {
    LOAD_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_SUCCESS,
} from '../profile/actions';
import {SUBMIT_ACTIVATION_TOKEN_SUCCESS} from '../registration/actions';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {close as closeLoginLightbox} from '../login-lightbox/actions';
import {selectDomain as selectLoginLightbox} from '../login-lightbox/selectors';
import {datalayerPush} from '../../utils/tracking-utils';
import {resetCurrentCampaign} from '../user-campaign-submissions';

// API endpoints that are accessible without authentication:
// if request succeeds, will NOT set isLoggedIn to true automatically
const publicApiPaths = [
    // don't put /login, /activate or /password/reset in here!
    '/register',
    '/activate/resend',
    '/password/email',
    '/pharmacies',
    '/trainings',
    '/contact',
];

// This is a hack to intercept all http requests to gatsby page-data json
// files and to detect whenever a request fails with status code 401
function onPageDataRequestFailure401() {
    return eventChannel((emitter) => {
        if (typeof window !== 'undefined') {
            (function (open) {
                window.XMLHttpRequest.prototype.open = function (method, url) {
                    if (url.startsWith('/page-data')) {
                        this.addEventListener(
                            'readystatechange',
                            function () {
                                if (
                                    this.readyState === 4 &&
                                    this.status === 401
                                ) {
                                    console.warn(
                                        'intercept',
                                        method,
                                        url,
                                        this.status
                                    );
                                    emitter(url);
                                }
                            },
                            false
                        );
                    }
                    open.apply(this, arguments);
                };
            })(window.XMLHttpRequest.prototype.open);
        }
        return () => {};
    });
}

function* postLoginSaga(type, identifier, password) {
    return yield call(fetch, type, 'POST', '/login', {
        identifier,
        password,
        remember: true,
    });
}

function* postLogoutSaga(type) {
    return yield call(fetch, type, 'POST', '/logout');
}

function* postSendEmailToResetPassword(email) {
    return yield call(
        fetch,
        SEND_EMAIL_TO_RESET_PASSWORD,
        'POST',
        '/password/email',
        {email}
    );
}

function* postResetPassword(user_id, token, password, password_confirmation) {
    return yield call(fetch, RESET_PASSWORD, 'POST', '/password/reset', {
        user_id,
        token,
        password,
        password_confirmation,
    });
}

function* loginUserSaga({payload}) {
    const {identifier, password} = payload;
    try {
        const {data: userData} = yield call(
            postLoginSaga,
            LOGIN,
            identifier,
            password
        );
        const {redirectRoute: loginLightboxRedirectRoute} = yield select(
            selectLoginLightbox
        );
        const location = yield select(selectLocation);
        const {redirect} = parseQueryString(location.search, {
            ignoreQueryPrefix: true,
        });
        if (
            redirect &&
            Object.values(routes).some((route) => matchPath(route, redirect))
        ) {
            navigate(redirect);
        } else {
            yield put(closeLoginLightbox());
            navigate(loginLightboxRedirectRoute || routes.start);
        }
        yield put(loginSuccess(userData));
        datalayerPush({event: 'user', user_id: userData.id});
    } catch (error) {
        yield put(loginFailure(error));
        toast.error(
            <Notification headline={lang.login.failure.hl}>
                {renderBackendApiError(error, identifier)}
            </Notification>
        );
    }
}

function* logoutUserSaga() {
    try {
        yield call(postLogoutSaga, LOGOUT);
        yield put(setIsLoggedIn(false));
        yield put(setLastLogin(null));
        yield put(resetCurrentCampaign());
        navigate(routes.start);
    } catch (error) {
        toast.error(
            <Notification headline={lang.logout.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

function* sessionValidSaga() {
    const isLoggedIn = yield select(selectIsLoggedIn);
    if (isLoggedIn !== true) {
        yield put(setIsLoggedIn(true));
    }
}

function* sessionExpiredSaga() {
    const isLoggedIn = yield select(selectIsLoggedIn);
    if (isLoggedIn !== false) {
        yield put(setIsLoggedIn(false));
    }
    if (isLoggedIn === true) {
        toast.warning(
            <Notification headline={lang.session.failure.hl}>
                {lang.session.failure.msg}
            </Notification>
        );
    }
}

function* setLastLoginSaga({payload}) {
    const {
        userData: {id, last_login},
    } = payload;
    yield put(setLastLogin(last_login));
    datalayerPush({event: 'user', user_id: id});
}

function* sendEmailToResetPasswordSaga({payload}) {
    try {
        const {email} = payload;
        yield call(postSendEmailToResetPassword, email);
        yield put(sendEmailToResetPasswordSuccess());
        toast.info(
            <Notification headline={lang.resetPassword.emailStep.success.hl}>
                {lang.resetPassword.emailStep.success.msg}
            </Notification>
        );
    } catch (error) {
        const {email} = payload;
        yield put(sendEmailToResetPasswordFailure(error));
        toast.error(
            <Notification headline={lang.resetPassword.emailStep.failure.hl}>
                {renderBackendApiError(error, email)}
            </Notification>
        );
    }
}

function* resetPasswordSaga({payload}) {
    try {
        const {password, passwordConfirmation} = payload;
        const {pathname, search} = yield select(selectLocation);
        const {token, user_id} = parseQueryString(search, {
            ignoreQueryPrefix: true,
        });

        if (pathname === routes.passwort_zuruecksetzen) {
            const {data: userData} = yield call(
                postResetPassword,
                user_id,
                token,
                password,
                passwordConfirmation
            );
            yield put(resetPasswordSuccess(userData));
            navigate(routes.trainings);
            toast.info(
                <Notification
                    headline={lang.resetPassword.changeStep.success.hl}>
                    {lang.resetPassword.changeStep.success.msg}
                </Notification>
            );
        } else {
            throw new Error('ERR_INVALID_URL');
        }
    } catch (error) {
        yield put(resetPasswordFailure(error));
        if (error.message === 'ERR_INVALID_URL') {
            toast.error(
                <Notification
                    headline={lang.resetPassword.changeStep.failure.hl}>
                    {lang.resetPassword.changeStep.failure.msgInvalidLink}
                </Notification>
            );
        } else {
            toast.error(
                <Notification
                    headline={lang.resetPassword.changeStep.failure.hl}>
                    {renderBackendApiError(error)}
                </Notification>
            );
        }
    }
}

export const internals = {
    postLoginSaga,
    postLogoutSaga,
    loginUserSaga,
    logoutUserSaga,
    sessionValidSaga,
    sessionExpiredSaga,
    setLastLoginSaga,
    sendEmailToResetPasswordSaga,
    postSendEmailToResetPassword,
    resetPasswordSaga,
    postResetPassword,
};

export default function* authenticationSaga() {
    yield takeLatest(LOGIN, loginUserSaga);
    yield takeLatest(LOGOUT, logoutUserSaga);
    yield takeLatest(
        [
            LOGIN_SUCCESS,
            UPDATE_USER_PROFILE_SUCCESS,
            LOAD_USER_PROFILE_SUCCESS,
            RESET_PASSWORD_SUCCESS,
            SUBMIT_ACTIVATION_TOKEN_SUCCESS,
        ],
        setLastLoginSaga
    );
    yield takeLatest(
        SEND_EMAIL_TO_RESET_PASSWORD,
        sendEmailToResetPasswordSaga
    );
    yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
    // Set logged in to true whenever a request succeeds, including
    // the login request but excluding any public API path:
    const API_REQUEST_SUCCESS = ({type, payload}) =>
        type === REQUEST_SUCCESS &&
        publicApiPaths.every(
            (publicApiPath) => !payload.path.startsWith(publicApiPath)
        );
    yield takeLatest(API_REQUEST_SUCCESS, sessionValidSaga);
    // Set logged in to false whenever an API request or a request
    // to gatsby page-data.json fails with status code 401:
    const API_REQUEST_FAILURE_401 = ({type, payload}) =>
        type === REQUEST_FAILURE &&
        payload.error instanceof BackendApiError &&
        payload.error.details.status === 401;
    yield takeLatest(API_REQUEST_FAILURE_401, sessionExpiredSaga);
    const pageDataRequestFailure401Channel = yield call(
        onPageDataRequestFailure401
    );
    yield takeLatest(pageDataRequestFailure401Channel, sessionExpiredSaga);
    // Set logged in state explicitly if initial state already
    // provided by laravel (this is for firing up some sagas that
    // wait for the login state to be determined), else (served by
    // develop-gatsby) we have to determine the logged in state by
    // making a test request:
    // see also: src/model/index.js
    const isInitiallyLoggedIn = yield select(selectIsLoggedIn);
    if (isInitiallyLoggedIn != null) {
        // served by laravel
        yield put(setIsLoggedIn(isInitiallyLoggedIn));
    } else {
        // served stand alone e.g. by gatsby develop
        yield put(loadUserProfile());
    }
}
