import {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {useDispatch} from 'react-redux';

import {setStaticTrainings} from './actions';

export default function SourceStaticTrainings({children}) {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const dispatch = useDispatch();
    const data = useStaticQuery(
        graphql`
            {
                allTraining {
                    edges {
                        node {
                            id
                            slug
                            type
                            title
                            description
                            instruction
                            duration
                            points
                            image
                            created_at
                            updated_at
                            available_from
                            fields {
                                route
                                iframe_url
                            }
                            brands {
                                id
                                name
                                image
                            }
                            categories {
                                id
                            }
                        }
                    }
                }
            }
        `
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    if (isFirstRender) {
        dispatch(
            setStaticTrainings(
                data.allTraining.edges.map((edge) => ({
                    id: +edge.node.id, // make it a number again
                    slug: edge.node.slug,
                    route: edge.node.fields.route,
                    type: edge.node.type,
                    title: edge.node.title,
                    description: edge.node.description,
                    instruction: edge.node.instruction,
                    duration: edge.node.duration,
                    points: edge.node.points,
                    image: edge.node.image,
                    iframeUrl: edge.node.fields.iframe_url,
                    brands: edge.node.brands,
                    categories: edge.node.categories.map(
                        (category) => category.id
                    ),
                    created: edge.node.created_at,
                    updated: edge.node.updated_at,
                    availableFrom: edge.node.available_from,
                }))
            )
        );
    }
    return children;
}
