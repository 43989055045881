import {combineReducers} from 'redux';

import {LOCATION_CHANGE} from './actions';

const initialState = {
    location: null,
};

const location = (state = initialState.location, {type, payload}) => {
    switch (type) {
        case LOCATION_CHANGE:
            return payload.location;
        default:
            return state;
    }
};

export const internals = {
    locationReducer: location,
};

export default combineReducers({
    location,
});
