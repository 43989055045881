import {call, takeLatest, put} from 'redux-saga/effects';

import {
    LOAD_POINTS_ASSIGNMENTS,
    loadPointsAssignmentsSuccess,
    loadPointsAssignmentsFailure,
    setAssignments,
    setPaginationData,
    clearPointsAssignments,
} from './actions';

import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {normalizePointsAssignmentsEntry} from './normalize';
import {fetch} from '../backend-api';

function* getPointsAssignmentsSaga(page = 1) {
    return yield call(
        fetch,
        LOAD_POINTS_ASSIGNMENTS,
        'GET',
        `/me/point-assignments?page=${page}`
    );
}

function* loadPointsAssignmentsSaga({payload: {page}}) {
    try {
        const {data: pointsAssignments, meta} = yield call(
            getPointsAssignmentsSaga,
            page
        );

        yield put(loadPointsAssignmentsSuccess(pointsAssignments));
        yield put(
            setAssignments(
                pointsAssignments.map((entry) =>
                    normalizePointsAssignmentsEntry(entry)
                )
            )
        );
        yield put(setPaginationData(meta.current_page, meta.last_page));
    } catch (error) {
        yield put(loadPointsAssignmentsFailure(error));
    }
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

function* clearPointsAssignmentsSaga() {
    yield put(clearPointsAssignments());
}

export const internals = {
    isLoggedOut,
    loadPointsAssignmentsSaga,
    clearPointsAssignmentsSaga,
    getPointsAssignmentsSaga,
};

export default function* pointsAssignmentsSaga() {
    yield takeLatest(LOAD_POINTS_ASSIGNMENTS, loadPointsAssignmentsSaga);
    yield takeLatest(isLoggedOut, clearPointsAssignmentsSaga);
}
