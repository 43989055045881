export const UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE =
    'user-pharmacy-tour-submission/UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE';
export const UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_SUCCESS =
    'user-pharmacy-tour-submission/UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_SUCCESS';
export const UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_FAILURE =
    'user-pharmacy-tour-submission/UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_FAILURE';
export const LOAD_CURRENT_COMPETITION =
    'user-campaign-submissions/LOAD_CURRENT_COMPETITION';
export const LOAD_CURRENT_COMPETITION_SUCCESS =
    'user-campaign-submissions/LOAD_CURRENT_COMPETITION_SUCCESS';
export const LOAD_CURRENT_COMPETITION_FAILURE =
    'user-campaign-submissions/LOAD_CURRENT_COMPETITION_FAILURE';

export const updloadSubmissionImageFile = (imageFile) => ({
    type: UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE,
    payload: {imageFile},
});
export const updloadSubmissionImageFileSuccess = () => ({
    type: UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_SUCCESS,
});
export const updloadSubmissionImageFileFailure = (error) => ({
    type: UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE_FAILURE,
    payload: {error},
});

export const loadCurrentCompetition = () => ({
    type: LOAD_CURRENT_COMPETITION,
});
export const loadCurrentCompetitionSuccess = (currentCompetition) => ({
    type: LOAD_CURRENT_COMPETITION_SUCCESS,
    payload: {currentCompetition},
});
export const loadCurrentCompetitionFailure = (error) => ({
    type: LOAD_CURRENT_COMPETITION_FAILURE,
    payload: {error},
});
