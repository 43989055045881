import {createSelector} from 'reselect';

export const selectDomain = (state) => state.backendApi;

export const selectPendingRequests = createSelector(
    selectDomain,
    (backendApi) => backendApi.pendingRequests
);

export const selectPendingRequestsSuccess = createSelector(
    selectDomain,
    (backendApi) => backendApi.pendingRequestsSuccess
);

export const selectPendingRequestsError = createSelector(
    selectDomain,
    (backendApi) => backendApi.pendingRequestsError
);

export const selectIsAnyRequestPending = createSelector(
    selectPendingRequests,
    (pendingRequests) => pendingRequests.length > 0
);

export const selectIsOnline = createSelector(
    selectDomain,
    (backendApi) => backendApi.isOnline
);
