import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';

import createRootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const initStore = function () {
    // most slice reducers receive undefined and will initialize themselves,
    // except for authentication->isLoggedIn and profile->roles,
    // which are being set directly to the initial server side state
    // if served by laravel:
    const initialState = {
        ...(typeof window !== 'undefined' &&
        window.isLoggedIn != null &&
        window.roles != null
            ? {
                  authentication: {
                      isLoggedIn: window.isLoggedIn,
                  },
                  profile: {
                      roles: window.roles,
                  },
              }
            : {}),
    };
    const appliedMiddleware = applyMiddleware(sagaMiddleware);
    const store = createStore(
        createRootReducer(),
        initialState,
        composeWithDevTools(appliedMiddleware) // use this during development only
        // TODO: use this before shipping to production: process.env.NODE_ENV === 'production' ? appliedMiddleware : composeWithDevTools(appliedMiddleware)
    );
    // Start sagas only if in browser environment and not during gatsby build:
    if (typeof window !== 'undefined') {
        sagaMiddleware.run(rootSaga);
    }
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers').default;
            store.replaceReducer(nextRootReducer);
        });
    }
    return {store};
};

// Simulate laravel providing the initial logged in state and role:
// if (typeof window !== 'undefined') {
//     window.isLoggedIn = false; // true | false
//     window.roles = []; // [] | ['USER'] | ["INTERIM"] | ['USER', 'TEST_USER'] | ['SALES_REPRESENTATIVE']
// }

// create the store once and export it for everyone to use:
export default process.env.NODE_ENV !== 'test' ? initStore().store : {};
