import {createSelector} from 'reselect';
import sampleSize from 'lodash.samplesize';

const compareByTypeAscByPointsAscByTitle = (reward1, reward2) => {
    if (reward1.type === reward2.type) {
        if (reward1.points === reward2.points) {
            return reward1.teaserHeadline.localeCompare(reward2.teaserHeadline);
        }
        return Math.sign(reward1.points - reward2.points);
    }
    if (reward1.type === 'product' && reward2.type === 'voucher') {
        return -1;
    }
    if (reward1.type === 'voucher' && reward2.type === 'product') {
        return 1;
    }
};

export const selectDomain = (state) => state.reward;

export const selectById = createSelector(selectDomain, (reward) => reward.byId);

export const selectAll = createSelector(selectById, (byId) =>
    Object.values(byId).sort(compareByTypeAscByPointsAscByTitle)
);

export const selectAllOfTypeProduct = createSelector(selectById, (byId) =>
    Object.values(byId)
        .filter((reward) => reward.type === 'product')
        .sort(compareByTypeAscByPointsAscByTitle)
);

export const selectAllOfTypeVoucher = createSelector(selectById, (byId) =>
    Object.values(byId)
        .filter((reward) => reward.type === 'voucher')
        .sort(compareByTypeAscByPointsAscByTitle)
);

export const suggest = (rewardsById, currentRewardId = null) => {
    const rewardsWithoutCurrent = Object.values(rewardsById).filter(
        (reward) => reward.id !== currentRewardId
    );
    return sampleSize(rewardsWithoutCurrent, 3); // always suggest 3 random rewards
};

export const internals = {
    compareByTypeAscByPointsAscByTitle,
};
