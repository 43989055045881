export const LOAD_SUBMISSIONS = 'campaign-submissions/LOAD_SUBMISSIONS';
export const LOAD_SUBMISSIONS_SUCCESS =
    'campaign-submissions/LOAD_SUBMISSIONS_SUCCESS';
export const LOAD_SUBMISSIONS_FAILURE =
    'campaign-submissions/LOAD_SUBMISSIONS_FAILURE';
export const SET_SUBMISSIONS = 'campaign-submissions/SET_SUBMISSIONS';
export const CLEAR_SUBMISSIONS = 'campaign-submissions/CLEAR_SUBMISSIONS';
export const SET_PAGINATION_DATA = 'campaign-submissions/SET_PAGINATION_DATA';
export const EVALUATE_SUBMISSION = 'campaign-submissions/EVALUATE_SUBMISSION';
export const EVALUATE_SUBMISSION_SUCCESS =
    'campaign-submissions/EVALUATE_SUBMISSION_SUCCESS';
export const EVALUATE_SUBMISSION_FAILURE =
    'campaign-submissions/EVALUATE_SUBMISSION_FAILURE';

export const loadSubmissions = (page = 1, isEvaluated) => ({
    type: LOAD_SUBMISSIONS,
    payload: {page, isEvaluated},
});

export const loadSubmissionsSuccess = (submissions) => ({
    type: LOAD_SUBMISSIONS_SUCCESS,
    payload: {submissions},
});
export const loadSubmissionsFailure = (error) => ({
    type: LOAD_SUBMISSIONS_FAILURE,
    payload: {error},
});

export const setSubmissions = (normalizedSubmissions) => ({
    type: SET_SUBMISSIONS,
    payload: {submissions: normalizedSubmissions},
});

export const setPaginationData = (current, total) => ({
    type: SET_PAGINATION_DATA,
    payload: {current, total},
});

export const clearSubmissions = () => ({
    type: CLEAR_SUBMISSIONS,
});

export const evaluateSubmission = (evaluateData) => ({
    type: EVALUATE_SUBMISSION,
    payload: {
        evaluateData,
    },
});

export const evaluateSubmissionSuccess = (submissionId) => ({
    type: EVALUATE_SUBMISSION_SUCCESS,
    payload: {submissionId},
});

export const evaluateSubmissionFailure = (error) => ({
    type: EVALUATE_SUBMISSION_FAILURE,
    payload: {error},
});
