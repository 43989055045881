export const normalizePointsHistoryEntry = (entry = null) => {
    if (!entry) return null;

    return {
        id: entry.id,
        date: entry.date,
        change: entry.change,
        message: entry.message,
    };
};
