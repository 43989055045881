import {combineReducers} from 'redux';

import {SET_STATIC_NEWS} from './actions';

const initialState = {
    byId: {},
};

const byId = (state = initialState.byId, {type, payload}) => {
    switch (type) {
        case SET_STATIC_NEWS:
            return payload.staticNews.reduce(
                (newsById, news) => ({
                    ...newsById,
                    [news.id]: news,
                }),
                {}
            );
        default:
            return state;
    }
};

export const internals = {
    initialState,
    byIdReducer: byId,
};

export default combineReducers({
    byId,
});
