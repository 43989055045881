export const normalizePharmacyResponse = (pharmacy = null) => {
    if (!pharmacy) return null;
    return {
        id: pharmacy.id,
        name: pharmacy.name,
        address: pharmacy.address,
        city: pharmacy.city,
        county: pharmacy.country,
        zip: pharmacy.zip_code,
        points: pharmacy.points,
        ranking: pharmacy.ranking,
        users: pharmacy.users?.map(normalizePharmacyUser),
    };
};

const normalizePharmacyUser = (user) => ({
    ...user,
    firstName: user.first_name,
    lastName: user.last_name,
    points: user.points,
});

export const normalizeSalesAgentResponse = (salesAgent = null) => {
    if (!salesAgent) return null;
    return {
        email: salesAgent.email,
        firstName: salesAgent.first_name,
        lastName: salesAgent.last_name,
        phone: salesAgent.tel,
        image: salesAgent.image,
        territory: salesAgent.territory,
        type: salesAgent.representative_type,
    };
};
