import React from 'react';
import {call, takeLatest, put} from 'redux-saga/effects';

import {fetch} from '../backend-api';
import {
    confirmPendingAnswerSuccess,
    confirmPendingAnswerFailure,
    loadAcademyDetailsSuccess,
    loadAcademyDetailsFailure,
    setAcademyDetails,
    LOAD_ACADEMY_DETAILS,
    CONFIRM_ANSWERS,
    CONFIRM_DATES_BOOKING,
    confirmPendingDatesBookingSuccess,
    confirmPendingDatesBookingFailure,
    setDatesBooking,
} from './actions';
import {normalizePbAcademyDetails} from './normalize';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {renderBackendApiError} from '../../utils/error-utils';

function* getAcademyDetailsSaga() {
    return yield call(fetch, LOAD_ACADEMY_DETAILS, 'GET', `/academy`);
}

function* loadAcademyDetailsSaga() {
    try {
        const response = yield call(getAcademyDetailsSaga);
        yield put(loadAcademyDetailsSuccess(response));
        yield put(setAcademyDetails(normalizePbAcademyDetails(response)));
        yield put(
            setDatesBooking(
                normalizePbAcademyDetails(response)
                    .dates.filter((date) => date.userBooking)
                    .map((date) => date.id)
            )
        );
    } catch (error) {
        yield put(loadAcademyDetailsFailure(error));
        toast.error(
            <Notification headline={lang.connection.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

function* postConfirmAnserSaga(allAnswers) {
    return yield call(fetch, CONFIRM_ANSWERS, 'POST', `/me/academy/survey`, {
        ...allAnswers,
    });
}
function* confirmAnserSaga({payload}) {
    const {allAnswers} = payload;
    try {
        const response = yield call(postConfirmAnserSaga, allAnswers);
        yield put(confirmPendingAnswerSuccess(response));
        yield call(loadAcademyDetailsSaga);
    } catch (error) {
        yield put(confirmPendingAnswerFailure(error));
        toast.error(
            <Notification headline={lang.connection.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

function* postBookingSaga(dates) {
    return yield call(
        fetch,
        CONFIRM_DATES_BOOKING,
        'POST',
        `/me/academy/bookings`,
        {
            dates,
        }
    );
}

function* updateBookingSaga({payload}) {
    const {dates} = payload;
    try {
        const response = yield call(postBookingSaga, dates);
        yield put(confirmPendingDatesBookingSuccess(response));
    } catch (error) {
        yield put(confirmPendingDatesBookingFailure(error));
        toast.error(
            <Notification headline={lang.connection.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

export const internals = {
    confirmAnserSaga,
    loadAcademyDetailsSaga,
    updateBookingSaga,
};

export default function* pbAcademySaga() {
    yield takeLatest(CONFIRM_ANSWERS, confirmAnserSaga);
    yield takeLatest(LOAD_ACADEMY_DETAILS, loadAcademyDetailsSaga);
    yield takeLatest(CONFIRM_DATES_BOOKING, updateBookingSaga);
}
