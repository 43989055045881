import {v4 as uuidv4} from 'uuid';

export const REQUEST_PENDING = 'backend-api/REQUEST_PENDING';
export const REQUEST_SUCCESS = 'backend-api/REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'backend-api/REQUEST_FAILURE';
export const REQUEST_CANCEL = 'backend-api/REQUEST_CANCEL';

export const requestPending = (type, path, id = uuidv4()) => ({
    type: REQUEST_PENDING,
    payload: {
        type,
        path,
        id,
    },
});

export const requestSuccess = (id, type, path) => ({
    type: REQUEST_SUCCESS,
    payload: {
        id,
        type,
        path,
    },
});

export const requestFailure = (id, type, path, error) => ({
    type: REQUEST_FAILURE,
    payload: {
        id,
        type,
        path,
        error,
    },
});

export const requestCancel = (id, type, path) => ({
    type: REQUEST_CANCEL,
    payload: {
        id,
        type,
        path,
    },
});
