import React from 'react';
import {takeLatest, call, put} from 'redux-saga/effects';
import {fetch} from '../backend-api';
import {
    LOAD_REASONS,
    SUBMIT_ASSIGN_POINTS,
    submitAssignPointsSuccess,
    submitAssignPointsFailure,
    loadReasonsSuccess,
    loadReasonsFailure,
} from './actions';
import toast, {Notification} from '../../ui/components/Toast';
import {renderBackendApiError} from '../../utils/error-utils';
import lang from '../../lang/notification.lang';

function* getReasonsSaga() {
    return yield call(fetch, LOAD_REASONS, 'GET', '/reasons');
}

function* postAssignPointsSaga(reasonId, userIds) {
    return yield call(
        fetch,
        SUBMIT_ASSIGN_POINTS,
        'POST',
        '/me/point-assignments',
        {
            reason_id: reasonId,
            users: userIds,
        }
    );
}

function* loadReasonsSaga() {
    try {
        const {data: reasons} = yield call(getReasonsSaga);
        yield put(loadReasonsSuccess(reasons));
    } catch (error) {
        yield put(loadReasonsFailure(error));
    }
}

function* submitAssignPointsSaga({payload}) {
    try {
        const {reasonId, userIds} = payload;
        yield call(postAssignPointsSaga, reasonId, userIds);
        yield put(submitAssignPointsSuccess());
        toast.info(
            <Notification headline={lang.assignPoints.success.hl}>
                {lang.assignPoints.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(submitAssignPointsFailure(error));
        toast.error(
            <Notification headline={lang.assignPoints.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
    yield null;
}

export const internals = {
    loadReasonsSaga,
    submitAssignPointsSaga,
    getReasonsSaga,
    postAssignPointsSaga,
};

export default function* pointsAssignmentSaga() {
    yield takeLatest(LOAD_REASONS, loadReasonsSaga);
    yield takeLatest(SUBMIT_ASSIGN_POINTS, submitAssignPointsSaga);
}
