export const LOAD_CURRENT_CAMPAIGN =
    'user-campaign-submissions/LOAD_CURRENT_CAMPAIGN';
export const LOAD_CURRENT_CAMPAIGN_SUCCESS =
    'user-campaign-submissions/LOAD_CURRENT_CAMPAIGN_SUCCESS';
export const LOAD_CURRENT_CAMPAIGN_FAILURE =
    'user-campaign-submissions/LOAD_CURRENT_CAMPAIGN_FAILURE';
export const UPLOAD_SUBMISSION_IMAGE_FILE =
    'user-campaign-submissions/UPLOAD_SUBMISSION_IMAGE_FILE';
export const UPLOAD_SUBMISSION_IMAGE_FILE_SUCCESS =
    'user-campaign-submissions/UPLOAD_SUBMISSION_IMAGE_FILE_SUCCESS';
export const UPLOAD_SUBMISSION_IMAGE_FILE_FAILURE =
    'user-campaign-submissions/UPLOAD_SUBMISSION_IMAGE_FILE_FAILURE';
export const RESET_CURRENT_CAMPAIGN =
    'user-campaign-submissions/RESET_CURRENT_CAMPAIGN';

export const updloadSubmissionImageFile = (imageFile) => ({
    type: UPLOAD_SUBMISSION_IMAGE_FILE,
    payload: {imageFile},
});
export const updloadSubmissionImageFileSuccess = () => ({
    type: UPLOAD_SUBMISSION_IMAGE_FILE_SUCCESS,
});
export const updloadSubmissionImageFileFailure = (error) => ({
    type: UPLOAD_SUBMISSION_IMAGE_FILE_FAILURE,
    payload: {error},
});

export const loadCurrentCampaign = () => ({
    type: LOAD_CURRENT_CAMPAIGN,
});
export const loadCurrentCampaignSuccess = (currentCampaign) => ({
    type: LOAD_CURRENT_CAMPAIGN_SUCCESS,
    payload: {currentCampaign},
});
export const loadCurrentCampaignFailure = (error) => ({
    type: LOAD_CURRENT_CAMPAIGN_FAILURE,
    payload: {error},
});
export const resetCurrentCampaign = () => ({
    type: RESET_CURRENT_CAMPAIGN,
});
