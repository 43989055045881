export const normalizeSubmissionEntry = (entry = null) => {
    if (!entry) return null;

    return {
        id: entry.id,
        title: entry.campaign_title,
        campaignStatus: entry.campaign_status,
        userName: entry.username,
        firstName: entry.user_first_name,
        lastName: entry.user_last_name,
        pharmacy: entry.pharmacy_name,
        files: entry.files,
        submissionStatus:
            entry.submission_status === 'accepted'
                ? 'angenommen'
                : entry.submission_status === 'rejected'
                ? 'abgelehnt'
                : 'pending',
    };
};

export const denormalizeSubmissionRequest = (submission = null) => {
    if (!submission) return null;
    return {
        id: submission.id,
        status: submission.isRejected ? 'rejected' : 'accepted',
        rejection_reason: submission.reason,
        review_message: submission.message,
    };
};
