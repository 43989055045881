import React from 'react';
import {takeLatest, call, put, select} from '@redux-saga/core/effects';

import {fetch} from '../backend-api';
import {
    UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE,
    updloadSubmissionImageFileSuccess,
    updloadSubmissionImageFileFailure,
    LOAD_CURRENT_COMPETITION,
    loadCurrentCompetitionSuccess,
    loadCurrentCompetitionFailure,
} from './actions';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {renderBackendApiError} from '../../utils/error-utils';
import {normalizeSubmission} from './normalize';
import {selectCurrentCompetitionId} from './selectors';

function* postUploadSubmissionImageFile(competitionId, data) {
    return yield call(
        fetch,
        UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE,
        'POST',
        `/me/competitions/${competitionId}/submissions`,
        data,
        null,
        null,
        true
    );
}

function* getCurrentCompetition() {
    return yield call(
        fetch,
        LOAD_CURRENT_COMPETITION,
        'GET',
        '/competitions/current'
    );
}

function* loadCurrentCompetitionSaga() {
    try {
        const {data: currentCompetition} = yield call(getCurrentCompetition);
        yield put(
            loadCurrentCompetitionSuccess(
                normalizeSubmission(currentCompetition)
            )
        );
    } catch (error) {
        yield put(loadCurrentCompetitionFailure(error));
    }
}

function* updloadSubmissionImageFileSaga({payload}) {
    try {
        const {imageFile} = payload;
        const currentCompetitionId = yield select(selectCurrentCompetitionId);
        if (!currentCompetitionId) {
            throw new Error('ERR_CURRENT_COMPETITION_NOT_AVAILABLE');
        }
        const formData = new FormData();
        formData.append('image', imageFile);
        yield call(
            postUploadSubmissionImageFile,
            currentCompetitionId,
            formData
        );
        yield put(updloadSubmissionImageFileSuccess());
        yield call(loadCurrentCompetitionSaga);
    } catch (error) {
        yield put(updloadSubmissionImageFileFailure(error));
        toast.error(
            <Notification headline={lang.apothekentourSubmission.failure.hl}>
                {error.toString() ===
                'Error: ERR_CURRENT_COMPETITION_NOT_AVAILABLE'
                    ? lang.apothekentourSubmission.failure.msg
                    : renderBackendApiError(error)}
            </Notification>
        );
    }
}

export const internals = {
    loadCurrentCompetitionSaga,
    updloadSubmissionImageFileSaga,
    getCurrentCompetition,
};

export default function* userCompetitionSubmissionsSaga() {
    yield takeLatest(LOAD_CURRENT_COMPETITION, loadCurrentCompetitionSaga);
    yield takeLatest(
        UPLOAD_PHARMACY_TOUR_SUBMISSION_IMAGE_FILE,
        updloadSubmissionImageFileSaga
    );
}
