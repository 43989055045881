export const OPEN = 'login-lightbox/OPEN';
export const CLOSE = 'login-lightbox/CLOSE';

export const open = (redirectRoute = null) => ({
    type: OPEN,
    payload: {
        redirectRoute,
    },
});

export const close = () => ({
    type: CLOSE,
});
