import {combineReducers} from 'redux';
import {
    SET_USER_PROFILE,
    CLEAR_USER_PROFILE,
    ADD_USER_POINTS,
    SET_TEMP_USER_SETTINGS,
    CLEAR_TEMP,
} from './actions';

const initialState = {
    // roles:
    // - initial user roles from server side state,
    // - undecided during gatsby build or when not being served by laravel
    // - kept in sync with user.roles from user profile API
    // - as soon as the login state is determined by getting the user profile,
    //   roles can also be assumed to have updated
    // - reset when logging out
    roles: [], // undecided
    user: null, // user obtained from API
    temp: {},
};

const roles = (state = initialState.roles, {type, payload}) => {
    switch (type) {
        case SET_USER_PROFILE:
            return payload.userProfile.roles;
        case CLEAR_USER_PROFILE:
            return initialState.roles;
        default:
            return state;
    }
};

const user = (state = initialState.user, {type, payload}) => {
    switch (type) {
        case SET_USER_PROFILE:
            return payload.userProfile;
        case CLEAR_USER_PROFILE:
            return initialState.user;
        case ADD_USER_POINTS:
            return {...state, points: state.points + payload.points};
        default:
            return state;
    }
};

const temp = (state = initialState.temp, {type, payload}) => {
    switch (type) {
        case SET_TEMP_USER_SETTINGS:
            return {
                ...state,
                categories:
                    payload.categories != null
                        ? payload.categories
                        : state.categories,
                userType:
                    payload.userType != null
                        ? payload.userType
                        : state.userType,
            };
        case CLEAR_TEMP:
        case SET_USER_PROFILE:
            return initialState.temp;
        default:
            return state;
    }
};

export const internals = {
    rolesReducer: roles,
    userReducer: user,
    tempReducer: temp,
    initialState,
};

export default combineReducers({
    roles,
    user,
    temp,
});
