import {combineReducers} from 'redux';

import {
    SET_REDIRECT_URL,
    SET_REFERRAL_CODE_ACTIVE,
    SET_REFERRAL_CODE_PASSIVE,
} from './actions';

const initialState = {
    redirectUrl: null,
};

const redirectUrl = (state = initialState.redirectUrl, {type, payload}) => {
    switch (type) {
        case SET_REDIRECT_URL:
            return payload.url;
        default:
            return state;
    }
};
const referralCodeStatus = (state = false, {type}) => {
    switch (type) {
        case SET_REFERRAL_CODE_ACTIVE:
            return true;
        case SET_REFERRAL_CODE_PASSIVE:
            return false;
        default:
            return state;
    }
};

export const internals = {
    initialState,
    redirectUrlReducer: redirectUrl,
    referralCodeStatus,
};

export default combineReducers({
    redirectUrl,
    referralCodeStatus,
});
