import {createSelector} from 'reselect';
import sampleSize from 'lodash.samplesize';
import moment from 'moment';

const compareAscByName = (item1, item2) => item1.name.localeCompare(item2.name);

const compareAscByIncompletenessDescByCreatedAscByTitle = (
    training1,
    training2
) => {
    if (training1.completed === training2.completed) {
        const created1 = new Date(training1.created).getTime();
        const created2 = new Date(training2.created).getTime();
        if (created1 === created2) {
            return training1.title.localeCompare(training2.title);
        }
        return Math.sign(created2 - created1);
    }
    return +training1.completed - +training2.completed;
};

export const selectDomain = (state) => state.training;

export const selectTypesById = createSelector(
    selectDomain,
    (training) => training.typesById
);

export const selectBrandsById = createSelector(
    selectDomain,
    (training) => training.brandsById
);

export const selectCategoriesById = createSelector(
    selectDomain,
    (training) => training.categoriesById
);

export const selectById = createSelector(
    selectDomain,
    selectTypesById,
    selectBrandsById,
    selectCategoriesById,
    (training, typesById, brandsById, categoriesById) =>
        Object.values(training.byId).reduce(
            (trainings, training) => ({
                ...trainings,
                [training.id]: {
                    ...training,
                    type: typesById[training.type],
                    isReleased: training.availableFrom
                        ? moment().isAfter(training.availableFrom)
                        : true,
                    releaseDate: training.availableFrom
                        ? moment(training.availableFrom).format('DD.MM.YYYY')
                        : '',
                    categories: training.categories.map(
                        (category) => categoriesById[category]
                    ),
                },
            }),
            {}
        )
);

export const selectTypes = createSelector(selectTypesById, (typesById) =>
    Object.values(typesById).sort(compareAscByName)
);

export const selectBrands = createSelector(selectBrandsById, (brandsById) =>
    Object.values(brandsById).sort(compareAscByName)
);

export const selectBrandsForTrainings = createSelector(
    selectById,
    selectBrands,
    (trainingsById, brands) => {
        const trainings = Object.values(trainingsById);
        return brands.filter((brand) =>
            trainings.some((training) =>
                training.brands.find(
                    (trainingBrand) => trainingBrand.id === brand.id
                )
            )
        );
    }
);

export const selectCategories = createSelector(
    selectCategoriesById,
    (categoriesById) => Object.values(categoriesById).sort(compareAscByName)
);

export const selectIsScormReady = createSelector(
    selectDomain,
    (training) => training.isScormReady
);

export const selectCurrent = createSelector(
    selectDomain,
    (training) => training.current
);

export const selectIsCurrentCompleted = createSelector(
    selectCurrent,
    (current) => current.completionStatus === 'completed'
);

export const filter = (
    trainingsById,
    filterTypes = [],
    filterBrands = [],
    filterCategories = []
) => {
    const result = Object.values(trainingsById)
        .filter(
            (training) =>
                (filterTypes.length > 0
                    ? filterTypes.includes(training.type.id)
                    : true) &&
                (filterBrands.length > 0
                    ? training.brands
                          .map((trainingBrand) => trainingBrand.id)
                          .some((brand) => filterBrands.includes(brand))
                    : true) &&
                (filterCategories.length > 0
                    ? filterCategories.some((filterCategory) =>
                          training.categories.some(
                              (trainingCategory) =>
                                  trainingCategory.id === filterCategory
                          )
                      )
                    : true)
        )
        .sort(compareAscByIncompletenessDescByCreatedAscByTitle);
    return result;
};

// NOTE: suggest only trainings that are not completed and not current!
export const suggest = (
    trainingsById,
    isLoggedIn = false,
    userCategories = [],
    currentTrainingId = null
) => {
    const onlyIncompleteAndNotCurrent = (training) =>
        training.completed !== true && training.id !== currentTrainingId;
    const trainingsMatchingInterest = isLoggedIn
        ? userCategories.length > 0
            ? // match interest exactly:
              filter(trainingsById, [], [], userCategories).filter(
                  onlyIncompleteAndNotCurrent
              )
            : [
                  // no user categories -> no matches
              ]
        : sampleSize(filter(trainingsById), 3); // not logged in -> 3 random matches
    const trainingsNotMatchingInterest = isLoggedIn
        ? userCategories.length > 0
            ? // get all sorted
              filter(trainingsById)
                  // prefilter
                  .filter(onlyIncompleteAndNotCurrent)
                  // only those that don't match interest:
                  .filter((training) =>
                      training.categories.every((trainingCategory) =>
                          userCategories.every(
                              (userCategory) =>
                                  trainingCategory.id !== userCategory
                          )
                      )
                  )
            : filter(trainingsById).filter(onlyIncompleteAndNotCurrent) // no user categories -> all are non-matching
        : [
              // not logged in -> only 3 random matches, no non-matching
          ];
    return {
        numTrainingsMatchingInterest: trainingsMatchingInterest.length,
        numTrainingsNotMatchingInterest: trainingsNotMatchingInterest.length,
        suggestedTrainings:
            trainingsMatchingInterest.length > 0
                ? trainingsMatchingInterest
                : trainingsNotMatchingInterest,
    };
};

export const internals = {
    compareAscByName,
    compareAscByIncompletenessDescByCreatedAscByTitle,
};
