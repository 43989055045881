import {combineReducers} from 'redux';
import {
    SET_USERS,
    SET_PAGINATION_DATA,
    CLEAR_PHARMACY_USERS,
    SET_SEARCH,
    CLEAR_SEARCH,
} from './actions';

const initialState = {
    users: [],
    pagination: {
        currentPage: 0,
        totalPages: 0,
        totalUsers: 0,
        toPage: 0,
        perPage: 0,
    },
    search: '',
};

const users = (state = initialState.users, {type, payload}) => {
    switch (type) {
        case SET_USERS:
            return payload.users;
        case CLEAR_PHARMACY_USERS:
            return initialState.users;
        default:
            return state;
    }
};

const pagination = (state = initialState.pagination, {type, payload}) => {
    switch (type) {
        case SET_PAGINATION_DATA:
            return {
                currentPage: payload.current,
                totalPages: payload.total,
                totalUsers: payload.totalUsers,
                toPage: payload.toPage,
                perPage: payload.perPage,
            };
        case CLEAR_PHARMACY_USERS:
            return initialState.pagination;
        default:
            return state;
    }
};

const search = (state = initialState.search, {type, payload}) => {
    switch (type) {
        case SET_SEARCH:
            return payload.search;
        case CLEAR_SEARCH:
            return initialState.search;
        default:
            return state;
    }
};

export const internals = {
    usersReducer: users,
    searchReducer: search,
    paginationReducer: pagination,
    initialState,
};

export default combineReducers({
    users,
    pagination,
    search,
});
