import {createSelector} from 'reselect';

export const selectDomain = (state) => state.userPharmacyTourSubmissions;

export const selectIsFormSendSuccess = createSelector(
    selectDomain,
    (domain) => domain.fileUploadSuccess
);

export const selectCurrentCompetition = createSelector(
    selectDomain,
    (domain) => domain.currentCompetition
);

export const selectCurrentCompetitionId = createSelector(
    selectCurrentCompetition,
    (currentCompetition) =>
        (currentCompetition && currentCompetition.id) || null
);
