import {css} from 'styled-components';

import {query as queries} from './variables';

export const makeStyledMedia = (query) => (...args) => css`
    @media ${query} {
        ${css(...args)};
    }
`;

export const xxsMax = makeStyledMedia(queries.xxsMax);
export const xsMax = makeStyledMedia(queries.xsMax);
export const xs = makeStyledMedia(queries.xs);
export const smMax = makeStyledMedia(queries.smMax);
export const sm = makeStyledMedia(queries.sm);
export const mdMax = makeStyledMedia(queries.mdMax);
export const md = makeStyledMedia(queries.md);
export const lgMax = makeStyledMedia(queries.lgMax);
export const lg = makeStyledMedia(queries.lg);
export const xlMax = makeStyledMedia(queries.xlMax);
export const xl = makeStyledMedia(queries.xl);
export const xxlMax = makeStyledMedia(queries.xxlMax);
export const xxl = makeStyledMedia(queries.xxl);
export const xxxlMax = makeStyledMedia(queries.xxxlMax);
export const xxxl = makeStyledMedia(queries.xxxl);

export const matchMedia = () =>
    Object.entries(queries).reduce((matches, [name, query]) => {
        if (window.matchMedia && window.matchMedia(query).matches) {
            return {
                ...matches,
                [name]: query,
            };
        }
        return matches;
    }, {});
