import {combineReducers} from 'redux';
import {
    LOAD_DETAILS_SUCCESS,
    LOAD_POINTS_HISTORY_SUCCESS,
    CLEAR,
} from './actions';

const initialState = {
    details: null,
    pointsHistory: [],
    pagination: {
        currentPage: 0,
        totalPages: 0,
    },
};

const details = (state = initialState.details, {type, payload}) => {
    switch (type) {
        case LOAD_DETAILS_SUCCESS:
            return payload.details;
        case CLEAR:
            return initialState.details;
        default:
            return state;
    }
};

const pointsHistory = (state = initialState.pointsHistory, {type, payload}) => {
    switch (type) {
        case LOAD_POINTS_HISTORY_SUCCESS:
            return payload.pointsHistory;
        case CLEAR:
            return initialState.pointsHistory;
        default:
            return state;
    }
};

const pagination = (state = initialState.pagination, {type, payload}) => {
    switch (type) {
        case LOAD_POINTS_HISTORY_SUCCESS:
            return payload.pagination;
        case CLEAR:
            return initialState.pagination;
        default:
            return state;
    }
};

export const internals = {
    detailsReducer: details,
    pointsHistoryReducer: pointsHistory,
    paginationReducer: pagination,
    initialState,
};

export default combineReducers({
    details,
    pointsHistory,
    pagination,
});
