export const SUBMIT_ASSIGN_POINTS = 'points-assignment/SUBMIT_ASSIGN_POINTS';
export const SUBMIT_ASSIGN_POINTS_SUCCESS =
    'points-assignment/SUBMIT_ASSIGN_POINTS_SUCCESS';
export const SUBMIT_ASSIGN_POINTS_FAILURE =
    'points-assignment/SUBMIT_ASSIGN_POINTS_FAILURE';
export const LOAD_REASONS = 'points-assignment/LOAD_REASONS';
export const LOAD_REASONS_SUCCESS = 'points-assignment/LOAD_REASONS_SUCCESS';
export const LOAD_REASONS_FAILURE = 'points-assignment/LOAD_REASONS_FAILURE';

export const submitAssignPoints = (reasonId, userIds) => ({
    type: SUBMIT_ASSIGN_POINTS,
    payload: {reasonId, userIds},
});

export const submitAssignPointsSuccess = () => ({
    type: SUBMIT_ASSIGN_POINTS_SUCCESS,
});

export const submitAssignPointsFailure = (error) => ({
    type: SUBMIT_ASSIGN_POINTS_FAILURE,
    payload: {error},
});

export const loadReasons = () => ({
    type: LOAD_REASONS,
});

export const loadReasonsSuccess = (reasons) => ({
    type: LOAD_REASONS_SUCCESS,
    payload: {reasons},
});

export const loadReasonsFailure = (error) => ({
    type: LOAD_REASONS_FAILURE,
    payload: {error},
});
