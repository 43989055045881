import {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {useDispatch} from 'react-redux';

import {setStaticPages} from './actions';

export default function SourceStaticPages({children}) {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const dispatch = useDispatch();
    const data = useStaticQuery(
        graphql`
            {
                allStaticPage {
                    edges {
                        node {
                            id
                            slug
                            title
                            updatedAt
                            fields {
                                route
                            }
                            content {
                                type
                                text
                                anchor_id
                            }
                        }
                    }
                }
            }
        `
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    if (isFirstRender) {
        dispatch(
            setStaticPages(
                data.allStaticPage.edges.map((edge) => ({
                    id: edge.node.id,
                    slug: edge.node.slug,
                    title: edge.node.title,
                    updatedAt: edge.node.updatedAt,
                    route: edge.node.fields.route,
                    content: edge.node.content,
                }))
            )
        );
    }
    return children;
}
