export const OPEN = 'interim-lightbox/OPEN';
export const CLOSE = 'interim-lightbox/CLOSE';

export const open = () => ({
    type: OPEN,
});

export const close = () => ({
    type: CLOSE,
});
