// NOTE: this must be a commonjs module because we need to import it in gatsby-node.js

const {match: matchPath} = require('@reach/router/lib/utils');
const roles = require('../profile/roles');

const routes = {
    start: '/', // "Startseite vor Login"
    registrieren: '/registrieren', // "Jetzt Registrieren"
    anmelden: '/anmelden', // separate login page
    aktivieren: '/konto-aktivieren', // page for account activation, accessed directly from activation email
    passwort_vergessen: '/passwort-vergessen',
    passwort_zuruecksetzen: '/passwort-zuruecksetzen',
    datenschutz: '/datenschutz',
    impressum: '/impressum',
    nutzungsbedingungen: '/nutzungsbedingungen',
    pflichttexte: '/pflichttexte',
    trainings: '/trainings', // "Deine Trainingskollektion", "Startseite nach Login"
    training: '/trainings/:training', // single training
    praemien: '/praemien', // "Deine Prämien" - rewards
    praemie: '/praemien/:reward', // single reward
    team: '/team', // "Deine Apotique" => "Dein Team"
    punktehistorie: '/punktehistorie', // "Deine Apotique" => "Deine Punktehistorie"
    aktionen: '/aktionen', // "Deine Apotique" => "Aktionen" Übersicht der aktuellen Aktion für User
    apothekentour: '/apothekentour', // Sonderaktion, nicht über das Menü auffindbar
    news: '/news', // news page
    news_article: '/news/:article', // news article page
    anspruch: '/anspruch', // "Über Apotique" => "Unser Anspruch"
    support: '/support', // "Hilfe und Kontakt", "Support"
    profil: '/profil', // "Deine Daten"
    punktevergabe: '/punktevergabe', // "Punktevergabe durch Außendienst"
    punktearchiv: '/punktearchiv', // "Punktearchiv des Außendiensts"
    apothekentabelle: '/deine-apotheken', // "Übersicht Apotheken und Apothekennutzer für Außendienst"
    aktionsteilnahmen: '/aktionsteilnahmen', // "Übersicht Aktionsteilnehmer für Außendienst"
    aktionenarchiv: '/aktionenarchiv', // "Übersicht Aktionsteilnehmer für Außendienst"
    debug: '/debug', // debugging page
    academy: '/akademie', // debugging page
    nlDeactivieren: '/nl-deaktivieren', // "Newsletter deaktivieren"
    userAbgelehnt: '/user-abgelehnt', // "Newsletter deaktivieren"
    userBestaetigt: '/user-bestaetigt', // "Newsletter deaktivieren"
};

// Routes that are publicly accessible without a login:
const routesWithoutLoginProtection = [
    routes.start,
    routes.registrieren,
    routes.anmelden,
    routes.praemien,
    routes.anspruch,
    routes.support,
    routes.debug,
    routes.aktivieren,
    routes.passwort_zuruecksetzen,
    routes.passwort_vergessen,
    routes.datenschutz,
    routes.impressum,
    routes.nutzungsbedingungen,
    routes.nutzungsbedingungen + '-old',
    routes.pflichttexte,
    routes.nlDeactivieren,
];

// Routes that are protected with a login:
const routesWithLoginProtection = [
    routes.trainings,
    routes.training,
    routes.praemie,
    routes.team,
    routes.profil,
    routes.punktehistorie,
    routes.punktevergabe,
    routes.punktearchiv,
    routes.apothekentabelle,
    routes.aktionsteilnahmen,
    routes.aktionenarchiv,
    routes.aktionen,
    routes.apothekentour,
    routes.news,
    routes.news_article,
    routes.academy,
    routes.userAbgelehnt,
    routes.userBestaetigt,
];

// Routes that are accessible for each role, otherwise results in 404 page.
// Each route from routes above should be listed either in PUBLIC or in one or more of the specific roles:
const routesAccessibleForRole = {
    // accessible for all roles, and users that are not logged in:
    [roles.PUBLIC]: [
        routes.start,
        routes.registrieren,
        routes.anmelden,
        routes.praemien,
        routes.anspruch,
        routes.support,
        routes.debug,
        routes.aktivieren,
        routes.passwort_zuruecksetzen,
        routes.passwort_vergessen,
        routes.datenschutz,
        routes.impressum,
        routes.nutzungsbedingungen,
        routes.nutzungsbedingungen + '-old',
        routes.pflichttexte,
        routes.trainings,
        routes.training,
        routes.praemie,
        routes.profil,
        routes.apothekentour,
    ],
    // accessible for regular logged in users:
    [roles.USER]: [routes.team, routes.aktionen, routes.punktehistorie],
    // accessible for users who have no pharmacy associated:
    [roles.INTERIM]: [routes.team],
    // accessible for logged in special test users ("Präsentationsuser"):
    [roles.TEST_USER]: [
        routes.team,
        routes.aktionen,
        routes.apothekentour,
        routes.punktehistorie,
    ],
    // accessible for logged in sales representatives ("Außendienstmitarbeiter"):
    [roles.SALES_REPRESENTATIVE]: [
        routes.punktevergabe,
        routes.punktearchiv,
        routes.apothekentabelle,
        routes.aktionsteilnahmen,
        routes.aktionenarchiv,
    ],
};

const isPathnameLoginProtected = (
    pathname,
    unprotectedRoutes = routesWithoutLoginProtection,
    protectedRoutes = routesWithLoginProtection
) => {
    const isUnprotected = unprotectedRoutes.some((route) =>
        matchPath(route, pathname)
    );
    const isProtected = protectedRoutes.some((route) =>
        matchPath(route, pathname)
    );
    return isProtected || !isUnprotected;
};

const getRolesRequiredToAccessPathname = (
    pathname,
    routesForRole = routesAccessibleForRole
) => {
    const isPublic = routesForRole.PUBLIC.some((route) =>
        matchPath(route, pathname)
    );
    if (isPublic) {
        return []; // empty array = PUBLIC => "no roles required to access"
    }
    // Return a list of matching roles:
    return Object.entries(routesForRole)
        .filter(([role]) => role !== roles.PUBLIC)
        .reduce(
            (rolesAllowed, [role, routes]) =>
                routes.some((route) => matchPath(route, pathname))
                    ? [...rolesAllowed, role]
                    : rolesAllowed,
            []
        );
};

module.exports = {
    routes,
    routesWithoutLoginProtection,
    routesWithLoginProtection,
    routesAccessibleForRole,
    isPathnameLoginProtected,
    getRolesRequiredToAccessPathname,
};
