import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {match as matchPath} from '@reach/router/lib/utils';

import {
    StyledLink,
    StyledInternalLink,
    Text,
    StyledIcon,
    StyledInternalButton,
    StyledButton,
    themeColors,
} from './styles';
import {icons} from '../Icon';
import {
    isNavigationToPathnameAllowedWithoutLogin,
    getRedirectedLocation,
} from '../../../model/navigation';
import {selectIsLoggedIn} from '../../../model/authentication';
import {selectRoles, Roles} from '../../../model/profile';
import {open as openLoginLightbox} from '../../../model/login-lightbox';
import {open as openInterimLightbox} from '../../../model/interim-lightbox';
import {routes} from '../../../model/navigation';

export const Link = ({
    children,
    className,
    href,
    icon,
    iconLeft,
    active,
    disabled,
    isInternal,
    onClick,
    showAsButton,
    theme,
}) => {
    const isAnchor = !!href.startsWith('#');
    const color = themeColors[theme];
    const Content = () =>
        icon ? (
            <>
                <Text className="ap-text">{children}</Text>
                <StyledIcon
                    className="ap-icon"
                    name={icon}
                    iconLeft={iconLeft}
                    color={color}
                    disabled={disabled}
                />
            </>
        ) : (
            <Text className="ap-text">{children}</Text>
        );

    if (isInternal) {
        // NOTE: we avoid passing icon and iconLeft props to gatsby
        // Link which would otherwise result in a runtime warning:
        // "React does not recognize the `iconLeft` prop on a DOM element"
        return showAsButton ? (
            <StyledInternalButton
                className={`${className} ${active ? 'active' : ''} ap-link`}
                to={href}
                onClick={onClick}
                data-icon={icon}
                data-icon-left={iconLeft}
                disabled={disabled}>
                <Content />
            </StyledInternalButton>
        ) : (
            <StyledInternalLink
                className={`${className} ${active ? 'active' : ''} ap-link`}
                to={href}
                onClick={onClick}
                color={color}
                data-icon={icon}
                data-icon-left={iconLeft}
                disabled={disabled}>
                <Content />
            </StyledInternalLink>
        );
    }

    return showAsButton ? (
        <StyledButton
            className={`${className} ap-link`}
            href={href}
            onClick={onClick}
            icon={icon}
            iconLeft={iconLeft}
            disabled={disabled}
            target={isAnchor ? undefined : '_blank'}>
            <Content />
        </StyledButton>
    ) : (
        <StyledLink
            className={`${className} ap-link`}
            href={href}
            onClick={onClick}
            icon={icon}
            color={color}
            iconLeft={iconLeft}
            disabled={disabled}
            target={isAnchor ? undefined : '_blank'}>
            <Content />
        </StyledLink>
    );
};

Link.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(Object.keys(icons)),
    iconLeft: PropTypes.bool,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    isInternal: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(themeColors)),
};

Link.defaultProps = {
    className: '',
    iconLeft: false,
    disabled: false,
    isInternal: false,
    active: false,
    theme: 'red',
    isScrollAnchor: false,
};

export default function DefaultLink(props) {
    // NOTE: Default to unconnected component inside storybook environment
    // so that components that use the default export of this Link
    // like Button do not break
    // TODO: find a better solution
    if (process.env.STORYBOOK_ENV === 'true') {
        return <Link {...props} />;
    }
    const {href} = props;
    const isAnchor = href.startsWith('#');
    const isInternal = href.startsWith('/');
    const isLoggedIn = useSelector(selectIsLoggedIn); // eslint-disable-line
    const roles = useSelector(selectRoles); // eslint-disable-line
    const isInterimUser = roles.includes(Roles.INTERIM);
    const dispatch = useDispatch(); // eslint-disable-line
    const needsLogin =
        !isAnchor &&
        isInternal &&
        !isNavigationToPathnameAllowedWithoutLogin(href, isLoggedIn);
    const wouldNeedLoginIfLoggedOut = !isNavigationToPathnameAllowedWithoutLogin(
        href,
        false
    );
    const isProfileRoute = matchPath(routes.profil, href);
    const isTrainingsRoute = matchPath(routes.trainings, href);
    const redirectedLocation =
        !isAnchor && isInternal
            ? getRedirectedLocation(href, isLoggedIn, roles)
            : href;
    return (
        <Link
            {...props}
            isInternal={isInternal}
            href={redirectedLocation}
            {...(needsLogin ||
            (isInterimUser &&
                wouldNeedLoginIfLoggedOut &&
                !isProfileRoute &&
                !isTrainingsRoute &&
                !isAnchor)
                ? {
                      onClick: (event) => {
                          // prevent default href/to navigation and show login lightbox instead
                          event.preventDefault();
                          dispatch(
                              needsLogin
                                  ? openLoginLightbox(href)
                                  : openInterimLightbox()
                          );
                      },
                  }
                : {
                      /* no onClick at all, use href/to navigation per default */
                  })}
        />
    );
}

DefaultLink.propTypes = Link.propTypes;
DefaultLink.defaultProps = Link.defaultProps;
