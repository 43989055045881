export const LOAD_USER_PROFILE = 'profile/LOAD_USER_PROFILE';
export const LOAD_USER_PROFILE_SUCCESS = 'profile/LOAD_USER_PROFILE_SUCCESS';
export const LOAD_USER_PROFILE_FAILURE = 'profile/LOAD_USER_PROFILE_FAILURE';
export const SET_USER_PROFILE = 'profile/SET_USER_PROFILE';
export const CLEAR_USER_PROFILE = 'profile/CLEAR_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'profile/UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS =
    'profile/UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE =
    'profile/UPDATE_USER_PROFILE_FAILURE';
export const ADD_USER_POINTS = 'profile/ADD_USER_POINTS';
export const SET_TEMP_USER_SETTINGS = 'profile/SET_TEMP_USER_SETTINGS';
export const CLEAR_TEMP = 'profile/CLEAR_TEMP';
export const UPDATE_USER_SETTINGS = 'profile/UPDATE_USER_SETTINGS';
export const UPDATE_USER_SETTINGS_SUCCESS =
    'profile/UPDATE_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_FAILURE =
    'profile/UPDATE_USER_SETTINGS_FAILURE';

export const loadUserProfile = () => ({
    type: LOAD_USER_PROFILE,
});

export const loadUserProfileSuccess = (userData) => ({
    type: LOAD_USER_PROFILE_SUCCESS,
    payload: {userData},
});

export const loadUserProfileFailure = (error) => ({
    type: LOAD_USER_PROFILE_FAILURE,
    payload: {error},
});

export const setUserProfile = (normalizedUserProfile) => ({
    type: SET_USER_PROFILE,
    payload: {userProfile: normalizedUserProfile},
});

export const clearUserProfile = () => ({
    type: CLEAR_USER_PROFILE,
});

export const updateUserProfile = (updateData) => ({
    type: UPDATE_USER_PROFILE,
    payload: {updateData},
});

export const updateUserProfileSuccess = (userData) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: {userData},
});
export const updateUserProfileFailure = (error) => ({
    type: UPDATE_USER_PROFILE_FAILURE,
    payload: {error},
});
export const addPoints = (points) => ({
    type: ADD_USER_POINTS,
    payload: {points},
});

export const setTempUserSettings = (categories, userType) => ({
    type: SET_TEMP_USER_SETTINGS,
    payload: {categories, userType},
});

export const updateUserSettings = (categories, userType, silent = false) => ({
    type: UPDATE_USER_SETTINGS,
    payload: {categories, userType, silent},
});
export const updateUserSettingsSuccess = (userData) => ({
    type: UPDATE_USER_SETTINGS_SUCCESS,
    payload: {userData},
});
export const updateUserSettingsFailure = (error) => ({
    type: UPDATE_USER_SETTINGS_FAILURE,
    payload: {error},
});

export const clearTemp = () => ({
    type: CLEAR_TEMP,
});
