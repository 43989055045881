import {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {useDispatch} from 'react-redux';

import {setStaticRewards} from './actions';

export default function SourceStaticRewards({children}) {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const dispatch = useDispatch();
    const data = useStaticQuery(
        graphql`
            {
                allReward {
                    edges {
                        node {
                            id
                            slug
                            type
                            teaser_headline
                            teaser_description
                            details_headline
                            details_description
                            image
                            points
                            fields {
                                route
                            }
                        }
                    }
                }
            }
        `
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    if (isFirstRender) {
        dispatch(
            setStaticRewards(
                data.allReward.edges.map((edge) => ({
                    id: +edge.node.id, // make it a number again
                    slug: edge.node.slug,
                    route: edge.node.fields.route,
                    type: edge.node.type,
                    teaserHeadline: edge.node.teaser_headline,
                    teaserDescription: edge.node.teaser_description,
                    detailsHeadline: edge.node.details_headline,
                    detailsDescription: edge.node.details_description,
                    image: edge.node.image,
                    points: edge.node.points,
                }))
            )
        );
    }
    return children;
}
