// import {startScrolling, stopScrolling} from '../../utils/scroll-utils';
// NOTE: for whatever weird reason, importing these will make tests fail with
//       "SyntaxError: Cannot use import statement outside a module",
//       so as a dirty workaround we use copies of their implementation in here.

const stopInteraction = () => {
    // ignore clicks:
    const $gatsbyContainer = document.getElementById('___gatsby');
    $gatsbyContainer.style.pointerEvents = 'none';
    // stop scrolling:
    const html = document.documentElement;
    html.style.overflow = 'hidden';
};

const startInteraction = () => {
    // accept clicks:
    const $gatsbyContainer = document.getElementById('___gatsby');
    $gatsbyContainer.style.pointerEvents = 'all';
    // start scrolling:
    const html = document.documentElement;
    html.style.overflow = 'auto';
};

export default function cookieConsentSaga() {
    // Step 1: stop interaction
    stopInteraction();
    // Step 2: listen for consent to be ready for starting interaction
    window.addEventListener(
        'CookiebotOnConsentReady',
        () => {
            if (window.Cookiebot.consent.necessary) {
                startInteraction();
            }
        },
        false
    );
    // Step 3: load the actual consent script that will fire the ready event
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'Cookiebot';
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.setAttribute('data-cbid', 'ec6e814c-760e-4638-9c03-6adf75243bee');
    script.setAttribute('data-blockingmode', 'auto');
    document.head.appendChild(script);
}
