import {createSelector} from 'reselect';

export const MAX_POINTS = 1620;

export const selectDomain = (state) => state.profile;

export const selectRoles = createSelector(
    selectDomain,
    (profile) => profile.roles
);

export const selectUser = createSelector(selectDomain, (domain) => domain.user);

export const selectPersonalData = createSelector(selectUser, (user) =>
    user
        ? {
              salutation: user.salutation,
              firstName: user.firstName,
              lastName: user.lastName,
              newsletter: user.newsletter,
              referralCode: user.referralCode,
              referralCodeUsages: user.referralCodeUsages,
          }
        : {
              salutation: '',
              firstName: '',
              lastName: '',
              referralCode: '',
              referralCodeUsages: '',
              newsletter: false,
          }
);

export const selectAccessData = createSelector(selectUser, (user) =>
    user
        ? {
              username: user.username,
              email: user.email,
          }
        : {username: '', email: ''}
);

export const selectUserCategories = createSelector(
    selectUser,
    (user) => (user && user.categories) || []
);

export const selectTempData = createSelector(
    selectDomain,
    (domain) => domain.temp
);

export const selectUserType = createSelector(
    selectUser,
    (user) => (user && user.type) || ''
);

export const selectUserPoints = createSelector(
    selectUser,
    (user) => (user && user.points) || 0
);

export const selectIsPersonalizationFinished = createSelector(
    selectTempData,
    selectUserCategories,
    selectUserType,
    (
        {categories: tempUserCategories, userType: tempUserType},
        userCategories,
        userType
    ) =>
        (tempUserCategories != null || userCategories.length > 0) &&
        (tempUserType != null || userType !== '')
);

export const selectHasUserReachedPointLimit = createSelector(
    selectUserPoints,
    (points) => points >= MAX_POINTS
);
