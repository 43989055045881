export const LOAD_POINTS_ASSIGNMENTS = 'points-archive/LOAD_POINTS_ASSIGNMENTS';
export const LOAD_POINTS_ASSIGNMENTS_SUCCESS =
    'points-archive/LOADS_POINT_ASSIGNMENTS_SUCCESS';
export const LOAD_POINTS_ASSIGNMENTS_FAILURE =
    'points-archive/LOAD_POINTS_ASSIGNMENTS_FAILURE';
export const SET_ASSIGNMENTS = 'points-archive/SET_ASSIGNMENTS';
export const CLEAR_POINTS_ASSIGNMENTS =
    'points-archive/CLEAR_POINTS_ASSIGNMENTS';
export const SET_PAGINATION_DATA = 'points-archive/SET_PAGINATION_DATA';

export const loadPointsAssignments = (page = 1) => ({
    type: LOAD_POINTS_ASSIGNMENTS,
    payload: {page},
});

export const loadPointsAssignmentsSuccess = (pointsAssignments) => ({
    type: LOAD_POINTS_ASSIGNMENTS_SUCCESS,
    payload: {pointsAssignments},
});
export const loadPointsAssignmentsFailure = (error) => ({
    type: LOAD_POINTS_ASSIGNMENTS_FAILURE,
    payload: {error},
});

export const setAssignments = (normalizedAssignments) => ({
    type: SET_ASSIGNMENTS,
    payload: {assignments: normalizedAssignments},
});

export const setPaginationData = (current, total) => ({
    type: SET_PAGINATION_DATA,
    payload: {current, total},
});

export const clearPointsAssignments = () => ({
    type: CLEAR_POINTS_ASSIGNMENTS,
});
