import {
    routes,
    routesWithoutLoginProtection,
    routesWithLoginProtection,
    routesAccessibleForRole,
} from './routes';
import {
    selectLocation,
    isNavigationToPathnameAllowedWithoutLogin,
    isNavigationToPathnameAllowedForRoles,
    getRedirectedLocation,
} from './selectors';

export {
    routes,
    routesWithoutLoginProtection,
    routesWithLoginProtection,
    routesAccessibleForRole,
    selectLocation,
    isNavigationToPathnameAllowedWithoutLogin,
    isNavigationToPathnameAllowedForRoles,
    getRedirectedLocation,
};
