import {combineReducers} from 'redux';
import {
    SET_SUBMISSIONS,
    SET_PAGINATION_DATA,
    CLEAR_SUBMISSIONS,
} from './actions';

const initialState = {
    submissions: [],
    pagination: {
        currentPage: 0,
        totalPages: 0,
    },
};

const submissions = (state = initialState.submissions, {type, payload}) => {
    switch (type) {
        case SET_SUBMISSIONS:
            return payload.submissions;
        case CLEAR_SUBMISSIONS:
            return initialState.submissions;
        default:
            return state;
    }
};

const pagination = (state = initialState.pagination, {type, payload}) => {
    switch (type) {
        case SET_PAGINATION_DATA:
            return {
                currentPage: payload.current,
                totalPages: payload.total,
            };
        case CLEAR_SUBMISSIONS:
            return initialState.pagination;
        default:
            return state;
    }
};

export const internals = {
    submissionsReducer: submissions,
    paginationReducer: pagination,
    initialState,
};

export default combineReducers({
    submissions,
    pagination,
});
