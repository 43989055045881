import React from 'react';
import PropTypes from 'prop-types';
import {NotificationContainer, Headline, Text} from './Notification.styles';

const Notification = ({headline, children}) => {
    return (
        <NotificationContainer>
            {headline && <Headline>{headline}</Headline>}
            {children && <Text>{children}</Text>}
        </NotificationContainer>
    );
};

Notification.propTypes = {
    headline: PropTypes.any,
    children: PropTypes.any,
};

export default Notification;
