import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {selectLocation} from '../../../model/navigation';

const LocationContext = React.createContext({});

export const LocationProvider = ({mockedLocation, children}) => {
    const location = useSelector(
        mockedLocation ? () => mockedLocation : selectLocation
    );
    return (
        <LocationContext.Provider value={location}>
            {children}
        </LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    mockedLocation: PropTypes.shape({
        pathname: PropTypes.string,
    }),
    children: PropTypes.any,
};

export const LocationConsumer = LocationContext.Consumer;

export default LocationContext;
