import React from 'react';
import {call, takeLatest, put, select} from 'redux-saga/effects';

import {
    LOAD_PENDING_RECEIPTS,
    CONFIRM_PENDING_TRANSACTION,
    REJECT_PENDING_TRANSACTION,
    DISMISS_PENDING_NOTIFICATION,
    loadPendingReceiptsSuccess,
    loadPendingReceiptsFailure,
    setPendingTransactions,
    confirmPendingTransactionSuccess,
    confirmPendingTransactionFailure,
    rejectPendingTransactionSuccess,
    rejectPendingTransactionFailure,
    setPendingNotifications,
    dismissPendingNotificationSuccess,
    dismissPendingNotificationFailure,
    clearPendingReceipts,
} from './actions';

import {SET_IS_LOGGED_IN, LOGIN_SUCCESS} from '../authentication/actions';
import {
    normalizePendingTransactionEntry,
    normalizePendingNotificationEntry,
} from './normalize';
import {fetch} from '../backend-api';
import {loadUserProfile} from '../profile';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {renderBackendApiError} from '../../utils/error-utils';
import {selectPendingTransactions} from './selectors';

function* getPendingTransactionsSaga(page = 1) {
    return yield call(
        fetch,
        LOAD_PENDING_RECEIPTS,
        'GET',
        `/me/transactions?filter[status]=pending&page=${page}`
    );
}

function* getPendingNotificationsSaga() {
    return yield call(fetch, LOAD_PENDING_RECEIPTS, 'GET', `/me/notifications`);
}

function* postConfirmPendingTransactionSaga(
    transactionId,
    ratingCourse,
    ratingInstructor,
    ratingContent
) {
    return yield call(
        fetch,
        CONFIRM_PENDING_TRANSACTION,
        'POST',
        `/me/transactions/${transactionId}/confirm`,
        {
            answer_1: ratingCourse,
            answer_2: ratingInstructor,
            answer_3: ratingContent,
        }
    );
}

function* postRejectPendingTransactionSaga(transactionId) {
    return yield call(
        fetch,
        REJECT_PENDING_TRANSACTION,
        'POST',
        `/me/transactions/${transactionId}/reject`
    );
}

function* patchDismissPendingNotificationSaga(notificationId) {
    return yield call(
        fetch,
        DISMISS_PENDING_NOTIFICATION,
        'PATCH',
        `/me/notifications/${notificationId}`
    );
}

function* loadPendingReceiptsSaga() {
    try {
        const {data: pendingTransactions} = yield call(
            getPendingTransactionsSaga
        );
        const {data: pendingNotifications} = yield call(
            getPendingNotificationsSaga
        );
        yield put(
            loadPendingReceiptsSuccess(
                pendingTransactions,
                pendingNotifications
            )
        );
        yield put(
            setPendingTransactions(
                pendingTransactions.map((entry) =>
                    normalizePendingTransactionEntry(entry)
                )
            )
        );
        yield put(
            setPendingNotifications(
                pendingNotifications.map((entry) =>
                    normalizePendingNotificationEntry(entry)
                )
            )
        );
    } catch (error) {
        yield put(loadPendingReceiptsFailure(error));
    }
}

function* confirmPendingTransactionSaga({payload}) {
    const {
        transactionId,
        ratingCourse,
        ratingInstructor,
        ratingContent,
    } = payload;
    const transactions = yield select(selectPendingTransactions);
    const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
    );
    try {
        yield call(
            postConfirmPendingTransactionSaga,
            transactionId,
            ratingCourse,
            ratingInstructor,
            ratingContent
        );
        yield put(confirmPendingTransactionSuccess(transactionId));
        yield put(loadUserProfile()); // update points
        if (transaction.type === 'manual') {
            toast.info(
                <Notification
                    headline={lang.pointsReceipt.manual.confirm.success.hl}>
                    {lang.pointsReceipt.manual.confirm.success.msg(
                        transaction.reason,
                        transaction.change
                    )}
                </Notification>
            );
        }
    } catch (error) {
        yield put(confirmPendingTransactionFailure(transactionId, error));
        if (transaction.type === 'manual') {
            toast.error(
                <Notification
                    headline={lang.pointsReceipt.manual.confirm.failure.hl}>
                    {renderBackendApiError(error)}
                </Notification>
            );
        }
    }
}

function* rejectPendingTransactionSaga({payload}) {
    const {transactionId} = payload;
    const transactions = yield select(selectPendingTransactions);
    const transaction = transactions.find(
        (transaction) => transaction.id === transactionId
    );
    try {
        yield call(postRejectPendingTransactionSaga, transactionId);
        yield put(rejectPendingTransactionSuccess(transactionId));
        if (transaction.type === 'manual') {
            toast.info(
                <Notification
                    headline={lang.pointsReceipt.manual.reject.success.hl}>
                    {lang.pointsReceipt.manual.reject.success.msg(
                        transaction.reason
                    )}
                </Notification>
            );
        }
    } catch (error) {
        yield put(rejectPendingTransactionFailure(transactionId, error));
        if (transaction.type === 'manual') {
            toast.error(
                <Notification
                    headline={lang.pointsReceipt.manual.reject.failure.hl}>
                    {renderBackendApiError(error)}
                </Notification>
            );
        }
    }
}

function* dismissPendingNotificationSaga({payload}) {
    const {notificationId} = payload;
    try {
        yield call(patchDismissPendingNotificationSaga, notificationId);
        yield put(dismissPendingNotificationSuccess(notificationId));
    } catch (error) {
        yield put(dismissPendingNotificationFailure(notificationId, error));
        toast.error(
            <Notification>{renderBackendApiError(error)}</Notification>
        );
    }
}

function* clearPendingReceiptsSaga() {
    yield put(clearPendingReceipts());
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

export const internals = {
    getPendingTransactionsSaga,
    getPendingNotificationsSaga,
    postConfirmPendingTransactionSaga,
    postRejectPendingTransactionSaga,
    patchDismissPendingNotificationSaga,
    loadPendingReceiptsSaga,
    confirmPendingTransactionSaga,
    rejectPendingTransactionSaga,
    dismissPendingNotificationSaga,
    clearPendingReceiptsSaga,
    isLoggedOut,
};

export default function* pointsReceiptSaga() {
    // We want to load pending receipts everytime
    // - the user is already logged in and reloads the page (see pages/saga)
    // - the user just got logged in
    yield takeLatest(
        [LOAD_PENDING_RECEIPTS, LOGIN_SUCCESS],
        loadPendingReceiptsSaga
    );
    yield takeLatest(isLoggedOut, clearPendingReceiptsSaga);
    yield takeLatest(
        CONFIRM_PENDING_TRANSACTION,
        confirmPendingTransactionSaga
    );
    yield takeLatest(REJECT_PENDING_TRANSACTION, rejectPendingTransactionSaga);
    yield takeLatest(
        DISMISS_PENDING_NOTIFICATION,
        dismissPendingNotificationSaga
    );
}
