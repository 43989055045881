import {combineReducers} from 'redux';
import {
    SET_PENDING_TRANSACTIONS,
    SET_PENDING_NOTIFICATIONS,
    CONFIRM_PENDING_TRANSACTION,
    CONFIRM_PENDING_TRANSACTION_SUCCESS,
    REJECT_PENDING_TRANSACTION,
    REJECT_PENDING_TRANSACTION_SUCCESS,
    DISMISS_PENDING_NOTIFICATION,
    DISMISS_PENDING_NOTIFICATION_SUCCESS,
    CLEAR_PENDING_RECEIPTS,
} from './actions';

const initialState = {
    pendingTransactions: [],
    pendingNotifications: [],
};

const pendingTransactions = (
    state = initialState.pendingTransactions,
    {type, payload}
) => {
    switch (type) {
        case SET_PENDING_TRANSACTIONS:
            return payload.pendingTransactions;
        case CONFIRM_PENDING_TRANSACTION:
        case REJECT_PENDING_TRANSACTION:
            return state.map((transaction) => ({
                ...transaction,
                done: transaction.id === payload.transactionId,
            }));
        case CONFIRM_PENDING_TRANSACTION_SUCCESS:
        case REJECT_PENDING_TRANSACTION_SUCCESS:
            // remove the transaction:
            const transactionIndex = state.findIndex(
                (transaction) => transaction.id === payload.transactionId
            );
            return [
                ...state.slice(0, transactionIndex),
                ...state.slice(transactionIndex + 1),
            ];
        case CLEAR_PENDING_RECEIPTS:
            return initialState.pendingTransactions;
        default:
            return state;
    }
};

const pendingNotifications = (
    state = initialState.pendingNotifications,
    {type, payload}
) => {
    switch (type) {
        case SET_PENDING_NOTIFICATIONS:
            return payload.pendingNotifications;
        case DISMISS_PENDING_NOTIFICATION:
            return state.map((notification) => ({
                ...notification,
                done: notification.id === payload.notificationId,
            }));
        case DISMISS_PENDING_NOTIFICATION_SUCCESS:
            // remove the notification:
            const notificationIndex = state.findIndex(
                (notification) => notification.id === payload.notificationId
            );
            return [
                ...state.slice(0, notificationIndex),
                ...state.slice(notificationIndex + 1),
            ];
        case CLEAR_PENDING_RECEIPTS:
            return initialState.pendingNotifications;
        default:
            return state;
    }
};

export const internals = {
    pendingTransactionsReducer: pendingTransactions,
    pendingNotificationsReducer: pendingNotifications,
    initialState,
};

export default combineReducers({
    pendingTransactions,
    pendingNotifications,
});
