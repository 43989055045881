import React from 'react';
import {call, takeLatest, put, select} from 'redux-saga/effects';
import {createModal} from 'redux-saga-modal';
import {fetch} from '../backend-api';
import {addPoints} from '../profile';
import {selectById} from './selectors';
import {
    SUBMIT_REWARD_ORDER,
    submitRewardOrderSuccess,
    submitRewardOrderFailure,
} from './actions';
import toast, {Notification} from '../../ui/components/Toast';
import {renderBackendApiError} from '../../utils/error-utils';
import lang from '../../lang/notification.lang';

function* postSubmitRewardOrder(id) {
    return yield call(fetch, SUBMIT_REWARD_ORDER, 'POST', '/me/orders', {
        rewards: [id],
    });
}

function* submitRewardOrderSaga({payload}) {
    try {
        const {id} = payload;
        const reward = (yield select(selectById))[id];
        const {points} = yield call(postSubmitRewardOrder, id);
        yield put(addPoints(points));
        yield put(submitRewardOrderSuccess());
        const modal = createModal('ORDER_SUCCESS_MODAL');
        yield modal.show({type: reward.type});
    } catch (error) {
        yield put(submitRewardOrderFailure(error));
        toast.error(
            <Notification headline={lang.reward.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

export const internals = {
    submitRewardOrderSaga,
    postSubmitRewardOrder,
};

export default function* rewardSaga() {
    yield takeLatest(SUBMIT_REWARD_ORDER, submitRewardOrderSaga);
}
