export const normalizeBannerContent = (data) => {
    if (!data) return null;

    return {
        id: data.id,
        closedHeadline: data.text_closed,
        openHeadline: data.text_opened,
        type: data.type,
        buttonOne: {
            label: data.first_button.text,
            href: data.first_button.link,
        },
        ...(data.second_button
            ? {
                  buttonTwo: {
                      label: data.second_button.text,
                      href: data.second_button.link,
                  },
              }
            : {}),
    };
};
