import styled, {css} from 'styled-components';
import {Link as GatsbyLink} from 'gatsby';

import {color, font, shadow, border} from '../../../styles/variables';
import Icon from '../Icon';

export const themeColors = {
    red: font.color.link,
    white: font.color.link2,
};

const iconLinkStyles = css`
    display: inline-flex;

    .ap-text {
        background-image: linear-gradient(
                ${(props) => props.color},
                ${(props) => props.color}
            ),
            linear-gradient(${color.fog}, ${color.fog});
        background-size: 0% 2px, 100% 2px;
    }

    &:hover,
    &:active,
    &:focus {
        .ap-text {
            background-image: linear-gradient(
                ${(props) => props.color},
                ${(props) => props.color}
            );
            background-size: 100% 2px;
        }
    }
`;

const disabledLinkStyles = css`
    cursor: not-allowed;
    opacity: 0.4;

    .ap-text {
        background-image: linear-gradient(${color.fog}, ${color.fog});
        background-size: 100% 2px;
    }

    &:hover,
    &:active,
    &:focus {
        .ap-text {
            background-image: linear-gradient(${color.fog}, ${color.fog});
            background-size: 100% 2px;
        }
    }

    &:focus {
        outline: none;
    }
`;

export const linkStyles = css`
    display: inline;
    align-items: center;
    color: ${(props) => props.color};
    text-decoration: none;
    cursor: pointer;

    .ap-text {
        background-image: linear-gradient(
            ${(props) => props.color},
            ${(props) => props.color}
        );
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100% 2px;
        transition: background-size 0.3s;
    }

    &:hover,
    &:active,
    &:focus {
        .ap-text {
            background-size: 0% 2px;
        }
    }

    &:focus {
        outline: 1px dotted ${(props) => props.color};
        outline-offset: 4px;
    }
`;

export const StyledLink = styled.a`
    ${linkStyles}
    ${(props) => (props.iconLeft ? 'flex-direction: row-reverse;' : '')}
    ${(props) => props.icon && iconLinkStyles}
    ${(props) => props.disabled && disabledLinkStyles}
`;

export const StyledInternalLink = styled(GatsbyLink)`
    ${linkStyles}
    &[data-icon-left='true'] {
        flex-direction: row-reverse;
    }
    &[data-icon] {
        ${iconLinkStyles}
    }
    ${(props) => props.disabled && disabledLinkStyles}
`;

export const Text = styled.span``;

export const StyledIcon = styled(Icon)`
    display: inline-block;
    width: 1em;
    height: 1em;

    ${(props) => (props.iconLeft ? 'margin-right: 1em;' : 'margin-left: 1em;')}
`;

// Button Styles
const iconLeftButtonStyles = css`
    flex-direction: row-reverse;
    .ap-icon {
        margin-right: 0.5rem;
        margin-left: 0;
    }
`;

const disabledButtonStyles = css`
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
        background-color: ${(props) =>
            props.secondary ? color.white : color.red};
        ${(props) => (props.secondary ? 'color: ' + color.red : '')};
    }

    &:active,
    &:focus {
        outline: none;
        border: ${(props) =>
            props.secondary ? border.red : border.transparent};
        box-shadow: none;
    }
`;

const ButtonStyles = css`
    height: 48px;
    padding: 0 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${color.white};
    background-color: ${color.red};
    cursor: pointer;
    border: ${border.transparent};
    font-size: 0.9rem;
    text-decoration: none;

    .ap-icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;

        svg {
            fill: ${color.white};
        }
    }

    &:hover {
        background-color: ${color.wine};
    }

    &:active,
    &:focus {
        outline: none;
        border: ${border.wine};
        box-shadow: ${shadow.wine};
    }

    ${(props) => props.iconLeft && iconLeftButtonStyles}
    ${(props) => props.disabled && disabledButtonStyles}
`;

export const StyledButton = styled.a`
    ${ButtonStyles};
`;

export const StyledInternalButton = styled(GatsbyLink)`
    ${ButtonStyles};
`;
