import {call, takeLatest, put} from 'redux-saga/effects';

import {
    LOAD_BANNER_CONTENT,
    loadBannerContentSuccess,
    loadBannerContentFailure,
} from './actions';
import {normalizeBannerContent} from './normalize';
import {fetch} from '../backend-api';

export function* getInteractiveBannerContentSaga() {
    const url = '/banners/current';
    return yield call(fetch, LOAD_BANNER_CONTENT, 'GET', url);
}

export function* loadInteractiveBannerContentSaga() {
    try {
        const bannerContent = yield call(getInteractiveBannerContentSaga);
        const content = bannerContent?.data.find((x) => x.type === 'simple');
        yield put(loadBannerContentSuccess(normalizeBannerContent(content)));
    } catch (error) {
        console.warn(error);
        yield put(loadBannerContentFailure(error));
    }
}

export default function* interactiveBannerSaga() {
    yield takeLatest(LOAD_BANNER_CONTENT, loadInteractiveBannerContentSaga);
}
