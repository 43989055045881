import React from 'react';
import {takeLatest, call, put, select} from '@redux-saga/core/effects';

import {fetch} from '../backend-api';
import {
    UPLOAD_SUBMISSION_IMAGE_FILE,
    LOAD_CURRENT_CAMPAIGN,
    loadCurrentCampaignSuccess,
    loadCurrentCampaignFailure,
    updloadSubmissionImageFileSuccess,
    updloadSubmissionImageFileFailure,
    resetCurrentCampaign,
} from './actions';
import {normalizeCampaignEntry} from './normalize';
import {selectCurrentCampaignId} from './selectors';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {renderBackendApiError} from '../../utils/error-utils';

function* postUploadSubmissionImageFile(campaignId, data) {
    return yield call(
        fetch,
        UPLOAD_SUBMISSION_IMAGE_FILE,
        'POST',
        `/me/campaigns/${campaignId}/submissions`,
        data,
        null,
        null,
        true
    );
}

function* getCurrentCampaign() {
    return yield call(
        fetch,
        LOAD_CURRENT_CAMPAIGN,
        'GET',
        '/campaigns/current'
    );
}

function* loadCurrentCampaignSaga() {
    try {
        const {data: currentCampaign} = yield call(getCurrentCampaign);
        yield put(
            loadCurrentCampaignSuccess(normalizeCampaignEntry(currentCampaign))
        );
    } catch (error) {
        yield put(loadCurrentCampaignFailure(error));
        yield put(resetCurrentCampaign());
    }
}

function* updloadSubmissionImageFileSaga({payload}) {
    try {
        const {imageFile} = payload;
        const currentCampaignId = yield select(selectCurrentCampaignId);
        if (!currentCampaignId) {
            throw new Error('ERR_CURRENT_CAMPAIGN_NOT_AVAILABLE');
        }
        const formData = new FormData();
        formData.append('image', imageFile);
        yield call(postUploadSubmissionImageFile, currentCampaignId, formData);
        yield put(updloadSubmissionImageFileSuccess());
        yield call(loadCurrentCampaignSaga);
        toast.info(
            <Notification headline={lang.updloadSubmissionImage.success.hl}>
                {lang.updloadSubmissionImage.success.msg}
            </Notification>
        );
    } catch (error) {
        yield put(updloadSubmissionImageFileFailure(error));
        toast.error(
            <Notification headline={lang.updloadSubmissionImage.failure.hl}>
                {error === 'ERR_CURRENT_CAMPAIGN_NOT_AVAILABLE'
                    ? lang.updloadSubmissionImage.failure.msg
                    : renderBackendApiError(error)}
            </Notification>
        );
    }
}

export const internals = {
    loadCurrentCampaignSaga,
    updloadSubmissionImageFileSaga,
    getCurrentCampaign,
};

export default function* userCampaignSubmissionsSaga() {
    yield takeLatest(LOAD_CURRENT_CAMPAIGN, loadCurrentCampaignSaga);
    yield takeLatest(
        UPLOAD_SUBMISSION_IMAGE_FILE,
        updloadSubmissionImageFileSaga
    );
}
