import {createSelector} from 'reselect';

import {
    routes,
    routesWithoutLoginProtection,
    routesWithLoginProtection,
    routesAccessibleForRole,
    isPathnameLoginProtected,
    getRolesRequiredToAccessPathname,
} from './routes';

export const selectDomain = (state) => state.navigation;

export const selectLocation = createSelector(
    selectDomain,
    (navigation) => navigation.location
);

export const isNavigationToPathnameAllowedWithoutLogin = (
    pathname,
    isLoggedIn,
    unprotectedRoutes = routesWithoutLoginProtection,
    protectedRoutes = routesWithLoginProtection
) => {
    const isProtected = isPathnameLoginProtected(
        pathname,
        unprotectedRoutes,
        protectedRoutes
    );
    return isLoggedIn === false && isProtected ? false : true;
};

export const isNavigationToPathnameAllowedForRoles = (
    pathname,
    roles,
    routesForRole = routesAccessibleForRole
) => {
    const requiredRoles = getRolesRequiredToAccessPathname(
        pathname,
        routesForRole
    );
    // Public routes are allowed for all roles:
    if (requiredRoles.length === 0) {
        return true;
    }
    // User must have at least one specific required role:
    return requiredRoles.some((requiredRole) =>
        roles.some((role) => role === requiredRole)
    );
};

export const getRedirectedLocation = (
    pathname,
    isLoggedIn,
    roles,
    unprotectedRoutes = routesWithoutLoginProtection,
    protectedRoutes = routesWithLoginProtection,
    routesForRole = routesAccessibleForRole
) => {
    if (
        !isNavigationToPathnameAllowedWithoutLogin(
            pathname,
            isLoggedIn,
            unprotectedRoutes,
            protectedRoutes
        )
    ) {
        // Redirect to login with encoded back navigation:
        return `${routes.anmelden}?redirect=${pathname}`;
    } else if (isLoggedIn === true && pathname === routes.start) {
        // Redirect to /trainings when visiting the start page and being logged in:
        return routes.trainings;
    } else if (
        !isNavigationToPathnameAllowedForRoles(pathname, roles, routesForRole)
    ) {
        // Redirect to 404 page:
        return '/404';
    }
    // else keep intact:
    return pathname;
};
