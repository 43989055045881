import React from 'react';
import {call, takeLatest, put, select} from 'redux-saga/effects';
import {navigate} from 'gatsby';

import {datalayerPush} from '../../utils/tracking-utils';

import {
    LOAD_COMPLETION,
    START,
    FINISH,
    CLOSE_SUCCESS_LIGHTBOX,
    loadCompletionSuccess,
    loadCompletionFailure,
    setScormReady,
    startSuccess,
    setProgress,
    setScore,
    setSuccessStatus,
    finishSuccess,
    finishFailure,
    openSuccessLightbox,
} from './actions';
import {LOGIN_SUCCESS} from '../authentication/actions';
import store from '..';
import {selectCurrent, selectById} from './selectors';
import {
    selectUser,
    addPoints,
    selectRoles as selectUserRoles,
    Roles,
} from '../profile';
import {finish} from './actions';
import {fetch} from '../backend-api';
import toast, {Notification} from '../../ui/components/Toast';
import lang from '../../lang/notification.lang';
import {renderBackendApiError} from '../../utils/error-utils';
import {routes} from '../navigation/routes';

const selectDirectly = (selector) => selector(store.getState());

// TODO: how can this be tested?
function initScormApi() {
    window.API_1484_11 = {
        Initialize: function () {
            return 'true';
        },
        Terminate: function () {
            return 'true';
        },
        GetValue: function (model) {
            const profile = selectDirectly(selectUser);
            const {completionStatus} = selectDirectly(selectCurrent);
            switch (model) {
                case 'cmi.learner_id':
                    return profile?.id;
                case 'cmi.learner_name':
                    return `${profile?.firstName} ${profile?.lastName}`;
                case 'cmi.location':
                    return null;
                case 'cmi.learner_preference.language':
                    return 'de';
                case 'cmi.completion_status':
                    return completionStatus;
                default:
                    return '';
            }
        },
        SetValue: function (model, value) {
            switch (model) {
                case 'cmi.progress_measure':
                    store.dispatch(setProgress(+value));
                    return 'true';
                case 'cmi.score.scaled':
                    store.dispatch(setScore(+value));
                    return 'true';
                case 'cmi.success_status':
                    store.dispatch(setSuccessStatus(value));
                    return 'true';
                case 'cmi.completion_status':
                    if (value === 'completed') {
                        store.dispatch(finish());
                    }
                    return 'true';
                default:
                    return 'true';
            }
        },
        Commit: function () {
            return 'true';
        },
        GetLastError: function () {
            return '0';
        },
        GetErrorString: function (errorCode) {
            return 'No error';
        },
        GetDiagnostic: function (errorCode) {
            return 'No error';
        },
    };
}

function* getTrainingsSaga(page) {
    return yield call(fetch, LOAD_COMPLETION, 'GET', `/trainings?page=${page}`);
}

function* putCompleteTraining(id) {
    return yield call(fetch, FINISH, 'PUT', `/me/trainings/${id}`);
}

function* loadCompletionSaga() {
    try {
        let currentPage = 0;
        let lastPage;
        let trainings = [];
        do {
            const {data, meta} = yield call(getTrainingsSaga, currentPage + 1);
            trainings = trainings.concat(data);
            currentPage = meta.current_page;
            lastPage = meta.last_page;
        } while (currentPage !== lastPage);
        yield put(loadCompletionSuccess(trainings));
    } catch (error) {
        yield put(loadCompletionFailure(error));
    }
}

function* startSaga({payload}) {
    const {id} = payload;
    const {title, categories} = (yield select(selectById))[id];
    const firstCategoryName =
        Array.isArray(categories) && categories.length
            ? categories[0].name
            : '';
    datalayerPush({
        event: 'training_start',
        training_category: firstCategoryName,
        training_name: title,
    });
    yield put(startSuccess(id));
}

function* finishSaga() {
    const userRoles = yield select(selectUserRoles);
    const isSalesRepresentative = userRoles.includes(
        Roles.SALES_REPRESENTATIVE
    );
    const {id, successStatus} = yield select(selectCurrent);
    const {title, categories} = (yield select(selectById))[id];
    const firstCategoryName =
        Array.isArray(categories) && categories.length
            ? categories[0].name
            : '';
    try {
        if (successStatus === 'passed') {
            // Training is considered completed only if passed.
            // Sales representatives will not get any points and
            // will see a toast message saying that they completed
            // the training repeatedly.
            const {points} = isSalesRepresentative
                ? {points: 0} // causes toast message
                : yield call(putCompleteTraining, id);
            if (!isSalesRepresentative) {
                yield put(addPoints(points));
                yield put(finishSuccess(id));
            }
            if (points > 0) {
                // erstmalig bestanden -> modaler Dialog mit button (siehe src/ui/domains/training/Training/index.js)
                // der dann per CLOSE_SUCCESS_LIGHTBOX weiterleitet (siehe unten),
                datalayerPush({
                    event: 'training_success',
                    training_category: firstCategoryName,
                    training_name: title,
                });
                yield put(openSuccessLightbox());
            } else {
                // erneut bestanden -> toast nachricht und weiterleiten
                datalayerPush({
                    event: 'training_finished_again',
                    training_category: firstCategoryName,
                    training_name: title,
                });
                toast.info(
                    <Notification headline={lang.training.finish.success.hl}>
                        {lang.training.finish.success.msg(points)}
                    </Notification>
                );
                navigate(routes.trainings);
            }
        } else {
            // nicht bestanden -> kommentarlos weiterleiten
            navigate(routes.trainings);
        }
    } catch (error) {
        yield put(finishFailure(error));
        toast.error(
            <Notification headline={lang.training.finish.failure.hl}>
                {renderBackendApiError(error)}
            </Notification>
        );
    }
}

export const internals = {
    initScormApi,
    getTrainingsSaga,
    putCompleteTraining,
    loadCompletionSaga,
    startSaga,
    finishSaga,
};

export default function* trainingSaga() {
    if (typeof window !== 'undefined') {
        yield call(initScormApi);
        yield put(setScormReady());
    }
    yield takeLatest([LOAD_COMPLETION, LOGIN_SUCCESS], loadCompletionSaga);
    yield takeLatest(START, startSaga);
    yield takeLatest(FINISH, finishSaga);
    yield takeLatest(CLOSE_SUCCESS_LIGHTBOX, () => navigate(routes.trainings));
}
