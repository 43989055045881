export const normalizeProfileResponse = (profile = null) => {
    if (!profile) return null;
    return {
        id: profile.id,
        username: profile.username,
        email: profile.email,
        firstName: profile.first_name,
        lastName: profile.last_name,
        salutation: profile.salutation,
        points: profile.points,
        interim: profile.interim,
        categories: profile.categories.map((item) => item.id),
        type: profile.type,
        newsletter: profile.newsletter_opt_in,
        roles: profile.roles,
        referralCode: profile.referral_code,
        referralCodeUsages: profile.referral_code_usages,
    };
};

export const denormalizeProfileRequest = (profile = null) => {
    if (!profile) return null;
    return {
        username: profile.username,
        email: profile.email,
        salutation: profile.salutation,
        last_name: profile.lastName,
        first_name: profile.firstName,
        password: profile.password,
        password_confirmation: profile.passwordConfirmation,
        pharmacy_id: profile.pharmacyId,
        identification_number: profile.identificationNumber,
        newsletter_opt_in: profile.newsletter,
        referral_code: profile.referralCode,
    };
};
