import {fork} from 'redux-saga/effects';

import registration from './registration/saga';
import authentication from './authentication/saga';
import navigation from './navigation/saga';
import pharmacy from './pharmacy/saga';
import training from './training/saga';
import profile from './profile/saga';
import pages from './pages/saga';
import contact from './contact/saga';
import pointsHistory from './points-history/saga';
import reward from './reward/saga';
import pointsReceipt from './points-receipt/saga';
import pointsArchive from './points-archive/saga';
import pointsAssignment from './points-assignment/saga';
import pharmacyUsers from './pharmacy-users/saga';
import pharmacyUserDetails from './pharmacy-user-details/saga';
import campaigns from './campaign-submissions/saga';
import submissionsSlider from './user-campaign-submissions-slider/saga';
import userCampaignSubmission from './user-campaign-submissions/saga';
import interactiveBanner from './interactive-banner/saga';
import highlightsBanner from './highlights-banner/saga';
import cookieConsent from './cookie-consent/saga';
import userPharmacySubmission from './user-pharmacy-tour-submission/saga';
import pbAcademySaga from './pb-academy/saga';

export default function* main() {
    cookieConsent();
    yield fork(registration);
    yield fork(authentication);
    yield fork(navigation);
    yield fork(pharmacy);
    yield fork(training);
    yield fork(profile);
    yield fork(pages);
    yield fork(contact);
    yield fork(pointsHistory);
    yield fork(reward);
    yield fork(pointsReceipt);
    yield fork(pointsArchive);
    yield fork(pointsAssignment);
    yield fork(pharmacyUsers);
    yield fork(pharmacyUserDetails);
    yield fork(campaigns);
    yield fork(submissionsSlider);
    yield fork(userCampaignSubmission);
    yield fork(interactiveBanner);
    yield fork(userPharmacySubmission);
    yield fork(pbAcademySaga);
    yield fork(highlightsBanner);
}
