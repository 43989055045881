import {call, takeLatest, put} from 'redux-saga/effects';

import {
    LOAD_SUBMISSIONS_SLIDER,
    loadSubmissionsSliderSuccess,
    loadSubmissionsSliderFailure,
    setSubmissionsSlider,
    clearSubmissionsSlider,
} from './actions';

import {SET_IS_LOGGED_IN} from '../authentication/actions';
import {fetch} from '../backend-api';

function* getSubmissionsSliderSaga(campaignId) {
    return yield call(
        fetch,
        LOAD_SUBMISSIONS_SLIDER,
        'GET',
        `/campaigns/${campaignId}/submissions`
    );
}

function* loadSubmissionsSliderSaga({payload: {campaignId}}) {
    try {
        const {data: submissionsSlider} = yield call(
            getSubmissionsSliderSaga,
            campaignId
        );

        yield put(loadSubmissionsSliderSuccess(submissionsSlider));
        yield put(setSubmissionsSlider(submissionsSlider));
    } catch (error) {
        yield put(loadSubmissionsSliderFailure(error));
    }
}

const isLoggedOut = (action) =>
    action.type === SET_IS_LOGGED_IN && !action.payload.isLoggedIn;

function* clearSubmissionsSliderSaga() {
    yield put(clearSubmissionsSlider());
}

export const internals = {
    isLoggedOut,
    loadSubmissionsSliderSaga,
    clearSubmissionsSliderSaga,
    getSubmissionsSliderSaga,
};

export default function* submissionsSliderSaga() {
    yield takeLatest(LOAD_SUBMISSIONS_SLIDER, loadSubmissionsSliderSaga);
    yield takeLatest(isLoggedOut, clearSubmissionsSliderSaga);
}
