export const normalizePointsAssignmentsEntry = (entry = null) => {
    if (!entry) return null;

    return {
        id: entry.id,
        date: entry.date,
        change: entry.change,
        reason: entry.reason,
        status: entry.status,
        username: entry.username,
        firstName: entry.first_name,
        lastName: entry.last_name,
        pharmacy: entry.pharmacy,
    };
};
