export const color = {
    //primary
    red: 'hsl(343, 64%, 38%)', //#A12347
    //secondary
    lightBlue: 'hsl(197, 56%, 91%)', //#DCEEF5
    petrol: 'hsl(198, 63%, 52%)', //#39A5D2
    lightPetrol: 'hsla(198, 63%, 52%, 0.1)',
    mediumPetrol: 'hsl(197.027, 63%, 77%)', //#9FD4E9
    darkBlue: 'hsl(245, 42%, 25%)', //#29255A
    blush: 'hsl(343, 41%, 88%)', //#EDD4DB
    wine: 'hsl(343, 64%, 31%)', //#801C38
    //grey scale
    black: 'hsl(0, 0%, 0%)', //#000000
    darkgrey: 'hsl(0, 0%, 20%)', //#333333
    darkgreyLucid: 'hsla(0, 0%, 20%, 0.7)',
    grey: 'hsl(0, 0%, 49%)', //#7C7C7C
    fog: 'hsl(180, 3%, 89%)', //#E1E3E3
    snow: 'hsl(0, 0%, 96%)', //#F5F5F5
    white: 'hsl(0, 0%, 100%)', //#FFF,
};

export const shadow = {
    black: '0 2px 12px 0 rgba(0,0,0,.5)',
    blackLight: '0 2px 16px 0 rgba(0,0,0,.25)',
    wine: '0 0 8px 0 hsl(343, 64%, 31%)',
};

export const border = {
    black: `1px solid ${color.black}`,
    grey: `1px solid ${color.grey}`,
    fog: `1px solid ${color.fog}`,
    red: `1px solid ${color.red}`,
    wine: `1px solid ${color.wine}`,
    petrol: `1px solid ${color.petrol}`,
    lightBlue: `1px solid ${color.lightBlue}`,
    transparent: `1px solid transparent`,
};

export const outline = {
    focus: `1px dashed ${color.red}`,
};

export const font = {
    color: {
        text: color.darkgrey,
        link: color.red,
        link2: color.white,
    },
    type: {
        serif: 'PT Serif, Georgia, serif',
        sansSerif: 'PT Sans, Trebuchet MS, sans-serif',
    },
    style: {
        normal: 'normal',
        italic: 'italic',
    },
    weight: {
        bold: '700',
        semibold: '600',
        regular: '400',
    },
    size: {
        base: {
            desktop: '18px',
            mobile: '16px',
        },
        xxsmall: '0.65rem',
        xsmall: '0.8rem',
        small: '1rem',
        medium: '1.25rem',
        ml: '1.5rem',
        large: '1.75rem',
        xlarge: '2.25rem',
        xxlarge: '2.5rem',
        xcessive: '3.25rem',
    },
};

export const headerHeight = '550px';
export const navbarHeight = '80px';
export const navbarHeightMobile = '60px';

export const radius = {
    button: '0px',
    video: '15px',
};

export const zindex = {
    navbar: '900',
    burger: '400',
    toast: '1000',
};

export const grid = {
    gutter: '30px',
    breakpoints: {
        xxs: '320px',
        xs: '480px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1460px',
        xxxl: '1750px',
    },
    container: {
        xxs: '320px',
        xs: '480px',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1140px',
        xxl: '1340px',
        xxxl: '1690px',
    },
};

export const query = {
    xxs: 'screen and (min-width: 320px)',
    xxsMax: 'screen and (max-width: 359px)',
    xsMax: 'screen and (max-width: 479px)',
    xs: 'screen and (min-width: 480px)',
    smMax: 'screen and (max-width: 575px)',
    sm: 'screen and (min-width: 576px)',
    mdMax: 'screen and (max-width: 767px)',
    md: 'screen and (min-width: 768px)',
    lgMax: 'screen and (max-width: 991px)',
    lg: 'screen and (min-width: 992px)',
    xlMax: 'screen and (max-width: 1199px)',
    xl: 'screen and (min-width: 1200px)',
    xxlMax: 'screen and (max-width: 1459x)',
    xxl: 'screen and (min-width: 1460px)',
    xxxlMax: 'screen and (max-width: 1749x)',
    xxxl: 'screen and (min-width: 1750px)',
};
