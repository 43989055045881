export const OPEN = 'point-limit-lightbox/OPEN';
export const CLOSE = 'point-limit-lightbox/CLOSE';

export const open = () => ({
    type: OPEN,
});

export const close = () => ({
    type: CLOSE,
});
